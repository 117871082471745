<template>
  <div class="modal is-active">
    <div @click="dismissModal" class="modal-background"></div>
    <div class="modal-card" style="width:720px;">
      <header class="modal-card-head">
        <div class="modal-card-title">Club PandaDoc</div>
        <button @click="dismissModal" class="delete" aria-label="close"></button>
      </header>
      <section class="modal-card-body">
        <app-spinner v-if="loading"></app-spinner>
        <div v-else-if="this.clubPandadoc">
          <span>{{ this.clubPandadoc.club.id }} - {{ this.clubPandadoc.club.name }}</span>
        </div>
        <div v-else>
          <span>{{ this.club.id }} - {{ this.club.name }}</span>
        </div>
        <hr>
        <div>
          <div class="columns">
            <div class="column is-half">
              <b-field label="Default Document Fee (in cents)">
                <b-input v-model="feeCents" type="number" min="0" max="1000"/>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column is-half">
              <b-field>
                <b-checkbox v-model="enabled">Enabled</b-checkbox>
              </b-field>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button @click="dismissModal" class="button">Cancel</button>
        <button @click="saveModal" class="button is-primary">Save</button>
      </footer>
    </div>
  </div>
</template>

<script>
import api from '@/http-playmetrics';
import Spinner from '@/components/common/Spinner';

export default {
  components: {
    'app-spinner': Spinner,
  },

  props: {
    club: { type: Object, required: false, default: null },
    clubPandadoc: { type: Object, required: false, default: null },
  },

  data() {
    return {
      loading: true,
      enabled: false,
      feeCents: 25,
    };
  },

  computed: {
    invalid() {
      return true;
    },
  },

  created() {
    this.loading = true;
    if (this.clubPandadoc) {
      // this.fetchClubTemplates(this.clubPandadoc.id);
      this.enabled = this.clubPandadoc.enabled;
      this.feeCents = this.clubPandadoc.fee_cents;
    } else if (this.club) {
      // nop
    } else {
      this.dismissModal({ refreshData: true });
    }
    this.loading = false;
  },

  methods: {
    saveModal() {
      const postBody = {
        enabled: this.enabled,
        fee_cents: Number(this.feeCents),
      };
      if (this.clubPandadoc) {
        api().post(`admin/pandadoc_accounts/${this.clubPandadoc.id}`, postBody)
          .then(() => {
            this.dismissModal({ refreshData: true });
          });
      } else {
        postBody.club_id = this.club.id;
        api().post('admin/pandadoc_accounts', postBody)
          .then(() => {
            this.dismissModal({ refreshData: true });
          });
      }
    },
    dismissModal(options = { refreshData: false }) {
      this.$emit('dismissClubPandadocModal', options);
    },
  },
};
</script>
