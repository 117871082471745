<template>
  <div>
    <div class="box">
      <app-spinner v-if="loading"></app-spinner>
      <div v-else>
        <p class="has-text-right" style="margin-bottom:1rem;">
          <button @click="savePrefs" class="button is-primary">
            <span>Save Changes</span>
          </button>
        </p>
        <b-table
          :data="prefs"
          narrowed
          checkable
          :checked-rows.sync="activePrefs"
          hoverable
          mobile-cards
          @select="selected">
          <template slot-scope="props">
            <b-table-column field="status" label="Status" sortable>
              {{ props.row.status }}
            </b-table-column>
            <b-table-column field="topic" label="Topic" sortable>
              {{ props.row.topic }}
            </b-table-column>
          </template>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>No results</p>
              </div>
            </section>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import api from '@/http-playmetrics';
import Spinner from '@/components/common/Spinner';

export default {
  components: {
    'app-spinner': Spinner,
  },

  data() {
    return {
      loading: true,
      prefs: null,
      activePrefs: [],
    };
  },

  computed: {
  },

  methods: {
    loadPrefs() {
      api().get('admin/wepay/notification_preferences')
        .then((response) => {
          this.prefs = Object.values(response.data);
          this.activePrefs = _.filter(this.prefs, p => p.status === 'active');
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    savePrefs() {
      this.loading = true;
      const prefsForSubmit = _.map(this.prefs, p =>
        _.merge(p, { status: _.includes(this.activePrefs, p) ? 'active' : '' }));
      api().post('admin/wepay/notification_preferences', prefsForSubmit)
        .then(() => {
          this.loadPrefs();
          this.$store.dispatch('setSuccessToast', 'Preferences Updated');
        });
    },
  },

  created() {
    this.loadPrefs();
  },
};
</script>
