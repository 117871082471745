<template>
  <div>
    <div class="box">
      <div class="level">
        <b-radio v-model="timespanSelect" name="name" native-value="week">Past Week</b-radio>
        <b-radio v-model="timespanSelect" name="name" native-value="month">Past Month</b-radio>
        <b-radio v-model="timespanSelect" name="name" native-value="year">Past Year</b-radio>
      </div>
      <div class="field">
        <div class="control">
          <input v-model="searchQuery" class="input" type="text" placeholder="Search...">
        </div>
      </div>
    </div>
    <div class="box">
      <app-spinner v-if="loading"></app-spinner>
      <b-table v-else
        :data="filteredEmails"
        :per-page="perPage"
        narrowed
        hoverable
        mobile-cards
        paginated
        pagination-simple
        @select="selected"
        :row-class="(row, index) => row.has_template ? 'clickable' : 'deleted-email'">
        <template slot-scope="props">
          <b-table-column field="template" label="Template" meta="" sortable>
            {{ props.row.template }}
          </b-table-column>
          <b-table-column field="first_sent_at" label="First Sent At" meta="" sortable>
            <b-tooltip
              v-if="props.row.first_sent_at"
              :label="`${formatDate(props.row.first_sent_at)}`" dashed>
              {{ props.row.first_sent_at | formatDate('l') }}
            </b-tooltip>
          </b-table-column>
          <b-table-column field="last_sent_at" label="Last Sent At" meta="" sortable>
            <b-tooltip
              v-if="props.row.last_sent_at"
              :label="`${formatDate(props.row.last_sent_at)}`" dashed>
              {{ props.row.last_sent_at | formatDate('l') }}
            </b-tooltip>
          </b-table-column>
          <b-table-column field="num_sent" label="Sends" meta="" sortable>
            {{ props.row.num_sent }}
          </b-table-column>
          <b-table-column field="num_opens" label="Opens" meta="" sortable>
            {{ props.row.num_opens }}
          </b-table-column>
          <b-table-column field="num_clicks" label="Clicks" meta="" sortable>
            {{ props.row.num_clicks }}
          </b-table-column>
          <b-table-column field="open_rate" label="OR" meta="Open Rate" sortable>
            <span v-if="isNaN(props.row.open_rate)">-</span>
            <span v-else>{{ props.row.open_rate }}%</span>
          </b-table-column>
          <b-table-column field="click_rate" label="CR" meta="Click Rate"  sortable>
            <span v-if="isNaN(props.row.click_rate)">-</span>
            <span v-else>{{ props.row.click_rate }}%</span>
          </b-table-column>
        </template>
        <template slot-scope="props" slot="header">
          <b-tooltip :active="!!props.column.meta" :label="props.column.meta" dashed>
            {{ props.column.label }}
          </b-tooltip>
        </template>
        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>No results</p>
            </div>
          </section>
        </template>
        <template slot="bottom-left">
          <b-select v-model="perPage">
            <option value="10">10 per page</option>
            <option value="15">15 per page</option>
            <option value="20">20 per page</option>
            <option value="50">50 per page</option>
            <option value="100">100 per page</option>
          </b-select>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';
import api from '@/http-playmetrics';
import Spinner from '@/components/common/Spinner';

export default {
  components: {
    'app-spinner': Spinner,
  },

  data() {
    return {
      tpSelect: 'week',
      loading: true,
      emails: [],
      perPage: 20,
      searchQuery: '',
    };
  },


  computed: {
    timespanSelect: {
      get() {
        return this.tpSelect;
      },
      set(timespanSelect) {
        this.tpSelect = timespanSelect;
        return this.fetchEmails();
      },
    },
    filteredEmails() {
      return this.emails.filter(email =>
        email.template.toLowerCase().includes(this.searchQuery.toLowerCase()),
      );
    },
  },

  methods: {
    selected(email) {
      if (email.has_template) {
        this.$router.push(`/emails/${email.template}`);
      }
    },
    formatDate(date) {
      return moment(date).format('llll');
    },
    isNaN(number) {
      _.isNaN(number);
    },
    fetchEmails() {
      api().get(`admin/email_templates?timespan=${this.timespanSelect}`)
        .then((response) => {
          this.emails = [];
          response.data.forEach((e) => {
            const email = Object.assign({}, e);
            if (email.first_sent_at.startsWith('0')) {
              delete email.first_sent_at;
              delete email.last_sent_at;
            } else {
              email.open_rate = _.round((email.num_opens / email.num_sent) * 100, 1);
              email.click_rate = _.round((email.num_clicks / email.num_opens) * 100, 1);
            }
            this.emails.push(email);
          });
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  created() {
    this.fetchEmails();
  },
};
</script>

<style>
  .deleted-email {
    text-decoration: line-through;
    background-color: lightgrey;
  }
</style>
