<template>
  <div>
    <div class="box">
      <div class="field">
        <div class="control">
          <input v-model="searchQuery" class="input" type="text" placeholder="Search by email...">
          <p class="has-text-right" style="margin-bottom:1rem;">
            <button @click="emailSearch" class="button is-primary">
              <span class="icon">
                <i class="fas fa-plus"></i>
              </span>
              <span>Email Search</span>
            </button>
          </p>
        </div>
      </div>
    </div>
    <div class="box">
      <div v-if="loading">Enter Email Address to Search Firebase Accounts</div>
      <div v-else-if="userFound">
        <div>USER FOUND</div>
        <div>UID: {{ this.user.uid }}</div>
        <div>Provider ID: {{ this.user.provider_id }}</div>
        <div>Email: {{ this.user.email }}</div>
        <div>Display Name: {{ this.user.display_name }}</div>
        <div>Is Disabled: {{ this.user.disabled }}</div>
        <div>Is Email Verified: {{ this.user.email_verified }}</div>
        <b-table
          :data="user.user_info"
          narrowed
          hoverable
          mobile-cards>
          <template slot-scope="props">
            <b-table-column field="uid" label="UID">
              {{ props.row.uid }}
            </b-table-column>
            <b-table-column field="provider_id" label="Provider ID">
              {{ props.row.provider_id }}
            </b-table-column>
            <b-table-column field="email" label="Email">
              {{ props.row.email }}
            </b-table-column>
            <b-table-column field="display_name" label="Display Name" sortable>
              {{ props.row.display_name }}
            </b-table-column>
          </template>
        </b-table>
        <p class="has-text-right" style="margin-bottom:1rem;">
          <button @click="deleteUser" class="button is-primary">
            <span class="icon">
              <i class="fas fa-plus"></i>
            </span>
            <span>Delete Firebase User</span>
          </button>
        </p>
        <p class="has-text-right" style="margin-bottom:1rem;">
          <button @click="resetUserEmail" class="button is-primary">
            <span class="icon">
              <i class="fas fa-plus"></i>
            </span>
            <span>Reset Initial Firebase User Email</span>
          </button>
        </p>
      </div>
      <div v-else>
        {{ JSON.stringify(err) }}
      </div>
      <p v-if="msg !== ''">
        {{ msg }}
      </p>
      <p v-if="msg !== '' && userId !== ''" class="has-text-right" style="margin-bottom:1rem;">
        <button @click="gotoUser" class="button is-primary">
          <span class="icon">
            <i class="fas fa-plus"></i>
          </span>
          <span>View User</span>
        </button>
      </p>
    </div>
  </div>
</template>

<script>
import api from '@/http-playmetrics';
// import Spinner from '@/components/common/Spinner';

export default {
  data() {
    return {
      searchQuery: '',
      user: null,
      err: null,
      msg: '',
      userId: '',
    };
  },

  components: {
    // 'app-spinner': Spinner,
  },

  computed: {
    loading() {
      return (this.user == null) && (this.err == null);
    },
    userFound() {
      return (this.user != null);
    },
  },

  created() {
    if (this.$route.query.email) {
      this.searchQuery = this.$route.query.email;
      this.emailSearch();
    }
  },

  methods: {
    emailSearch() {
      api().get(`/admin/firebase_user/email/${this.searchQuery}`)
        .then((response) => {
          if (response.data.error) {
            this.err = response.data.error;
          } else {
            this.user = response.data;
          }
        })
        .catch((err) => {
          this.user = null;
          this.err = err;
        });
    },
    deleteUser() {
      api().get(`/admin/firebase_user/delete/${this.user.uid}`)
        .then((response) => {
          const ret = response.data;
          if (ret.result.startsWith('Success')) {
            this.msg = ret.result;
            this.userId = ret.user_id;
            this.user = null;
          }
        })
        .catch((err) => {
          this.user = null;
          this.err = err;
        });
    },
    resetUserEmail() {
      api().get(`/admin/firebase_user/reset_email/${this.user.uid}`)
        .then((response) => {
          const ret = response.data;
          if (ret.result.startsWith('Success')) {
            this.msg = ret.result;
            this.userId = ret.user_id;
            this.user = null;
          }
        })
        .catch((err) => {
          this.user = null;
          this.err = err;
        });
    },
    gotoUser() {
      this.$router.push(`/users/${this.userId}`);
    },
  },
};
</script>
