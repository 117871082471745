<template>
  <div class="modal is-active">
    <div @click="dismissModal()" class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <div class="modal-card-title">Add/Edit Admin User</div>
        <button @click="dismissModal()" class="delete" aria-label="close"></button>
      </header>
      <section class="modal-card-body">
        <b-field label="Email">
          <b-input :disabled="this.userId > 0" v-model="email"></b-input>
        </b-field>
      </section>
      <section class="modal-card-body">
        <b-field label="Permissions"></b-field>
        <div
          v-for="(perm, idx) in this.adminPermissions"
          :key="idx"
          class="columns">
          <div class="column">
            <b-field>
              <b-checkbox v-model="userPermissions" :native-value="perm">{{ perm }}</b-checkbox>
            </b-field>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button @click="saveAdminUser"
          class="button is-primary"
          :class="{'is-loading': loading}"
          :disabled="loading || invalid">
          {{ this.saveButtonText() }}
        </button>
        <button @click="dismissModal()" class="button">Cancel</button>
      </footer>
    </div>
  </div>
</template>

<script>
import api from '@/http-playmetrics';
import _ from 'lodash';

export default {
  props: {
    userId: { type: Number, required: true },
  },

  data() {
    return {
      loading: true,
      email: '',
      adminPermissions: [],
      userPermissions: [],
      userCSTPermissions: [],
    };
  },

  computed: {
    invalid() {
      if (this.userId > 0) {
        return false;
      }
      const re = /\S+@\S+\.\S+/;
      return (this.email === '' || !re.test(this.email));
    },
  },

  created() {
    api().get('/admin/admin_permissions').then((permResponse) => {
      this.adminPermissions = permResponse.data.filter(perm => !perm.startsWith('cst') || perm === 'cst_tool');
      if (this.userId > 0) {
        api().get(`admin/users/${this.userId}`)
          .then((response) => {
            const user = response.data;
            if (user) {
              this.email = user.email;
              this.userCSTPermissions = user.admin_permissions.filter(perm => perm.startsWith('cst') && perm !== 'cst_tool');
              this.userPermissions = _.intersection(user.admin_permissions, this.adminPermissions);
            }
          });
      }
    }).finally(() => {
      this.loading = false;
    });
  },

  methods: {
    saveButtonText() {
      if (this.userId === 0) {
        return 'Add as Admin';
      } else {
        return 'Update Admin';
      }
    },
    saveAdminUser() {
      this.loading = true;
      let postBody;
      let url = '/admin/admin_users';
      const postUserPermissions = _.union(this.userPermissions, this.userCSTPermissions);
      if (this.userId === 0) {
        postBody = {
          email: this.email,
          admin_permissions: postUserPermissions,
        };
      } else {
        url += `/${this.userId}/permissions`;
        postBody = postUserPermissions;
      }
      api().post(url, postBody)
        .then(() => {
          this.$store.dispatch('setSuccessToast', 'Admin User Saved');
          this.loading = false;
          this.dismissModal({ refreshData: true });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    dismissModal(options = { refreshData: false }) {
      this.$emit('dismissAdminUserModal', options);
    },
  },
};
</script>
