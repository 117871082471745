<template>
  <div class="modal is-active">
    <div @click="dismissModal()" class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <div class="modal-card-title">Create Email Domain</div>
        <button @click="dismissModal()" class="delete" aria-label="close"></button>
      </header>
      <section class="modal-card-body">
        <b-field label="Email Domain">
          <b-input v-model="email_domain" type="email_domain"></b-input>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <button @click="createDomain"
          class="button is-primary"
          :class="{'is-loading': loading}"
          :disabled="loading || invalid">
          Create Email Domain
        </button>
        <button @click="dismissModal()" class="button">Cancel</button>
      </footer>
    </div>
  </div>
</template>

<script>
import api from '@/http-playmetrics';

const disallowedSubdomains = [
  'admin',
  'api',
  'api2',
  'cst',
  'gb',
  'governance',
  'governing',
  'governingbody',
  'governingbodies',
  'governing-body',
  'governing-bodies',
  'clb',
  'club',
  'email',
  'home',
  'lb',
  'local',
  'mail',
  'sport',
  'sports',
  'stage',
  'staging',
  'test',
  'testing',
  'tests',
  'www',
  'webmail',
];

const disallowedEmailDomains = [
  // Root domains...
  'playmetric.io',
  'playmetrics.com',
  'pmsends.com',
  'gb.playmetric.io',
  'gb.playmetrics.com',
  // Used domains...
  'home.playmetric.io',
  'home.playmetrics.com',
  'api.playmetric.io',
  'api.playmetrics.com',
  'api2.playmetric.io',
  'api2.playmetrics.com',
  'api.gb.playmetric.io',
  'api.gb.playmetrics.com',
  'api2.gb.playmetric.io',
  'api2.gb.playmetrics.com',
  'clb.playmetric.io',
  'clb.playmetrics.com',
  'lb.playmetric.io',
  'lb.playmetrics.com',
  'webhooks.playmetric.io',
  'webhooks.playmetrics.com',
  'webhooks.gb.playmetric.io',
  'webhooks.gb.playmetrics.com',
  // Others...
  'playmetrics.io',
  'playmetric.com',
  'pmsends.io',
  'pmsend.com',
  'gb.playmetrics.io',
  'gb.playmetric.com',
];

const disallowedEmailDomainSuffixes = [
  '.pmsends.com',
];

export default {
  props: {
  },

  data() {
    return {
      loading: false,
      email_domain: '',
    };
  },

  computed: {
    invalid() {
      const emailDomain = this.email_domain.toLowerCase();
      if (disallowedEmailDomains.some(i => i === emailDomain)) {
        return true;
      }
      if (disallowedEmailDomainSuffixes.some(i => emailDomain.endsWith(i))) {
        return true;
      }
      if (disallowedSubdomains.some(i => i === (emailDomain.split('.')[0] || ''))) {
        return true;
      }
      const re = /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$/;
      return !re.test(this.email_domain);
    },
  },

  methods: {
    createDomain() {
      this.loading = true;
      api().post(`/admin/clubs/${this.$route.params.club}/create_email_domain/${this.email_domain}`, {})
        .then(() => {
          this.$store.dispatch('createNotification', { message: 'Email Domain Created', success: true, toast: true });
          this.loading = false;
          this.dismissModal();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    dismissModal() {
      this.$emit('dismissModal');
    },
  },
};
</script>
