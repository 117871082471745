<template>
  <div>
    <div class="box">
      <div class="field">
        <div class="control">
          <input v-model="searchQuery" class="input" type="text" placeholder="Search by email...">
          <p class="has-text-right" style="margin-bottom:1rem;">
            <button @click="emailSearch" class="button is-primary">
              <span class="icon">
                <i class="fas fa-plus"></i>
              </span>
              <span>Email Search</span>
            </button>
          </p>
        </div>
      </div>
    </div>
    <div class="box">
      <div v-if="loading && !searched">Enter Email Address to Search User Push Notification Tokens</div>
      <div v-else-if="userFound">
        <div>USER FOUND</div>
        <div>Email: {{ this.user.email }}</div>
        <div>First Name: {{ this.user.first_name }}</div>
        <div>Last Name: {{ this.user.last_name }}</div>
        <div>Mobile Number: {{ this.user.mobile_number }}</div>
        <b-table
          :data="user.push_tokens"
          :checked-rows.sync="checkedRows"
          :is-row-checkable="(t) => t.logged_in"
          :default-sort="['logged_in', 'desc']"
          checkable
          narrowed
          hoverable
          mobile-cards
        >
          <template slot-scope="props">
            <b-table-column field="id" label="ID">
              {{ props.row.id }}
            </b-table-column>
            <b-table-column field="logged_in" label="Logged In" sortable>
              {{ props.row.logged_in }}
            </b-table-column>
            <b-table-column field="client" label="Client">
              {{ props.row.client }}
            </b-table-column>
            <b-table-column field="token" label="Token">
              {{ props.row.token.substring(0,10) }}...
            </b-table-column>
            <b-table-column field="created_at" label="Created At">
              {{ props.row.created_at }}
            </b-table-column>
            <b-table-column field="updated_at" label="Updated At">
              {{ props.row.updated_at }}
            </b-table-column>
            <b-table-column field="invalid_at" label="Invalid At">
              {{ props.row.invalid_at }}
            </b-table-column>
          </template>
        </b-table>
        <input
          v-model="testMsg"
          class="input"
          style="margin-top: 1rem"
          type="text"
          placeholder="Input Test Message Here..."
          maxlength="120"
        >
        <div style="margin: 0.5rem 0">
          <button
            @click="()=>sendTestNotification(true)"
            class="button is-primary"
            :disabled="sendButtonInvalid || requestInFlight"
          >
            <span>Send Push Notification Through Firebase SDK</span>
          </button>
        </div>
        <div>
          <button
            @click="()=>sendTestNotification(false)"
            class="button is-primary"
            :disabled="sendButtonInvalid || requestInFlight"
          >
            <span>Send Push Notification Skip Firebase SDK</span>
          </button>
        </div>
        <div>
          <div style="font-weight:bold"><br>Instructions</div>
          <div>
            See Internal Knowledge Base Article <a href="https://app.getguru.com/card/cApM4j9i/Not-Receiving-Push-Notifications-on-Mobile-Device" target="_blank">Here</a>
            <ul style="list-style-type:disc;list-style-position:inside">
              <li>Before sending push notifications through the Push Notifications test tool,
              validate that the user has push notifications enabled on their device. (See macro -
              <i>Notification Issue - Not Receiving Push Notifications</i>)</li>
              <li>Once the user has validated their app settings and communication preferences send
                them a test push notification using the
                <i><b>Send Push Notification Through Firebase SDK</b></i> button. The push notification
                message will include the token id associated with the
                device that it is sent to so it is okay to check on all devices.</li>
              <li>Check whether the user recieves the push notification. If they do not receive the
                notification then repeat the test using the
                <i><b>Send Push Notification Skip Firebase SDK</b></i> button</li>
            </ul>
          </div>
        </div>
      </div>
      <div v-else-if="err">
        {{ JSON.stringify(err) }}
      </div>
      <div v-else-if="searched">No user found with email address {{ searchQuery }}</div>
      <p v-if="msg !== ''">
        {{ msg }}
      </p>
    </div>
  </div>
</template>

<script>
import api from '@/http-playmetrics';
// import Spinner from '@/components/common/Spinner';

export default {
  data() {
    return {
      searchQuery: '',
      user: null,
      err: null,
      msg: '',
      userId: '',
      testMsg: '',
      checkedRows: [],
      searched: false,
      requestInFlight: false,
    };
  },

  components: {
    // 'app-spinner': Spinner,
  },

  watch: {
    searchQuery() {
      if (this.searched) {
        this.searched = false;
      }
    },
  },

  computed: {
    loading() {
      return (this.user == null) && (this.err == null);
    },
    userFound() {
      return (this.user != null);
    },
    sendButtonInvalid() {
      return this.checkedRows.length === 0 || this.testMsg === '';
    },
  },

  methods: {
    emailSearch() {
      this.user = null;
      this.checkedRows = [];
      this.testMsg = '';
      this.searched = false;
      api().post('admin/users/search', { email: this.searchQuery, populate: 'push_tokens' })
        .then((response) => {
          if (response.data.error) {
            this.err = response.data.error;
          } else {
            this.user = response.data.results[0];
          }
          this.searched = true;
        })
        .catch((err) => {
          this.user = null;
          this.err = err;
          this.searched = true;
        });
    },
    sendTestNotification(throughClient) {
      this.requestInFlight = true;
      api().post('admin/push_notification_test',
        { tokens: this.checkedRows, message: this.testMsg, userId: this.user.id, through_client: throughClient })
        .then((response) => {
          if (response.status === 200) {
            this.$store.dispatch('createNotification', { message: 'Push Notification has been sent out', success: true, toast: true });
            this.requestInFlight = false;
          }
        }).catch((err) => {
          this.$store.dispatch('createNotification', { message: err.message, success: false, toast: true });
          this.requestInFlight = false;
        });
    },
  },
};
</script>
