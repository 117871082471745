<template>
  <div>
    <app-spinner v-if="loading"></app-spinner>
    <div v-else>
      <div class="box">
        <div class="is-size-4">Test Page</div>
        <hr />
        <h2>Financial Connections</h2>
        <div class="columns">
          <div class="column is-narrow">
            <button
              @click="doGetBankToken()"
              :disabled="getTokenDisabled"
              class="button is-primary">
              <span>Get Bank Token</span>
            </button>
          </div>
          <div class="column is-narrow">
            <button
              @click="doMakePayment()"
              disabled
              class="button is-primary">
              <span>Make Payment</span>
            </button>
          </div>
        </div>
        <hr />
        <h2>Setup Intent</h2>
        <div class="columns">
          <div class="column is-narrow">
            <button
              @click="doInitPaymentElement()"
              :disabled="initSetupIntentDisabled"
              class="button is-primary">
              <span>Init Payment Element</span>
            </button>
          </div>
          <div class="column is-narrow">
            <button
              @click="doSubmitPaymentElement()"
              :disabled="initSetupIntentDisabled"
              class="button is-primary">
              <span>Submit</span>
            </button>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column is-half">
            <div id="payment-element">
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <p>{{ this.setupIntentPaymentMethod }}</p>
            <p>{{ this.setupIntentError }}</p>
          </div>
        </div>
        <hr />
        <h2>PIN</h2>
        <div class="columns">
          <div class="column is-narrow">
            <button
              @click="doDisplayPin()"
              :disabled="pinButtonDisabled"
              class="button is-primary">
              <span>PIN</span>
            </button>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column is-half is-outlined">
            <div id="pin-element">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { importJWK, CompactEncrypt } from 'jose';
import _ from 'lodash';
import api from '@/http-playmetrics';
import Spinner from '@/components/common/Spinner';
import Vue from 'vue';

export default {
  components: {
    'app-spinner': Spinner,
  },

  data() {
    return {
      loading: true,
      vendorID: 'acct_1Me85xQnIOhwElMU',
      stripe: null,
      elements: null,
      session: null,
      getTokenDisabled: false,
      initSetupIntentDisabled: false,
      submitSetupIntentDisabled: true,
      setupIntentPaymentMethod: '',
      setupIntentError: '',
      pinButtonDisabled: false,
    };
  },

  computed: {
  },

  methods: {
    loadStripe(vendorKey) {
      if (window.Stripe) {
        return Promise.resolve(window.Stripe(vendorKey, { stripeAccount: this.vendorID }));
      }
      console.log('Loading Stripe JS');
      return Vue.loadScript('https://js.stripe.com/v3/').then(() => window.Stripe(vendorKey, { stripeAccount: this.vendorID }));
    },
    doGetBankToken() {
      this.getTokenDisabled = true;
      api().post('/admin/test/session', {}).then((resp) => {
        this.session = resp.data;
        this.stripe.collectBankAccountToken({ clientSecret: this.session.client_secret }).then((stripeResp) => {
          console.log(stripeResp);
          const token = _.get(stripeResp, 'linkAccountSession.bankAccountToken.id', '');
          console.log('token is', token);
          if (token !== '') {
            api().post('/admin/test/token', token, { headers: { 'content-type': 'text/plain' } });
          }
          // confirmUsBankAccountSetup
        }).catch((err) => {
          console.log(err);
        });
        this.getTokenDisabled = false;
      });
    },
    doMakePayment() {
      console.log('TODO');
    },
    doInitPaymentElement() {
      this.initSetupIntentDisabled = true;
      api().post('/admin/test/get_setup_intent', {}).then((resp) => {
        const elementsOptions = {
          clientSecret: resp.data,
          appearance: {}, // Fully customizable with appearance API.
        };
        const user = this.$store.getters.user;
        let defaultName = '';
        if ((user.first_name !== '') && (user.last_name !== '')) {
          defaultName = `${user.first_name} ${user.last_name}`;
        }

        const createOptions = {
          layout: {
            type: 'tabs', // 'accordion',
            // defaultCollapsed: true,
            // radios: true,
          },
          defaultValues: {
            billingDetails: {
              name: defaultName,
              // email: user.email,
            },
          },
          // business: {
          //   name: 'PlayMetrics FC',
          // },
          fields: {
            billingDetails: {
              email: 'never',
            },
          },
        };

        // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 3
        this.elements = this.stripe.elements(elementsOptions);

        // Create and mount the Payment Element
        const paymentElement = this.elements.create('payment', createOptions);
        paymentElement.mount('#payment-element');

        paymentElement.on('change', (event) => {
          console.log('event is', event);
          this.submitSetupIntentDisabled = !event.complete;
        });
      });
    },
    doSubmitPaymentElement() {
      const confirmSetupOptions = {
        elements: this.elements,
        confirmParams: {
          payment_method_data: {
            billing_details: {
              email: this.$store.getters.user.email,
            },
          },
        },
        redirect: 'if_required',
      };
      this.stripe.confirmSetup(confirmSetupOptions).then((resp) => {
        console.log('confirmSetup returned', resp);
        if (resp.error) {
          this.setupIntentError = resp.error.code;
        } else if (resp.setupIntent) {
          this.setupIntentPaymentMethod = resp.setupIntent.payment_method;
          // TODO submit to BE
        }
      });
    },
    async encryptPin() {
      // https://stripe.com/docs/issuing/cards/pin-management#pin-encryption-examples

      // Fetch Stripe's RSA public key
      const keyData = await fetch('https://issuing-key.stripe.com/v1/keys').then(r => r.json());

      // Import the public key. Here, we choose to import the JWK-formatted key,
      // but it will also be available in PKCS#8 format as `keyData.pkcs8`
      const publicKey = await importJWK(keyData.jwk, 'RSA-OAEP');

      // Encrypt the new PIN with the given public key, using the RSA-OAEP
      // algorithm to wrap the key, and A128CBC-HS256 to produce the ciphertext
      const pin = this.pin.join('');
      console.log('>>>', pin);
      const jwe = await new CompactEncrypt(new TextEncoder().encode(pin))
        .setProtectedHeader({ alg: 'RSA-OAEP', enc: 'A128CBC-HS256', kid: keyData.key_id })
        .encrypt(publicKey);

      // Return our JWE (JWEs are base64url-encoded)
      return jwe;
    },
    async doDisplayPin() {
      this.pinButtonDisabled = true;
      const cardId = 'ic_1Me8JpQnIOhwElMUpRLWlBGl';

      const nonceResult = await this.stripe.createEphemeralKeyNonce({
        issuingCard: cardId,
      });

      api().post('/admin/test/pin_data', { club_id: 294, card_id: 3273, nonce: nonceResult.nonce }).then((resp) => {
        console.log('>>>', resp);

        const ephemeralKeyResponseData = resp.data;

        const elementsOptions = {
          clientSecret: ephemeralKeyResponseData.client_secret,
          appearance: {}, // Fully customizable with appearance API.
        };

        const createOptions = {
          issuingCard: cardId,
          nonce: nonceResult.nonce,
          ephemeralKeySecret: ephemeralKeyResponseData.ephemeral_key_secret,
        };

        // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 3
        this.elements = this.stripe.elements(elementsOptions);

        // Create and mount the Pin Element
        console.log('createOptions', createOptions);
        const pinElement = this.elements.create('issuingCardPinDisplay', createOptions);
        pinElement.mount('#pin-element');
        console.log('pinElement', pinElement);

        pinElement.on('change', (event) => {
          console.log('event is', event);
          this.pinButtonDisabled = !event.complete;
        });
      });
    },
  },

  created() {
    api().post('/admin/test/stripe_public_key', {}).then((resp) => {
      this.loadStripe(resp.data).then((stripe) => {
        this.stripe = stripe;
        this.loading = false;
      });
    });
  },
};
</script>
