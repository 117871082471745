<template>
  <div>
    <b-table
      :data="reminders"
      narrowed
      :per-page="perPage"
      paginated
      pagination-simple>
      <template slot-scope="props">
          <b-table-column field="id" label="ID" sortable>
            {{ props.row.id }}
          </b-table-column>
         <b-table-column field="club_id" label="Club ID" sortable>
          {{ props.row.club_id }}
        </b-table-column>
        <b-table-column field="team_id" label="Team ID" sortable>
          {{ props.row.team_id }}
        </b-table-column>
        <b-table-column field="context_type" label="Context Type" sortable>
          {{ props.row.context_type }}
        </b-table-column>
        <b-table-column field="recipient_id" label="Recipient ID" sortable>
          {{ props.row.recipient_id }}
        </b-table-column>
        <b-table-column field="trigger_at" label="Trigger At" sortable>
          {{ props.row.trigger_at | formatDate('llll') }}
        </b-table-column>
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>No reminders</p>
          </div>
        </section>
      </template>
      <template slot="bottom-left">
        <b-select v-model="perPage">
          <option value="10">10 per page</option>
          <option value="15">15 per page</option>
          <option value="20">20 per page</option>
          <option value="50">50 per page</option>
          <option value="100">100 per page</option>
        </b-select>
      </template>
    </b-table>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    reminders: { type: Array, required: true },
  },

  data() {
    return {
      perPage: 20,
      searchQuery: '',
    };
  },

  computed: {
  },

  methods: {
    formatDate(date) {
      return moment(date).format('llll');
    },
  },
};
</script>
