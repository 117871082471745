<template>
  <div>
    <div class="box">
      <div class="is-size-4">Memcache</div>
      <div class="columns">
        <div class="column">
          <b-field label="Get Key">
            <b-input v-model="memcacheKey" />
          </b-field>
          <button @click="memcacheGetKey()" class="button">Get Key Value</button>
        </div>
      </div>
      <div>
        {{ this.memcacheWorking }}
      </div>
      <div class="columns">
        <div class="column">
          <b-field label="Value">
            {{ this.memcacheValue }}
          </b-field>
          <b-field label="Flags">
            {{ this.memcacheFlags }}
          </b-field>
        </div>
      </div>
      <hr>
      <div class="columns">
        <div class="column">
          <b-field label="Set Key">
            <b-input v-model="memcacheKey" />
          </b-field>
          <button @click="memcacheSetKey()" class="button">Set Key String Value</button>
          <button @click="memcacheSetKeyInt()" class="button">Set Key Int Value</button>
        </div>
        <div class="column">
          <b-field label="Value">
            <b-input v-model="memcacheValue" />
          </b-field>
        </div>
      </div>
      <div>
        {{ this.memcacheWorking }}
      </div>
      <hr>
      <div class="columns">
        <div class="column">
          <b-field label="Touch Key">
            <b-input v-model="memcacheKey" />
          </b-field>
          <button @click="memcacheTouchKey()" class="button">Set Key Expires</button>
        </div>
        <div class="column">
          <b-field label="Expiration">
            <b-input v-model="memcacheExpires" />
          </b-field>
        </div>
      </div>
      <div>
        <span>*Expiration is the cache expiration time, in seconds: either a relative time from now (up to 1 month),
        or an absolute Unix epoch time. Zero means the Item has no expiration time. You can not retrive the current value</span>
        {{ this.memcacheWorking }}
      </div>
      <hr>
      <div class="columns">
        <div class="column">
          <b-field label="Update Counter">
            <b-input v-model="memcacheKey" />
          </b-field>
          <button @click="memcacheIncKey()" class="button">Increment Key by Value</button>
          <button @click="memcacheDecKey()" class="button">Decrement Key by Value</button>
        </div>
        <div class="column">
          <b-field label="Value">
            <b-input v-model="memcacheIncValue" />
          </b-field>
        </div>
      </div>
      <div>
        {{ this.memcacheWorking }}
      </div>
      <div>
        {{ this.memcacheValue }}
      </div>
      <hr>
      <div class="columns">
        <div class="column">
          <b-field label="Delete Key">
            <b-input v-model="memcacheKey" />
          </b-field>
          <button @click="memcacheDeleteKey()" class="button">Delete Key</button>
        </div>
      </div>
      <div>
        <span>*Comma separated for multiple</span>
        {{ this.memcacheWorking }}
      </div>
    </div>
  </div>
</template>
<script>
import api from '@/http-playmetrics';

export default {
  data() {
    return {
      memcacheKey: '',
      memcacheValue: '',
      memcacheIncValue: '',
      memcacheFlags: '',
      memcacheExpires: '',
      memcacheWorking: '',
    };
  },
  methods: {
    memcacheGetKey() {
      this.memcacheWorking = 'Working';
      api().get(`admin/memcache_keys/${this.memcacheKey}`).then((resp) => {
        this.memcacheWorking = '';
        this.memcacheValue = resp.data.Value;
        this.memcacheFlags = resp.data.Flags;
      });
    },
    memcacheSetKey() {
      this.memcacheWorking = 'Working';
      api().post(`admin/memcache_keys/${this.memcacheKey}`, this.memcacheValue).then(() => {
        this.memcacheWorking = '';
        this.memcacheFlags = '';
      });
    },
    memcacheSetKeyInt() {
      this.memcacheWorking = 'Working';
      api().post(`admin/memcache_keys_int/${this.memcacheKey}`, this.memcacheValue).then(() => {
        this.memcacheWorking = '';
        this.memcacheFlags = '';
      });
    },
    memcacheIncKey() {
      this.memcacheWorking = 'Working';
      api().post(`admin/memcache_keys_inc/${this.memcacheKey}`, this.memcacheIncValue).then((resp) => {
        this.memcacheWorking = '';
        this.memcacheValue = resp.data;
        this.memcacheFlags = '';
      });
    },
    memcacheDecKey() {
      this.memcacheWorking = 'Working';
      api().post(`admin/memcache_keys_dec/${this.memcacheKey}`, this.memcacheIncValue).then((resp) => {
        this.memcacheWorking = '';
        this.memcacheValue = resp.data;
        this.memcacheFlags = '';
      });
    },
    memcacheTouchKey() {
      this.memcacheWorking = 'Working';
      api().post(`admin/memcache_keys_touch/${this.memcacheKey}`, this.memcacheExpires).then((resp) => {
        this.memcacheWorking = '';
        this.memcacheValue = resp.data.Value;
        this.memcacheFlags = resp.data.Flags;
      });
    },
    memcacheDeleteKey() {
      this.memcacheWorking = 'Working';
      api().delete(`admin/memcache_keys/${this.memcacheKey}`).then(() => {
        this.memcacheWorking = '';
        this.memcacheFlags = '';
      });
    },
  },
};
</script>
