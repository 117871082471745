<template>
  <div class="modal is-active">
    <div @click="dismissModal" class="modal-background"></div>
    <div class="modal-card" style="width:720px;">
      <header class="modal-card-head">
        <div class="modal-card-title">Edit Club Export</div>
        <button @click="dismissModal" class="delete" aria-label="close"></button>
      </header>
      <section class="modal-card-body">
        <app-spinner v-if="loading"></app-spinner>
        <div v-else>
          <div class="columns">
            <div class="column">
              <b-field label="Club Data Export Config">
                <b-input
                  v-model="config"
                  type="textarea"
                  rows="4"/>
              </b-field>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button @click="dismissModal" class="button">Cancel</button>
        <button @click="saveModal" class="button is-primary">Save</button>
      </footer>
    </div>
  </div>
</template>

<script>
import api from '@/http-playmetrics';
import Spinner from '@/components/common/Spinner';

export default {
  components: {
    'app-spinner': Spinner,
  },

  props: {
    clubId: { type: Number, required: true },
    currentConfig: { type: Object, required: true },
  },

  data() {
    return {
      loading: true,
      updatedConfig: null,
    };
  },

  watch: {
    currentConfig() {
      this.config = this.currentConfig;
    },
  },

  created() {
    this.loading = false;
  },

  computed: {
    config: {
      get() {
        return JSON.stringify(this.currentConfig);
      },
      set(val) {
        const clean = val.replace(/(?:\r\n|\r|\n)/g, '');
        this.updatedConfig = JSON.parse(clean);
      },
    },
  },

  methods: {
    saveModal() {
      const postBody = {
        config: this.updatedConfig, // TODO verify JSON
      };
      api().post(`admin/club_exports/${this.clubId}`, postBody)
        .then((response) => {
          if (response.data.errors) {
            this.$store.dispatch('createNotification', { message: response.data.errors, success: false, toast: true });
          } else {
            this.dismissModal({ refreshData: true });
          }
        })
        .catch((err) => {
          this.$store.dispatch('createNotification', { message: err.message, success: false, toast: true });
        });
    },
    dismissModal(options = { refreshData: false }) {
      this.$emit('dismissClubModal', options);
    },
  },
};
</script>
