<template>
  <div v-if="siteMovedTo" class="has-text-centered" style="margin-top:4rem">
    <h1>The Admin tool has been moved to</h1>
    <a :href="siteMovedTo">{{siteMovedTo}}</a>
    <h1>Please update your bookmarks to point to the new URL</h1>
  </div>
  <div v-else>
    <app-navbar></app-navbar>
    <app-notifications></app-notifications>
    <router-view class="full-height-view"></router-view>
    <app-footer></app-footer>
    <ReauthenticationModal />
  </div>
</template>

<script>
import CONFIG from '@/env-config';
import Navbar from './components/layout/Navbar';
import Notifications from './components/layout/Notifications';
import Footer from './components/layout/Footer';
import ReauthenticationModal from './components/auth/ReauthenticationModal';

export default {
  components: {
    'app-navbar': Navbar,
    'app-notifications': Notifications,
    'app-footer': Footer,
    ReauthenticationModal,
  },
  computed: {
    siteMovedTo() {
      const currentURL = `${window.location.protocol}//${window.location.host}/`;
      return currentURL === CONFIG.adminURL ? null : CONFIG.adminURL;
    },
  },
};
</script>

<style lang="sass">
  // NOTE: webpack imports variables.sass into each component.
  // https://vue-loader.vuejs.org/en/configurations/pre-processors.html
  @import "~bulma"
  @import "./assets/styles/custom-styles"
  @import "~buefy/src/scss/buefy"
  .full-height-view
    min-height: 80vh
</style>
