<template>
  <div class="modal is-active">
    <div @click="dismissModal" class="modal-background"></div>
    <div class="modal-card" style="width:720px;">
      <header class="modal-card-head">
        <div class="modal-card-title">Digital Document Template</div>
        <button @click="dismissModal" class="delete" aria-label="close"></button>
      </header>
      <section class="modal-card-body">
        <app-spinner v-if="loading"></app-spinner>
        <div v-else-if="this.digitalDocumentTemplate">
          <span>{{ this.digitalDocumentTemplate.vendor_id }}</span>
        </div>
        <div v-else>
          <div class="columns">
            <div class="column">
              <b-select
                v-model="vendorId"
                placeholder="Select the PandaDoc Template..."
                expanded>
                <option
                  v-for="template in pandadocTemplates"
                  :value="template.id"
                  :key="template.id">
                  {{ template.name }} ({{ template.id }})
                </option>
              </b-select>
            </div>
          </div>
        </div>
        <hr>
        <div>
          <div class="columns">
            <div class="column is-9">
              <b-field label="Name">
                <b-input v-model="name" />
              </b-field>
            </div>
            <div class="column is-3">
              <b-field label="Fee (cents)">
                <b-input v-model="feeCents" type="number" min="0" max="1000"/>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Types" />
              <b-checkbox native-value="Player" v-model="types">Player</b-checkbox>
              <b-checkbox native-value="Coach" v-model="types">Coach</b-checkbox>
              <b-checkbox native-value="Team" v-model="types">Team</b-checkbox>
              <b-checkbox native-value="Club" v-model="types">Club</b-checkbox>
              <b-checkbox native-value="Registration" v-model="types">Registration</b-checkbox>
              <b-checkbox native-value="Financial Aid" v-model="types">Financial Aid</b-checkbox>
            </div>
          </div>
          <hr>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button @click="dismissModal" class="button">Cancel</button>
        <button @click="saveModal" class="button is-primary">Save</button>
      </footer>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import api from '@/http-playmetrics';
import Spinner from '@/components/common/Spinner';

export default {
  components: {
    'app-spinner': Spinner,
  },

  props: {
    clubPandadoc: { type: Object, required: true },
    digitalDocumentTemplate: { type: Object, required: false, default: null },
  },

  data() {
    return {
      loading: true,
      pandadocTemplates: [],
      name: '',
      types: [],
      feeCents: 25,
      vendorId: '',
    };
  },

  computed: {
    invalid() {
      return true;
    },
  },

  created() {
    this.loading = true;
    if (this.digitalDocumentTemplate) {
      this.name = this.digitalDocumentTemplate.name;
      this.feeCents = this.digitalDocumentTemplate.fee_cents;
      this.types = this.digitalDocumentTemplate.types;
      this.loading = false;
    } else {
      this.feeCents = this.clubPandadoc.fee_cents;
      this.types = ['Player'];
      this.pandadocTemplates = [];
      api().get(`/admin/pandadoc_accounts/${this.clubPandadoc.id}/pandadoc_templates`).then((resp) => {
        this.pandadocTemplates = _.sortBy(resp.data, ['name', 'date_created']);
        this.loading = false;
      });
    }
  },

  methods: {
    saveModal() {
      const postBody = {
        // club_region_id: 0,
        // team_id: 0,
        name: this.name,
        types: this.types,
        fee_cents: Number(this.feeCents),
      };
      if (this.digitalDocumentTemplate) {
        api().post(`admin/pandadoc_accounts/${this.clubPandadoc.id}/templates/${this.digitalDocumentTemplate.id}`, postBody)
          .then(() => {
            this.dismissModal({ refreshData: true });
          });
      } else {
        // postBody.club_id = this.clubPandadoc.club_id;
        postBody.vendor_id = this.vendorId;
        api().post(`admin/pandadoc_accounts/${this.clubPandadoc.id}/templates`, postBody)
          .then(() => {
            this.dismissModal({ refreshData: true });
          });
      }
    },
    dismissModal(options = { refreshData: false }) {
      this.$emit('dismissDigitalDocumentTemplateModal', options);
    },
  },
};
</script>
