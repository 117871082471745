<template>
  <div>
    <div class="box">
      <div class="is-size-4">System Notification</div>
      <div style="margin-bottom:1rem">
        <span v-if="!currMessage" class="is-italic">Empty</span>
        <div v-else>
          <div class="has-text-weight-bold">{{ currMessage.message }}</div>
          <div class="is-italic is-size-7">Published on {{ currMessage.created_at | formatDate }}</div>
        </div>
      </div>
      <b-field label="Message (empty to clear)">
        <b-input v-model="message" />
      </b-field>
      <div style="margin: 1rem 0 1rem">
        This will display a toast to all users.<br><br>
        Example: PlayMetrics will be down for maintenance on Tues 7/26 5am EDT for about 15 minutes.
      </div>
      <button @click="publish()" class="button">Publish Notification</button>
    </div>
        <div class="box">
      <div class="is-size-4">Site Down Page</div>
      <div style="margin-bottom:1rem">
        <span v-if="!currSitedown" class="is-italic">Empty</span>
        <div v-else>
          <div class="has-text-weight-bold">{{ currSitedown.message }}</div>
          <div class="is-italic is-size-7">Published on {{ currSitedown.created_at | formatDate }}</div>
        </div>
      </div>
      <b-field label="Message (empty to clear)">
        <b-input v-model="messageSitedown" />
      </b-field>
      <div style="margin: 1rem 0 1rem">
        This will display a page with site down message disabling all frontends including login.
        Once cleared users will return to their previous page.<br><br>
        Example: PlayMetrics is currently down for maintenance.  We expect to restore service by 5:15am EDT.
      </div>
      <button @click="publishSitedown()" class="button">Publish Sitedown</button>
    </div>
  </div>
</template>
<script>
import api from '@/http-playmetrics';
import { getDatabase, onValue, ref } from 'firebase/database';


export default {
  data() {
    return {
      currMessage: null,
      currSitedown: null,
      message: '',
      messageSitedown: '',
    };
  },
  created() {
    this.fetchNotification();
    this.fetchSitedownMessage();
  },
  methods: {
    fetchNotification() {
      const database = getDatabase(this.$firebaseApp);
      const toastRef = ref(database, 'global/toast');
      onValue(toastRef, (update) => {
        this.currMessage = update.val();
      });
    },
    fetchSitedownMessage() {
      const database = getDatabase(this.$firebaseApp);
      const toastRef = ref(database, 'global/sitedown');
      onValue(toastRef, (update) => {
        this.currSitedown = update.val();
      });
    },
    publish() {
      const toPost = { message: this.message || null };
      api().post('/admin/system_notification', toPost).then(() => {
        this.$store.dispatch('setSuccessToast', this.message ? 'Notification Posted' : 'Notification Cleared');
      });
    },
    publishSitedown() {
      const toPost = { message: this.messageSitedown || null };
      api().post('/admin/sitedown_notification', toPost).then(() => {
        this.$store.dispatch('setSuccessToast', this.messageSitedown ? 'Sitedown Notification Posted' : 'Sitedown Notification Cleared');
      });
    },
  },
};
</script>
