<template>
    <div>
      <div class="box"
      v-if="!searched">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <div class="field">
                <div class="control">
                <input type="text" v-model="club" list="filterClub" size="50">
                </div>
                <datalist
                placeholder="Select a club..."
                id="filterClub">
                <option
                v-for="club in filterClubs"
                :value="club.name"
                 :key="club.id">
                {{ club.name }}
                </option>
                </datalist>
              </div>
            </div>
            <div class="level-item">
              <b-switch
              v-model="includeTestClubs"
              @input="club=null">
              Include Test Clubs
              </b-switch>
            </div>
            <div class="level-item">
              <b-button
              :disabled="invalid"
              class="is-primary"
              @click="search">
              Generate Summary
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="searched">
      <app-spinner v-if="loading"></app-spinner>
        <div v-if="!loading">
          <div class="box">
            <div class="level">
              <div class="level-left">
                <div class="columns">
                  <div class="column">
                    <b-button
                    class="is-primary"
                    @click="newSearch">
                    New Search
                    </b-button>
                  </div>
                  <div class="column">
                    <router-link
                    v-if="boolSeSearch"
                    tag="span"
                    :to="{ path: `/season_status`,
                    query: { selected: this.selectSearch }}">
                      <b-button
                      class="is-primary">
                      Return to Season Status
                      </b-button>
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="level-right">
                <a class="button is-primary"
                :href="exportSummary"
                target="_blank">
                Export Roster Counts
                </a>
              </div>
            </div>
          </div>
          <div class="box">
            <h4 class="title is-4">Club Summary for {{ selectClub.name }}</h4>
            <p>Club Status: <b>{{ onboardingStatusEnabled ? selectClub.config.onboarding_status : 'Not Set' }}</b></p>
            <p>Onboarding Type: <b>{{ onboardingTypeEnabled ? selectClub.config.onboarding_type : 'Not Set' }}</b></p>
            <b-table
            :data="playerList">
              <template slot-scope="props">
                <b-table-column field="type" label="Type">
                  {{ props.row.type }}
                </b-table-column>
                <b-table-column field="contract" label="Contract">
                  {{ props.row.contract }}
                </b-table-column>
                <b-table-column field="weeklyplayer" :label=weekOf>
                  {{ props.row.weeklyplayer }}
                </b-table-column>
                <b-table-column field="difference" label="Difference">
                  {{ props.row.difference }}
                </b-table-column>
              </template>
            </b-table>
          </div>
          <div class="box">
            <h4 class="title is-4">Club Setup for {{ selectClub.name }}</h4>
              <div class="box">
                <h5 class="title is-5">STAFF</h5>
                  <b-table
                  :data="staffList">
                    <template slot-scope="props">
                      <b-table-column field="role" label="Role">
                        {{ props.row.role }}
                      </b-table-column>
                      <b-table-column field="total" label="Total">
                        {{ props.row.total }}
                      </b-table-column>
                      <b-table-column field="verified_accounts" label="Verified Accounts">
                        {{ props.row.verified_accounts }}
                      </b-table-column>
                    </template>
                  </b-table>
                <h5 class="title is-5">TEAMS</h5>
                  <div class="field">
                    <p><b>{{ clubTeamSummary.total_teams }}</b> teams in the current season</p>
                    <p><b>{{ clubTeamSummary.rostered_teams_count }}</b> teams with rostered players</p>
                  </div>
                <h5 class="title is-5">PLAYERS</h5>
                  <div class="field">
                    <p><b>{{ clubPlayerSummary.active_player_count }}</b> active players</p>
                    <p><b>{{ clubPlayerSummary.archived_player_count }}</b> archived players</p>
                    <p><b>{{ clubPlayerSummary.deleted_player_count }}</b> deleted players</p>
                  </div>
              </div>
              <div class="box">
                <h4 class="title is-4">REGISTRATION</h4>
                <div class="field"
                v-if="selectClub.config.enable_field_planning">
                  <p><b>{{ clubRegistrationSummary.programs_count}}</b> programs currently open</p>
                  <p><b>{{ clubRegistrationSummary.transaction_count }}</b> transactions processed in the last 30 days</p>
                  <p><b>{{ clubRegistrationSummary.total_amount | currency }}</b> processed in the last 30 days</p>
                </div>
                <div class="field"
                v-else>
                  <p>{{ selectClub.name }} has not enabled registration.</p>
                </div>
              </div>
              <div class="box">
                <h4 class="title is-4">FIELD PLANS</h4>
                <div class="field"
                v-if="selectClub.config.enable_field_planning">
                  <p><b>{{ clubFieldPlanSummary.total_plan_count }}</b> field plans</p>
                  <p><b>{{ clubFieldPlanSummary.previous_assignments_count}}</b> assignments in the last 30 days</p>
                  <p><b>{{ clubFieldPlanSummary.upcoming_assignments_count }}</b> assignments in the next 30 days</p>
              </div>
              <div class="field"
              v-else>
                <p>{{ selectClub.name }} is not enabled for field planning.</p>
              </div>
            </div>
            <div class="box">
              <h4 class="title is-4">PRACTICES</h4>
              <p><b>{{ clubPracticeSummary.previous_practice_count }}</b> practices in the last 30 days</p>
              <p><b>{{ clubPracticeSummary.practice_count_with_drills }}</b>
               practices in the last 30 days with at least one drill</p>
              <p><b>{{ clubPracticeSummary.upcoming_practice_count }}</b> practices in the next 30 days</p>
            </div>
            <div class="box">
              <h4 class="title is-4">GAMES</h4>
              <p><b>{{ clubGameSummary.previous_game_count }}</b> games in the last 30 days</p>
              <p><b>{{ clubGameSummary.game_count_with_starters }}</b> games in the last 30 days with a game lineup</p>
              <p><b>{{ clubGameSummary.upcoming_game_count }}</b> games in the next 30 days</p>
          </div>
          <div class="box">
            <h4 class="title is-4">FORMS</h4>
            <div class="field"
            v-if="selectClub.config.enable_forms">
              <p><b>{{ clubFormSummary.form_template_count }}</b> form templates</p>
              <p><b>{{ clubFormSummary.form_request_count }}</b> form requests created in the last 30 days</p>
              <p><b>{{ clubFormSummary.completed_form_count }}</b> forms completed in the last 30 days</p>
            </div>
            <div class="field"
            v-else>
              <p>{{ selectClub.name }} is not enabled for club forms.</p>
            </div>
          </div>
          <div class="box">
            <h4 class="title is-4">SEASON PLANS</h4>
            <div class="field"
            v-if="selectClub.config.enable_season_planning">
              <p><b>{{ clubSeasonPlanSummary.season_plan_count }}</b> open season plans</p>
              <p><b>{{ clubSeasonPlanSummary.teams_with_season_plan_count }}</b> current teams associated with a season plan</p>
            </div>
            <div class="field"
            v-else>
              <p>{{ selectClub.name }} is not enabled for season planning.</p>
            </div>
          </div>
          <div class="box">
            <h4 class="title is-4">DRILLS</h4>
            <p><b>{{ clubDrillSummary.club_drill_count }}</b> club created drills</p>
            <p><b>{{ clubDrillSummary.club_coach_drill_count }}</b> coach created drills</p>
            <p><b>{{ clubDrillSummary.new_club_drill_count }}</b> club created drills in the last 30 days</p>
            <p><b>{{ clubDrillSummary.new_coach_drill_count }}</b> coach created drills in the last 30 days</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from '@/http-playmetrics';
import _ from 'lodash';
import Spinner from '@/components/common/Spinner';

export default {
  components: {
    'app-spinner': Spinner,
  },

  data() {
    return {
      searched: false,
      loading: false,
      includeTestClubs: false,
      clubs: [],
      club: null,
      selectClub: null,
      clubID: null,
      clubTeamStats: null,
      clubStaffSummary: null,
      clubTeamSummary: null,
      clubPlayerSummary: null,
      clubRegistrationSummary: null,
      clubFieldPlanSummary: null,
      clubPracticeSummary: null,
      clubGameSummary: null,
      clubFormSummary: null,
      clubSeasonPlanSummary: null,
      clubDrillSummary: null,
      onboardingType: true,
      onboardingStatus: true,
      urlExport: null,
      selectSearch: null,
      staffList: [
        { role: 'Club Staff', total: 0, verified_accounts: 0 },
        { role: 'Coaching Staff', total: 0, verified_accounts: 0 },
        { role: 'Team Staff', total: 0, verified_accounts: 0 },
      ],
      playerList: [
        { type: 'Recreation', contract: 0, weeklyplayer: 0, difference: 0 },
        { type: 'Competitive', contract: 0, weeklyplayer: 0, difference: 0 },
        { type: 'Total', contract: 0, weeklyplayer: 0, difference: 0 },
      ],
    };
  },
  computed: {
    selected() {
      return this.$router.query.selected;
    },
    exportSummary() {
      return `${this.urlExport}`;
    },
    activeRecPlayers() {
      if (!Number.isNaN(this.clubTeamStats.active_rec_players)) {
        return this.clubTeamStats.active_rec_players;
      } else {
        return 0;
      }
    },
    activeCompPlayers() {
      if (!Number.isNaN(this.clubTeamStats.active_competitive_players)) {
        return this.clubTeamStats.active_competitive_players;
      } else {
        return 0;
      }
    },
    contractRecPlayers() {
      if (this.selectClub.config.rec_player_cap !== undefined && this.selectClub.config.rec_player_cap !== null
      && !Number.isNaN(this.selectClub.config.rec_player_cap)) {
        return this.selectClub.config.rec_player_cap;
      } else {
        return 0;
      }
    },
    contractCompPlayers() {
      if (this.selectClub.config.competitive_player_cap !== undefined && this.selectClub.config.competitive_player_cap !== null
      && !Number.isNaN(this.selectClub.config.competitive_player_cap)) {
        return this.selectClub.config.competitive_player_cap;
      } else {
        return 0;
      }
    },
    weekOf() {
      return `Week of ${this.clubTeamStats.captured_on}`;
    },
    boolSeSearch() {
      if (this.$route.query.searched) {
        return true;
      } else {
        return false;
      }
    },
    invalid() {
      const check = _.map(this.filterClubs, cid => cid.name === this.club);
      return !check.includes(true);
    },
    filterClubs() {
      if (this.includeTestClubs) {
        return _.sortBy(this.clubs, 'name');
      } else {
        return _.sortBy(this.clubs.filter(user =>
          user.is_test_club === this.includeTestClubs), 'name');
      }
    },
    onboardingTypeEnabled() {
      if (this.selectClub.config.onboarding_type === null || this.selectClub.config.onboarding_type === '') {
        return false;
      } else {
        return true;
      }
    },
    onboardingStatusEnabled() {
      if (this.selectClub.config.onboarding_status === null || this.selectClub.config.onboarding_status === '') {
        return false;
      } else {
        return true;
      }
    },
    recPlayerSet() {
      if (Number.isNaN(this.selectClub.config.rec_player_cap)
      || this.selectClub.config.rec_player_cap === undefined) {
        return false;
      } else {
        return true;
      }
    },
    compPlayerSet() {
      if (Number.isNaN(this.selectClub.config.competitive_player_cap)
      || this.selectClub.config.competitive_player_cap === undefined) {
        return false;
      } else {
        return true;
      }
    },
  },

  methods: {
    queryCatch() {
      if (this.$route.query.searched) {
        this.clubID = parseInt(this.$route.query.club_id, 10);
        this.selectSearch = this.$route.query.selected;
        this.selectClub = _.find(this.clubs, c => c.id === this.clubID);
        this.searched = true;
        this.search();
      }
    },
    newSearch() {
      this.searched = false;
      this.selectClub = null;
      this.includeTestClubs = false;
      this.club = null;
      if (this.boolSeSearch) {
        this.$router.replace('/club_usage_summary');
      }
    },
    search() {
      this.loading = true;
      this.searched = true;
      if (!this.$route.query.searched) {
        this.selectClub = _.find(this.filterClubs, cis => cis.name === this.club);
      }
      Promise.all([
        this.fetchStaffSummary(),
        this.fetchTeamSummary(),
        this.fetchPlayerSummary(),
        this.fetchRegistrationSummary(),
        this.fetchFieldPlanSummary(),
        this.fetchPracticeSummary(),
        this.fetchGameSummary(),
        this.fetchFormSummary(),
        this.fetchSeasonPlanSummary(),
        this.fetchDrillSummary(),
        this.fetchTeamPlayerStats(),
        this.fetchExportURL(),
      ]).then(() => {
        this.staffList[0].total = this.clubStaffSummary.staff_count;
        this.staffList[0].verified_accounts = this.clubStaffSummary.active_staff_count;
        this.staffList[1].total = this.clubStaffSummary.coach_count;
        this.staffList[1].verified_accounts = this.clubStaffSummary.active_coach_count;
        this.staffList[2].total = this.clubStaffSummary.team_staff_count;
        this.staffList[2].verified_accounts = this.clubStaffSummary.active_team_staff_count;
        this.playerList[0].weeklyplayer = this.activeRecPlayers;
        this.playerList[0].contract = this.contractRecPlayers;
        this.playerList[0].difference = this.contractRecPlayers - this.activeRecPlayers;
        this.playerList[1].weeklyplayer = this.activeCompPlayers;
        this.playerList[1].contract = this.contractCompPlayers;
        this.playerList[1].difference = this.contractCompPlayers - this.activeCompPlayers;
        this.playerList[2].contract = this.contractRecPlayers + this.contractCompPlayers;
        this.playerList[2].weeklyplayer = this.activeCompPlayers + this.activeRecPlayers;
        this.playerList[2].difference = this.playerList[2].contract - this.playerList[2].weeklyplayer;
        this.loading = false;
      });
    },
    fetchExportURL() {
      return api().serverURL(`/admin/clubs/${this.selectClub.id}/summary/roster_counts.csv`).then((url) => {
        this.urlExport = url;
      });
    },
    fetchStaffSummary() {
      return api().get(`admin/clubs/${this.selectClub.id}/summary/staff`).then((resp2) => {
        this.clubStaffSummary = resp2.data;
      });
    },
    fetchTeamSummary() {
      return api().get(`admin/clubs/${this.selectClub.id}/summary/teams`).then((resp2) => {
        this.clubTeamSummary = resp2.data;
      });
    },
    fetchPlayerSummary() {
      return api().get(`admin/clubs/${this.selectClub.id}/summary/players`).then((resp2) => {
        this.clubPlayerSummary = resp2.data;
      });
    },
    fetchRegistrationSummary() {
      return api().get(`admin/clubs/${this.selectClub.id}/summary/registrations`).then((resp2) => {
        this.clubRegistrationSummary = resp2.data;
      });
    },
    fetchFieldPlanSummary() {
      return api().get(`admin/clubs/${this.selectClub.id}/summary/field-plans`).then((resp2) => {
        this.clubFieldPlanSummary = resp2.data;
      });
    },
    fetchPracticeSummary() {
      return api().get(`admin/clubs/${this.selectClub.id}/summary/practices`).then((resp2) => {
        this.clubPracticeSummary = resp2.data;
      });
    },
    fetchGameSummary() {
      return api().get(`admin/clubs/${this.selectClub.id}/summary/games`).then((resp2) => {
        this.clubGameSummary = resp2.data;
      });
    },
    fetchFormSummary() {
      return api().get(`admin/clubs/${this.selectClub.id}/summary/forms`).then((resp2) => {
        this.clubFormSummary = resp2.data;
      });
    },
    fetchSeasonPlanSummary() {
      return api().get(`admin/clubs/${this.selectClub.id}/summary/season-plans`).then((resp2) => {
        this.clubSeasonPlanSummary = resp2.data;
      });
    },
    fetchDrillSummary() {
      return api().get(`admin/clubs/${this.selectClub.id}/summary/drills`).then((resp2) => {
        this.clubDrillSummary = resp2.data;
      });
    },
    fetchTeamPlayerStats() {
      return api().get(`admin/clubs/${this.selectClub.id}/summary/team_player_stats`).then((resp2) => {
        this.clubTeamStats = resp2.data;
      });
    },
    fetchClubs() {
      this.loading = true;
      api().get('admin/clubs').then((resp1) => {
        this.clubs = _.map(resp1.data, (clu) => {
          let name = clu.name;
          if (clu.is_test_club) {
            name += ' (test club)';
          }
          clu.name = name;
          return clu;
        });
        this.queryCatch();
      });
    },
  },
  created() {
    this.fetchClubs();
  },
};
</script>
