// state
const state = {
  notification: null,
};

// getters
const getters = {
  notification(state) {
    return state.notification;
  },
};

// mutations
const mutations = {
  setNotification(state, payload) {
    state.notification = payload;
  },
};

// actions
const actions = {
  createNotification(context, payload) {
    context.commit('setNotification', payload);
  },
  clearNotification(context) {
    context.commit('setNotification', {});
  },
  setSuccessToast(context, message) {
    context.commit('setNotification', { toast: true, success: true, message });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
