<template>
    <div>
        <div class="box"
        v-if="!searched">
          <div class="level">
            <app-spinner v-if="loading"></app-spinner>
            <b-radio
            v-model="searchSelect"
            name="name"
            native-value="clubNoUpcoming">
            View Clubs Without an Upcoming Season</b-radio>
            <b-radio v-model="searchSelect" name="name" native-value="clubTransition">View Clubs In Transition</b-radio>
            <b-radio v-model="searchSelect" name="name" native-value="clubSeason">View Club Season</b-radio>
            <b-button
            @click="search"
            :disabled="noSearch"
            class="is-primary">
            Search
            </b-button>
          </div>
        </div>
        <div v-else>
          <div class="box">
            <div class="level">
              <div class="level-left">
                <b-switch
                  v-if="viewClubNoUpcoming || viewClubTransition"
                  v-model="showTestClubs">
                  Test Clubs
                </b-switch>
              </div>
              <div class="level-right">
                <b-button @click="newSearch" class="is-primary">New Search</b-button>
              </div>
            </div>
          </div>
          <div class="box"
          v-if="viewClubNoUpcoming">
          <app-spinner v-if="loading"></app-spinner>
            <b-table
            :data="filteredNoUpcomingClubs"
            :per-page="perPage"
            narrowed
            hoverable
            mobile-cards
            paginated
            pagination-simple>
              <template slot-scope="props">
                <b-table-column field="club_id" label="Club ID">
                {{ props.row.club_id }}
                </b-table-column>
                <b-table-column field="club_name" label="Club Name">
                {{ props.row.club_name }}
                </b-table-column>
                <b-table-column class="has-text-right" field="estimated_players" label="Estimated Players">
                {{ props.row.estimated_players }}
                </b-table-column>
                <b-table-column class="has-text-right" field="teams_ending_soon" label="Teams Ending Soon">
                {{ props.row.num_teams_ending }}
                </b-table-column>
                <b-table-column class="has-text-right" field="teams_season_ended" label="Teams Season has Ended">
                {{ props.row.num_teams_ended }}
                </b-table-column>
                <b-table-column field="club_summary" label="View Club Summary">
                      <router-link :to="{ path: `/club_usage_summary`,
                      query: { club_id: props.row.club_id, searched: true, selected: 'clubNoUpcoming' } }">Summary</router-link>
                </b-table-column>
              </template>
              <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>No results</p>
              </div>
            </section>
          </template>
          <template slot="bottom-left">
            <b-select v-model="perPage">
              <option value="10">10 per page</option>
              <option value="15">15 per page</option>
              <option value="20">20 per page</option>
              <option value="50">50 per page</option>
              <option value="100">100 per page</option>
            </b-select>
          </template>
            </b-table>
          </div>
          <div class="box"
          v-if="viewClubTransition">
          <app-spinner v-if="loading"></app-spinner>
            <b-table
              :data="filteredTransitionClubs"
              :per-page="perPage"
              narrowed
              hoverable
              mobile-cards
              paginated
              pagination-simple>
                <template slot-scope="props">
                  <b-table-column field="club_id" label="Club ID">
                  {{ props.row.club_id }}
                  </b-table-column>
                  <b-table-column field="club_name" label="Club Name">
                  {{ props.row.club_name }}
                  </b-table-column>
                  <b-table-column class="has-text-right" field="estimated_players" label="Estimated Players">
                  {{ props.row.estimated_players }}
                  </b-table-column>
                  <b-table-column class="has-text-right" field="teams_ending_soon" label="Teams Ending Soon">
                  {{ props.row.num_teams_ending }}
                  </b-table-column>
                  <b-table-column class="has-text-right" field="teams_season_ended" label="Teams Season has Ended">
                  {{ props.row.num_teams_ended }}
                  </b-table-column>
                  <b-table-column class="has-text-right" field="teams_upcoming_season" label="Teams on Upcoming Seasons">
                  {{ props.row.num_teams_starting }}
                  </b-table-column>
                  <b-table-column field="club_summary" label="View Club Summary">
                      <router-link :to="{ path: `/club_usage_summary`,
                      query: { club_id: props.row.club_id, searched: true, selected: 'clubTransition' } }">Summary</router-link>
                </b-table-column>
                </template>
                <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>No results</p>
                </div>
              </section>
            </template>
            <template slot="bottom-left">
              <b-select v-model="perPage">
                <option value="10">10 per page</option>
                <option value="15">15 per page</option>
                <option value="20">20 per page</option>
                <option value="50">50 per page</option>
                <option value="100">100 per page</option>
              </b-select>
            </template>
              </b-table>
          </div>
          <div class="box"
          v-if="viewClubSeason">
          <app-spinner v-if="loading"></app-spinner>
            <b-table
                :data="seasonClubs"
                :per-page="perPage"
                narrowed
                hoverable
                mobile-cards
                paginated
                pagination-simple>
                  <template slot-scope="props">
                    <b-table-column field="club_id" label="Club ID">
                    {{ props.row.club_id }}
                    </b-table-column>
                    <b-table-column field="club_name" label="Club Name">
                    {{ props.row.club_name }}
                    </b-table-column>
                    <b-table-column field="club_season" label="Season">
                    {{ props.row.name }}
                    </b-table-column>
                    <b-table-column field="season_start_date" label="Start Date">
                    {{ props.row.start_date }}
                    </b-table-column>
                    <b-table-column field="season_end_date" label="End Date">
                    {{ props.row.end_date }}
                    </b-table-column>
                    <b-table-column class="has-text-right" field="club_team_total" label="Teams">
                    {{ props.row.num_teams }}
                    </b-table-column>
                    <b-table-column field="club_status" label="Status" sortable>
                    {{ props.row.season_status }}
                    </b-table-column>
                  </template>
                  <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p>No results</p>
                  </div>
                </section>
              </template>
              <template slot="bottom-left">
                <b-select v-model="perPage">
                  <option value="10">10 per page</option>
                  <option value="15">15 per page</option>
                  <option value="20">20 per page</option>
                  <option value="50">50 per page</option>
                  <option value="100">100 per page</option>
                </b-select>
              </template>
            </b-table>
        </div>
      </div>
      <div class="box"
      v-if="searchSelect == 'clubSeason' && searched == false">
        <div class="field">
          <div class="level-left">
            <div class="level-item">
            <b-select v-model="club" placeholder="Select a club...">
          <option
          v-for="club in filteredClubs"
          :value="club"
          :key="club.id">
          {{ club.name }}
          </option>
        </b-select>
        <b-switch
              v-model="includeTestClubs"
              @input="club=null">
              Include Test Clubs
            </b-switch>
            </div>
          </div>
        </div>
        <div class="field">
        <b-checkbox v-model="status" native-value="In Season">
          In Season
        </b-checkbox>
        <b-checkbox v-model="status" native-value="Ending Soon">
          Ending Soon
        </b-checkbox>
        <b-checkbox v-model="status" native-value="Ended">
          Ended
        </b-checkbox>
        <b-checkbox v-model="status" native-value="Upcoming">
          Upcoming
        </b-checkbox>
        </div>
      </div>
    </div>
</template>

<script>
import moment from 'moment';
import api from '@/http-playmetrics';
import Spinner from '@/components/common/Spinner';
import _ from 'lodash';

export default {
  components: {
    'app-spinner': Spinner,
  },

  data() {
    return {
      searched: false,
      loading: false,
      searchSelect: '',
      club: null,
      clubs: [],
      status: [],
      includeTestClubs: false,
      perPage: 20,
      seasonClubs: [],
      transitionClubs: [],
      noUpcomingClubs: [],
      showTestClubs: false,
    };
  },
  computed: {
    boolReSearch() {
      if (this.$route.query.selected) {
        return true;
      } else {
        return false;
      }
    },
    filteredClubs() {
      if (this.includeTestClubs) {
        return this.clubs;
      } else {
        return this.clubs.filter(user =>
          user.is_test_club === this.includeTestClubs);
      }
    },
    noSearch() {
      return _.isEmpty(this.searchSelect);
    },
    viewClubNoUpcoming() {
      return this.searchSelect === 'clubNoUpcoming';
    },
    viewClubTransition() {
      return this.searchSelect === 'clubTransition';
    },
    viewClubSeason() {
      return this.searchSelect === 'clubSeason';
    },
    filteredTransitionClubs() {
      return this.transitionClubs.filter(club =>
        !club.club_name.includes('DELETED -') &&
        club.is_test_club === this.showTestClubs,
      );
    },
    filteredNoUpcomingClubs() {
      return this.noUpcomingClubs.filter(club =>
        !club.club_name.includes('DELETED -') &&
        club.is_test_club === this.showTestClubs,
      );
    },
  },

  methods: {
    returnSearch() {
      this.searchSelect = this.$route.query.selected;
      this.search();
    },
    fetchClubs() {
      api().get('admin/clubs')
        .then((response) => {
          this.clubs = _.sortBy(response.data, 'name');
          this.loading = false;
          if (this.$route.query.selected !== undefined) {
            this.returnSearch();
          }
        }).catch(() => {
          this.loading = false;
        });
    },
    newSearch() {
      this.searched = false;
      this.searchSelect = '';
      this.status = [];
      this.includeTestClubs = false;
      this.club = null;
      this.showTestClubs = false;
      if (this.boolReSearch) {
        this.$router.replace('/season_status');
      }
    },
    search() {
      this.loading = true;
      this.searched = true;
      if (_.isEmpty(this.status)) {
        this.status = ['In Season', 'Ending Soon', 'Ended', 'Upcoming'];
      }
      if (this.searchSelect === 'clubSeason') {
        api().get('admin/seasons/search').then((resp2) => {
          this.seasonClubs = _.sortBy(resp2.data, ses => ses.club_id);
          if (this.club !== null) {
            this.seasonClubs = _.filter(this.seasonClubs, clid => _.includes(this.club, clid.club_id));
          }
          this.seasonClubs = _.map(this.seasonClubs, (sesClub) => {
            const clubCheck = _.find(this.clubs, c => c.id === sesClub.club_id);
            if (clubCheck) {
              sesClub.club_name = clubCheck.name;
            }
            return sesClub;
          });
          _.forEach(this.seasonClubs, (cd) => {
            const startDate = moment(cd.start_date);
            const endDate = moment(cd.end_date);
            if (startDate.isAfter(moment(), 'day') && _.includes(this.status, 'Upcoming')) {
              cd.season_status = 'Upcoming';
            } else if (endDate.isBefore(moment(), 'day') && _.includes(this.status, 'Ended')) {
              cd.season_status = 'Ended';
            } else if (startDate.isSameOrBefore(moment(), 'day') && moment().add(30, 'd').isSameOrAfter(endDate, 'day') && moment().isBefore(endDate, 'day') && _.includes(this.status, 'Ending Soon')) {
              cd.season_status = 'Ending Soon';
            } else if (startDate.isSameOrBefore(moment(), 'day') && moment().add(30, 'd').isBefore(endDate, 'day') && _.includes(this.status, 'In Season')) {
              cd.season_status = 'In Season';
            }
          });
          this.seasonClubs = _.filter(this.seasonClubs, fil => _.includes(this.status, fil.season_status));
          this.loading = false;
        });
      } else if (this.searchSelect === 'clubTransition') {
        api().get('admin/seasons/clubs_in_transition').then((resp3) => {
          this.transitionClubs = _.sortBy(resp3.data, sesID => sesID.club_id);
          this.transitionClubs = _.map(this.transitionClubs, (sClubID) => {
            const clubCheck2 = _.find(this.clubs, clubCheckID => clubCheckID.id === sClubID.club_id);
            if (clubCheck2) {
              sClubID.club_name = clubCheck2.name;
              sClubID.is_test_club = clubCheck2.is_test_club;
            }
            if (Number.isNaN(clubCheck2.config.competitive_player_cap) || clubCheck2.config.competitive_player_cap === undefined) {
              clubCheck2.config.competitive_player_cap = 0;
            }
            if (Number.isNaN(clubCheck2.config.rec_player_cap) || clubCheck2.config.rec_player_cap === undefined) {
              clubCheck2.config.rec_player_cap = 0;
            }
            sClubID.estimated_players = clubCheck2.config.competitive_player_cap + clubCheck2.config.rec_player_cap;
            return sClubID;
          });
          this.loading = false;
        });
      } else if (this.searchSelect === 'clubNoUpcoming') {
        api().get('admin/seasons/clubs_without_upcoming_seasons').then((resp4) => {
          this.noUpcomingClubs = _.sortBy(resp4.data, nID => nID.club_id);
          this.noUpcomingClubs = _.map(this.noUpcomingClubs, (nClubID) => {
            const clubCheck3 = _.find(this.clubs, nClubCheckID => nClubCheckID.id === nClubID.club_id);
            if (clubCheck3) {
              nClubID.club_name = clubCheck3.name;
              nClubID.is_test_club = clubCheck3.is_test_club;
            }
            if (Number.isNaN(clubCheck3.config.competitive_player_cap) || clubCheck3.config.competitive_player_cap === undefined) {
              clubCheck3.config.competitive_player_cap = 0;
            }
            if (Number.isNaN(clubCheck3.config.rec_player_cap) || clubCheck3.config.rec_player_cap === undefined) {
              clubCheck3.config.rec_player_cap = 0;
            }
            nClubID.estimated_players = clubCheck3.config.competitive_player_cap + clubCheck3.config.rec_player_cap;
            return nClubID;
          });
          this.loading = false;
        });
      }
    },
  },
  created() {
    this.fetchClubs();
  },
};
</script>
