
<template>
  <div>
    <div>
      <h2>DNS entries for domain {{club.email_domain}}</h2>
    </div>
    <b-table
      :data="club.email_domain_dns_entries"
      narrowed
      hoverable
      mobile-cards>
      <template slot-scope="props">
        <b-table-column field="Valid" label="Valid">
          <span v-if="props.row.Valid === 'valid'">
            <img src="/static/images/icons/icon-checkmark.svg" class="check-mark" style="height: 22px">
          </span>
          <span v-else>
            <img src="/static/images/icons/icon-x-mark.svg" class="check-mark" style="height: 22px">
          </span>
        </b-table-column>
        <b-table-column field="record_type" label="Type" sortable>
          {{props.row.record_type}}
        </b-table-column>
        <b-table-column field="Name" label="Name" sortable>
          {{ props.row.Name }}
        </b-table-column>
        <b-table-column field="Priority" label="Priority">
          {{ props.row.Priority }}
        </b-table-column>
        <b-table-column field="value" label="Value">
          {{ props.row.Value }}
        </b-table-column>
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <span v-if="(!this.club.email_domain_dns_entries)">
              <span v-if="((this.club.email_domain.endsWith('playmetric.io')) ||
                          (this.club.email_domain.endsWith('pmsends.com')))">
                Wildcard domains do not require DNS entries
              </span>
              <span v-else>
                Warning: This domain probably should have DNS entries!
              </span>
            </span>
          </div>
        </section>
      </template>
      <template v-slot:foot()>
        <p>stuf</p>
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>This domain hasn't been created yet.</p>
          </div>
        </section>
      </template>
    </b-table>
    <app-create-domain
      v-if="createModal"
      :club="club"
      @dismissModal="dismissModal($event)">
    </app-create-domain>
  </div>
</template>

<script>
import api from '@/http-playmetrics';
import ClubCreateDomain from './ClubCreateDomain';

export default {
  components: {
    'app-create-domain': ClubCreateDomain,
  },
  data() {
    return {
      formTemplates: null,
      createModal: false,
    };
  },
  props: {
    club: { type: Object, required: true },
  },
  methods: {
    loadDnsEntries() {
      api().get(`/admin/clubs/${this.$route.params.club}`)
        .then((response) => {
          this.club.email_domain_dns_entries = response.data.email_domain_dns_entries;
          if ((this.club.email_domain.endsWith('playmetric.io')) || (this.club.email_domain.endsWith('pmsends.com'))) {
            this.warning = false;
          } else {
            this.warning = true;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    createDomain() {
      this.createModal = true;
    },
    dismissModal() {
      this.createModal = false;
      this.loadDnsEntries();
    },
  },
};
</script>
