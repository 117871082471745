import _ from 'lodash';
import axios from 'axios';
import { getAuth, getIdToken } from 'firebase/auth';

import store from '@/store/store';
import Vue from 'vue';
import CONFIG from './env-config';

export default function api(options = { toast: true, showError: true }) {
  function create(firebaseToken) {
    const headers = {
      'Content-Type': 'application/json',
      'Firebase-Token': firebaseToken,
    };
    if (store.getters.user && store.getters.user.access_key) headers['pm-access-key'] = store.getters.user.access_key;
    return axios.create({
      baseURL: CONFIG.backendBaseURL,
      headers,
      timeout: options.timeout || 0,
    });
  }

  function errorToMessage(error) {
    if (_.isString(error)) return error;
    let message = error.message;
    if (error.response && error.response.data && error.response.data.error) {
      message = error.response.data.error;
    } else if (error.response && error.response.data && error.response.data.errors) {
      message = _.join(_.map(error.response.data.errors, (v, k) => (k ? `${k}: ${v}` : v)), '; ');
    }
    if (message.startsWith('timeout of') ||
        message.startsWith('Network Error') ||
        (error.response && error.response.status === 504)) {
      message = 'A connection to PlayMetrics cannot be established.  Please try again later.';
    }
    return message || 'Unknown Error';
  }

  function handleError(error) {
    const message = errorToMessage(error);
    console.log('handleError', message, options.showError);
    if (options.showError) {
      store.commit('setNotification', { message, success: false, toast: options.toast });
    }
    throw error;
  }

  function axiosWithToken() {
    const auth = Vue.prototype.$firebaseApp ? getAuth(Vue.prototype.$firebaseApp) : null;
    if (!auth || !auth.currentUser) {
      return Promise.resolve(create(null));
    }
    return getIdToken(auth.currentUser).then(firebaseToken => create(firebaseToken));
  }

  function get(url, headers) {
    return axiosWithToken().then(ax => ax.get(url, headers)).catch(error => handleError(error));
  }
  function post(url, data, config) {
    return axiosWithToken().then(ax => ax.post(url, data, config)).catch(e => handleError(e));
  }
  function deleteMethod(url, headers) {
    return axiosWithToken().then(ax => ax.delete(url, headers)).catch(e => handleError(e));
  }

  return {
    get,
    post,
    delete: deleteMethod,
    errorToMessage,

    serverURL(url) {
      const auth = getAuth(Vue.prototype.$firebaseApp);
      if (!auth.currentUser) return Promise.resolve('');
      return getIdToken(auth.currentUser).then((firebaseToken) => {
        const urlReturn = `${CONFIG.backendBaseURL}${url}?access_key=${store.getters.user.access_key}&fbt=${firebaseToken}`;
        return urlReturn;
      });
    },
    uploadUserFile(file) {
      // First get Upload URL
      return api().post('/user/upload_url', {
        name: file.name,
        content_type: file.type,
      }).then((response) => {
        const uploadData = response.data;
        // Upload file
        return axios.put(uploadData.upload_url, file, {
          headers: {
            'Content-Type': file.type,
          },
        }).then((response2) => {
          console.log('response2=', response2); // eslint-disable-line no-console
          // Mark as Public
          return api().post('/user/upload_url/finalize', uploadData).then(() => (uploadData.url));
        });
      });
    },
    statesForCountry(country) {
      if (country === 'US') {
        return [
          { abbr: 'AL', name: 'Alabama' },
          { abbr: 'AK', name: 'Alaska' },
          { abbr: 'AZ', name: 'Arizona' },
          { abbr: 'AR', name: 'Arkansas' },
          { abbr: 'CA', name: 'California' },
          { abbr: 'CO', name: 'Colorado' },
          { abbr: 'CT', name: 'Connecticut' },
          { abbr: 'DC', name: 'District of Columbia' },
          { abbr: 'DE', name: 'Delaware' },
          { abbr: 'FL', name: 'Florida' },
          { abbr: 'GA', name: 'Georgia' },
          { abbr: 'HI', name: 'Hawaii' },
          { abbr: 'ID', name: 'Idaho' },
          { abbr: 'IL', name: 'Illinois' },
          { abbr: 'IN', name: 'Indiana' },
          { abbr: 'IA', name: 'Iowa' },
          { abbr: 'KS', name: 'Kansas' },
          { abbr: 'KY', name: 'Kentucky' },
          { abbr: 'LA', name: 'Louisiana' },
          { abbr: 'ME', name: 'Maine' },
          { abbr: 'MD', name: 'Maryland' },
          { abbr: 'MA', name: 'Massachusetts' },
          { abbr: 'MI', name: 'Michigan' },
          { abbr: 'MN', name: 'Minnesota' },
          { abbr: 'MS', name: 'Mississippi' },
          { abbr: 'MO', name: 'Missouri' },
          { abbr: 'MT', name: 'Montana' },
          { abbr: 'NE', name: 'Nebraska' },
          { abbr: 'NV', name: 'Nevada' },
          { abbr: 'NH', name: 'New Hampshire' },
          { abbr: 'NJ', name: 'New Jersey' },
          { abbr: 'NM', name: 'New Mexico' },
          { abbr: 'NY', name: 'New York' },
          { abbr: 'NC', name: 'North Carolina' },
          { abbr: 'ND', name: 'North Dakota' },
          { abbr: 'OH', name: 'Ohio' },
          { abbr: 'OK', name: 'Oklahoma' },
          { abbr: 'OR', name: 'Oregon' },
          { abbr: 'PA', name: 'Pennsylvania' },
          { abbr: 'RI', name: 'Rhode Island' },
          { abbr: 'SC', name: 'South Carolina' },
          { abbr: 'SD', name: 'South Dakota' },
          { abbr: 'TN', name: 'Tennessee' },
          { abbr: 'TX', name: 'Texas' },
          { abbr: 'UT', name: 'Utah' },
          { abbr: 'VT', name: 'Vermont' },
          { abbr: 'VA', name: 'Virginia' },
          { abbr: 'WA', name: 'Washington' },
          { abbr: 'WV', name: 'West Virginia' },
          { abbr: 'WI', name: 'Wisconsin' },
          { abbr: 'WY', name: 'Wyoming' },
        ];
      }
      if (country === 'CA') {
        return [
          { abbr: 'AB', name: 'Alberta' },
          { abbr: 'BC', name: 'British Columbia' },
          { abbr: 'MB', name: 'Manitoba' },
          { abbr: 'NB', name: 'New Brunswick' },
          { abbr: 'NL', name: 'Newfoundland and Labrador' },
          { abbr: 'NT', name: 'Northwest Territories' },
          { abbr: 'NS', name: 'Nova Scotia' },
          { abbr: 'NU', name: 'Nunavut' },
          { abbr: 'ON', name: 'Ontario' },
          { abbr: 'PE', name: 'Prince Edward Island' },
          { abbr: 'QC', name: 'Quebec' },
          { abbr: 'SK', name: 'Saskatchewan' },
          { abbr: 'YT', name: 'Yukon Territory' },
        ];
      }
      return [];
    },
    timezonesForCountry(country) {
      if (country === 'US') {
        return [
          // 'America/Adak',
          'America/Anchorage',
          // 'America/Boise',
          'America/Chicago',
          'America/Denver',
          'America/Detroit',
          'America/Indiana/Indianapolis',
          // 'America/Indiana/Knox',
          // 'America/Indiana/Marengo',
          // 'America/Indiana/Petersburg',
          // 'America/Indiana/Tell_City',
          // 'America/Indiana/Vevay',
          // 'America/Indiana/Vincennes',
          // 'America/Indiana/Winamac',
          // 'America/Juneau',
          // 'America/Kentucky/Louisville',
          // 'America/Kentucky/Monticello',
          'America/Los_Angeles',
          // 'America/Menominee',
          // 'America/Metlakatla',
          'America/New_York',
          // 'America/Nome',
          // 'America/North_Dakota/Beulah',
          // 'America/North_Dakota/Center',
          // 'America/North_Dakota/New_Salem',
          'America/Phoenix',
          // 'America/Shiprock',
          // 'America/Sitka',
          'Pacific/Honolulu',
        ];
      }
      if (country === 'CA') {
        return [
          // 'America/Atikokan',
          // 'America/Blanc-Sablon',
          // 'America/Cambridge_Bay',
          // 'America/Creston',
          // 'America/Dawson',
          // 'America/Dawson_Creek',
          'America/Edmonton',
          // 'America/Glace_Bay',
          // 'America/Goose_Bay',
          'America/Halifax',
          // 'America/Inuvik',
          // 'America/Iqaluit',
          'America/Moncton',
          'America/Montreal',
          // 'America/Nipigon',
          // 'America/Pangnirtung',
          // 'America/Rainy_River',
          // 'America/Rankin_Inlet',
          'America/Regina',
          // 'America/St_Johns',
          // 'America/Swift_Current',
          // 'America/Thunder_Bay',
          'America/Toronto',
          'America/Vancouver',
          // 'America/Whitehorse',
          'America/Winnipeg',
        ];
      }
      return [];
    },
  };
}
