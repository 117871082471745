<template>
  <div>
    <div class="box">
      <nav class="level">
        <div class="level-left">
          <div class="level-item">
            <nav class="breadcrumb">
              <ul>
                <li>
                  <router-link to="/" tag="a">
                    Home
                  </router-link>
                </li>
                <li>
                  <router-link to="/emails" tag="a">
                    Emails
                  </router-link>
                </li>
                <li class="is-active">
                  <router-link :to="`/emails/${$route.params.email}`" tag="a">
                    {{$route.params.email}}
                  </router-link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div class="level-right">
          <p class="level-item">
            <a :href="emailTemplateURL"
              target="_blank"
              class="button is-primary">
              <span class="icon">
                <i class="fas fa-external-link-alt"></i>
              </span>
              <span>View in New Window</span>
            </a>
          </p>
          <p class="level-item">
            <button @click="sendSampleEmail()"
              class="button is-primary"
              :class="{'is-loading': loading}"
              :disabled="loading">
              <span class="icon">
                <i class="fas fa-envelope"></i>
              </span>
              <span>Send Sample Email</span>
            </button>
          </p>
        </div>
      </nav>
    </div>
    <div class="box" style="height:800px;">
      <app-spinner v-if="!emailLoaded"></app-spinner>
      <iframe v-show="emailLoaded"
        :srcdoc="emailTemplate"
        ref="frame"
        style="width:100%; height:100%;"
        frameborder="0">
      </iframe>
    </div>
  </div>
</template>

<script>
import api from '@/http-playmetrics';
import Spinner from '@/components/common/Spinner';

export default {
  components: {
    'app-spinner': Spinner,
  },

  data() {
    return {
      emailTemplateName: this.$route.params.email,
      emailLoaded: false,
      loading: false,
      emailTemplateURL: null,
      emailTemplate: null,
    };
  },

  created() {
    api().get(`admin/email_templates/${this.$route.params.email}`).then((resp) => {
      this.emailTemplate = resp.data;
      this.emailLoaded = true;
    });
    api().serverURL(`admin/email_templates/${this.$route.params.email}`).then((url) => {
      this.emailTemplateURL = url;
    });
  },
  methods: {
    sendSampleEmail() {
      this.loading = true;
      api().post(`/admin/email_templates/${this.emailTemplateName}/send_email`, {})
        .then((response) => {
          this.loading = false;
          this.$store.dispatch('createNotification', { message: response.data, success: true, toast: true });
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
