<template>
  <div>
    <div v-if="loading" class="box">
      <app-spinner ></app-spinner>
    </div>
    <div v-if="!loading" class="box">
      <nav class="level">
        <div class="level-left">
          <div class="level-item">
            <nav class="breadcrumb">
              <ul>
                <li>
                  <router-link to="/" tag="a">
                    Home
                  </router-link>
                </li>
                <li>
                  <router-link to="/clubs" tag="a">
                    Clubs
                  </router-link>
                </li>
                <li class="is-active">
                  <router-link :to="`/clubs/${club.id}`" tag="a">
                    {{club.name}}
                  </router-link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div class="level-right">
          <p v-if="activeTab === 'clubInfo'" class="level-item">
            <button
              @click="refreshFirebaseOrg"
              class="button is-primary">
              <span class="icon">
                <i class="fas fa-redo"></i>
              </span>
              <span>Refresh Firebase Org</span>
            </button>
          </p>
          <p class="level-item">
            <button v-if="activeTab === 'clubInfo' && !club.is_disabled && !club.is_deleted"
              @click="disableClubConfirmation"
              class="button is-primary">
              <span class="icon">
                <i class="fas fa-do-not-enter"></i>
              </span>
              <span>Disable Club</span>
            </button>
            <button v-if="activeTab === 'clubInfo' && club.is_disabled && !club.is_deleted"
              @click="enableClub"
              class="button is-primary"
              style="margin-right:0.5em">
              <span class="icon">
                <i class="fas fa-plus"></i>
              </span>
              <span>Enable Club</span>
            </button>
            <button v-if="activeTab === 'clubInfo' && club.is_disabled && !club.is_deleted"
              @click="deleteClubConfirmation"
              class="button is-primary">
              <span class="icon">
                <i class="fas fa-trash"></i>
              </span>
              <span>Delete Club</span>
            </button>
            <button v-if="activeTab === 'programs'"
              @click="createProgramModal = true"
              class="button is-primary">
              <span class="icon">
                <i class="fas fa-plus"></i>
              </span>
              <span>Create Program</span>
            </button>
            <button v-if="activeTab === 'admins'"
              @click="addAdmin"
              class="button is-primary">
              <span class="icon">
                <i class="fas fa-plus"></i>
              </span>
              <span>Create Admin</span>
            </button>
            <button v-if="activeTab === 'domain'  && typeof(club.email_domain_dns_entries) == 'undefined'"
              @click="createDomain"
              class="button is-primary">
              <span class="icon">
                <i class="fas fa-plus"></i>
              </span>
              <span>Create Domain</span>
            </button>
            <button v-if="activeTab === 'domain'  && typeof(club.email_domain_dns_entries) != 'undefined'"
              @click="deleteDomainConfirmation"
              class="button is-primary">
              <span class="icon">
                <i class="fas fa-plus"></i>
              </span>
              <span>Delete Domain</span>
            </button>
          </p>
        </div>
      </nav>
    </div>
    <div v-if="!loading" class="box">
      <div class="tabs is-fullwidth">
        <ul>
          <li @click="tabClicked('clubInfo')"
            :class="{ 'is-active': activeTab === 'clubInfo' }">
            <a>Club Settings</a>
          </li>
          <li @click="tabClicked('admins')"
            :class="{ 'is-active': activeTab === 'admins' }">
            <a>Admins</a>
          </li>
          <li @click="tabClicked('domain')"
            :class="{ 'is-active': activeTab === 'domain' }">
            <a>Domain</a>
          </li>
        </ul>
      </div>
      <div v-if="activeTab === 'clubInfo'" class="columns">
        <div class="column">
          <app-club-info
            :club="club"
            @imageUpdated="fetchClub()" />
        </div>
        <hr/>
      </div>
      <div v-if="activeTab === 'admins'" class="columns">
        <div class="column">
          <app-club-admins
            ref="clubAdmins"
            :club="club">
          </app-club-admins>
        </div>
      </div>
      <div v-if="activeTab === 'domain'" class="columns">
        <div class="column">
          <app-club-domain
            ref="clubDomain"
            :club="club">
          </app-club-domain>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/http-playmetrics';
import Spinner from '@/components/common/Spinner';
import ClubInfo from './ClubInfo';
import ClubAdmins from './ClubAdmins';
import ClubDomain from './ClubDomain';

export default {
  components: {
    'app-spinner': Spinner,
    'app-club-info': ClubInfo,
    'app-club-admins': ClubAdmins,
    'app-club-domain': ClubDomain,
  },

  data() {
    return {
      loading: true,
      club: null,
      activeTab: 'clubInfo',
    };
  },

  methods: {
    tabClicked(tab) {
      this.activeTab = tab;
    },
    fetchClub() {
      api().get(`/admin/clubs/${this.$route.params.club}`)
        .then((response) => {
          this.club = response.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    refreshFirebaseOrg() {
      api().post(`/admin/clubs/${this.club.id}/refresh_org`).then(() => {
        this.$store.dispatch('setSuccessToast', 'Org refreshed');
      });
    },
    disableClubConfirmation() {
      this.$buefy.dialog.confirm({
        title: 'Disable Club',
        message: `Are you sure you want to <b>disable</b><br/> ${this.club.name}?`,
        confirmText: 'Disable',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.disableClub(),
      });
    },
    disableClub() {
      this.loading = true;
      api().post(`/admin/clubs/${this.club.id}/disable`).then(() => {
        this.$store.dispatch('setSuccessToast', 'Club Disabled');
        this.fetchClub();
      });
    },
    enableClub() {
      this.loading = true;
      api().post(`/admin/clubs/${this.club.id}/undisable`).then(() => {
        this.$store.dispatch('setSuccessToast', 'Club Enabled');
        this.fetchClub();
      });
    },
    deleteClubConfirmation() {
      this.$buefy.dialog.confirm({
        title: 'Delete Club',
        message: `Are you sure you want to <b>delete</b><br/> ${this.club.name}?`,
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteClub(),
      });
    },
    deleteClub() {
      this.loading = true;
      api().delete(`/admin/clubs/${this.club.id}`).then(() => {
        this.$store.dispatch('setSuccessToast', 'Club Removed');
        this.fetchClub();
      });
    },
    deleteDomainConfirmation() {
      this.$buefy.dialog.confirm({
        title: 'Delete Club',
        message: `Are you sure you want to <b>delete</b><br/> ${this.club.name}'s email domain?  This will set the email_domain back to the default and may impact email delivery.`,
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteDomain(),
      });
    },
    deleteDomain() {
      this.loading = true;
      api().post(`/admin/clubs/${this.club.id}/delete_email_domain`).then(() => {
        this.$store.dispatch('createNotification', { message: 'Domain Removed', success: true, toast: true });
        this.fetchClub();
      });
    },
    addAdmin() {
      this.$refs.clubAdmins.addAdmin();
    },
    createDomain() {
      this.$refs.clubDomain.createDomain();
    },
  },

  created() {
    this.fetchClub();
  },
};
</script>
