<template>
  <footer class="footer">
    <div class="container">
      <div class="content has-text-centered">
        <p class="has-text-white">
          <b><span style="color:tomato">PlayMetrics Admin</span></b>
        </p>
        <p class="has-text-white">
          © 2020-{{ year }} PlayMetrics, LLC. All Rights Reserved.
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style lang="sass" scoped>
  .footer
    background-color: #000
</style>
