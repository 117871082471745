import _ from 'lodash';
import store from '@/store/store';

export const AuthGuard = (to, from, next) => {
  if (store.getters.user) {
    next();
  } else {
    store.commit('setLoginRedirect', to.path);
    next('/login');
  }
};

export const LoggedInGuard = (to, from, next) => {
  if (store.getters.user) {
    next('/');
  } else {
    next();
  }
};

export const HasFullAccessGuard = (to, from, next) => {
  const user = store.getters.user;
  if (user && _.includes(user.admin_permissions, 'admin_tool')) {
    next();
  } else {
    // next('/');
  }
};
