<template>
  <div class="modal is-active">
    <div @click="dismissModal()" class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <div class="modal-card-title">Edit user 2FA number</div>
        <button @click="dismissModal()" class="delete" aria-label="close"></button>
      </header>
      <section class="modal-card-body">
        <b-field label="2FA number"
                 :type="{ 'is-danger': isNumberInvalid }"
                 :message="errors">
          <b-input type="tel"
                   @keydown.native="checkInput"
                   @paste.native="formatPaste"
                   v-model="phoneNumber"
                   >
          </b-input>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <button @click="saveUser2FA"
                class="button is-primary"
                :class="{'is-loading': loading}"
                :disabled="loading || isNumberInvalid">
          Save
        </button>
        <button @click="dismissModal()" class="button">Cancel</button>
      </footer>
    </div>
  </div>
</template>

<script>
import api from '@/http-playmetrics';
import _ from 'lodash';

const PHONE_NUMBER_REGEX = /^[+]?[0-9]{0,2}[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4}$/;
const metaKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete'];
const allowedKeys = ['+', '(', ')', '-', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
export default {
  props: {
    user: { type: Object, required: true },
  },
  data() {
    return {
      loading: false,
      phoneNumber: '',
    };
  },
  created() {
    this.phoneNumber = this.user.mobile_2fa;
  },
  computed: {
    formattedNumber() {
      const processedNumber = this.removeSeparators(this.phoneNumber);
      if (processedNumber.length === 10) {
        return `+1${processedNumber}`;
      }
      return `+${processedNumber}`;
    },
    isNumberInvalid() {
      return !PHONE_NUMBER_REGEX.test(this.phoneNumber) || this.isCountryCodeInvalid || this.isLengthInvalid;
    },
    isCountryCodeInvalid() {
      return this.phoneNumber !== '' && this.phoneNumber.startsWith('+') && !/^((\+1)|\d)/.test(this.phoneNumber);
    },
    isLengthInvalid() {
      const length = this.formattedNumber.length;
      return length !== 12;
    },
    errors() {
      if (this.isCountryCodeInvalid) {
        return [{ 'Phone number must use a +1 country code': true }];
      } else if (this.isLengthInvalid) {
        return [{ 'Phone number must be 10 digits': true }];
      } else if (this.isNumberInvalid) {
        return [{ 'Improper phone number': true }];
      } else {
        return [];
      }
    },
  },
  methods: {
    dismissModal(options = { refreshData: false }) {
      this.$emit('dismissModal', options);
    },
    removeSeparators(number) {
      return number.replaceAll(/\D/gm, '');
    },
    saveUser2FA() {
      if (this.user.mobile_2fa === this.formattedNumber) {
        this.dismissModal();
        return;
      }
      if (this.loading) return;
      this.loading = true;
      const url = `/admin/users/${this.user.id}/mobile2fa`;

      const postBody = {
        mobile_2fa: this.formattedNumber,
      };
      api().post(url, postBody).then(() => {
        this.$store.dispatch('setSuccessToast', '2FA Number Saved');
        this.dismissModal({ refreshData: true });
      })
        .finally(() => {
          this.loading = false;
        });
    },
    checkInput(event) {
      if (_.includes(metaKeys, event.key)) {
        return;
      }
      if (event.key === 'v' && (event.metaKey || event.ctrlKey)) {
        // Allow pasting
        return;
      }
      if (!_.includes(allowedKeys, event.key)) {
        // Prevent invalid keys from being typed
        event.preventDefault();
      }
    },
    formatPaste(event) {
      event.preventDefault();
      const data = event.clipboardData.getData('text');
      this.phoneNumber = data.replaceAll(/[^+\d()-]*/gm, '');
    },
  },
};
</script>
