import moment from 'moment';

export function cmToFt(cm) {
  const realFeet = ((cm * 0.393700) / 12);
  let feet = Math.floor(realFeet);
  let inches = Math.round((realFeet - feet) * 12);
  if (inches === 12) {
    feet += 1;
    inches = 0;
  }
  return `${feet}ft ${inches}in`;
}

export function kgToLbs(kg) {
  return Math.round(kg * 2.20462);
}

export function currency(value, options) {
  if (typeof value !== 'number') {
    return value;
  }
  if (value === 0 && (options || {}).empty) return options.empty;
  const formatterOptions = Object.assign({
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  }, options || {});
  const formatter = new Intl.NumberFormat('en-US', formatterOptions);
  return options && options.fraction ? formatter.format(value) : formatter.format(value / 100.0);
}

export function formatDate(date, format = 'll z') {
  if (!date) {
    return 'N/A';
  }
  return moment(date).format(format);
}
