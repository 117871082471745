<template>
  <div>
    <div class="box">
      <div class="field">
        <div class="control">
          <input v-model.trim="searchQuery" class="input" type="text" placeholder="Search...">
        </div>
      </div>
    </div>
    <div class="box">
      <app-spinner v-if="loading"></app-spinner>
      <div v-else>
        <p class="has-text-right" style="margin-bottom:1rem;">
          <button @click="createAdminUserModal = true" class="button is-primary">
            <span class="icon">
              <i class="fas fa-plus"></i>
            </span>
            <span>Add Admin</span>
          </button>
        </p>
        <b-table
          :data="filteredUsers"
          :per-page="perPage"
          narrowed
          hoverable
          mobile-cards
          paginated
          pagination-simple
          @select="selected"
          :row-class="(function() {return 'clickable';})">
          <template slot-scope="props">
            <b-table-column field="id" label="User ID" sortable>
              {{ props.row.id }}
            </b-table-column>
            <b-table-column field="email" label="Email" sortable>
              {{ props.row.email }}
            </b-table-column>
            <b-table-column field="first_name" label="First" sortable>
              {{ props.row.first_name }}
            </b-table-column>
            <b-table-column field="last_name" label="Last" sortable>
              {{ props.row.last_name }}
            </b-table-column>
            <b-table-column field="registered_at" label="Registered At" sortable>
              <span v-if="props.row.registered_at">
                {{ props.row.registered_at | formatDate('M/D/YY h:mmA') }}
              </span>
              <span v-else>
                <button
                  @click.capture.stop="resend(props.row.email)"
                  class="button is-info is-small">
                  <span>Resend</span>
                </button>
              </span>
            </b-table-column>
            <b-table-column label="" centered>
              <a
                class="has-text-primary clickable"
                @click="removeAdminConfirmation(props.row, $event)">
                <i class="fas fa-trash"></i>
              </a>
            </b-table-column>
          </template>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>No results</p>
              </div>
            </section>
          </template>
          <template slot="bottom-left">
            <b-select v-model="perPage">
              <option value="10">10 per page</option>
              <option value="15">15 per page</option>
              <option value="20">20 per page</option>
              <option value="50">50 per page</option>
              <option value="100">100 per page</option>
            </b-select>
          </template>
        </b-table>
        <app-add-or-edit-admin-user
          v-if="createAdminUserModal"
          @dismissAdminUserModal="dismissAdminUserModal($event)"
          :user-id="0">
        </app-add-or-edit-admin-user>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/http-playmetrics';
import Spinner from '@/components/common/Spinner';
import AddOrEditAdminUser from './AddOrEditAdminUser';

export default {
  components: {
    'app-spinner': Spinner,
    'app-add-or-edit-admin-user': AddOrEditAdminUser,
  },

  data() {
    return {
      users: null,
      perPage: 20,
      searchQuery: '',
      createAdminUserModal: false,
    };
  },

  computed: {
    loading() {
      return (this.users == null);
    },
    filteredUsers() {
      const lowerCaseQuery = this.searchQuery.toLowerCase();
      return this.users.filter(user => (
        user.email.toLowerCase().includes(lowerCaseQuery) ||
        `${user.id}` === lowerCaseQuery
      ));
    },
  },

  methods: {
    fetchUsers() {
      this.users = null;
      api().get('/admin/admin_users')
        .then((response) => {
          this.users = response.data;
        })
        .catch(() => {
          this.users = [];
        });
    },
    dismissAdminUserModal(options) {
      if (options.refreshData) {
        this.fetchUsers();
      }
      this.createAdminUserModal = false;
    },
    selected(user) {
      this.$router.push(`/users/${user.id}`);
    },
    resend(email) {
      api().post('/admin/admin_users', {
        email,
      })
        .then(() => {
          this.$store.dispatch('setSuccessToast', 'Email Resent');
        });
    },
    removeAdminConfirmation(user, $event) {
      $event.preventDefault();
      $event.stopPropagation();
      this.$buefy.dialog.confirm({
        title: 'Remove Admin',
        message: `Are you sure you want to <b>remove</b><br/> ${user.email}?`,
        confirmText: 'Remove',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.removeAdmin(user),
      });
    },
    removeAdmin(user) {
      api().delete(`/admin/admin_users/${user.id}`).then(() => {
        this.$store.dispatch('setSuccessToast', 'Admin Removed');
        this.fetchUsers();
      });
    },
  },
  created() {
    this.fetchUsers();
  },
};
</script>
