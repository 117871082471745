import { getAuth, signInWithEmailAndPassword, signOut, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import _ from 'lodash';
import Vue from 'vue';
import router from '@/router/index';
import api from '@/http-playmetrics';

// state
const state = {
  user: null,
  loginInfo: null,
  loginRedirect: null,
  justLoggedIn: true,
  justLoggedOut: false,
};

// getters
const getters = {
  user(state) {
    return state.user;
  },
  loginInfo(state) {
    return state.loginInfo;
  },
  loginRedirect(state) {
    return state.loginRedirect;
  },
  justLoggedIn(state) {
    return state.justLoggedIn;
  },
  justLoggedOut(state) {
    return state.justLoggedOut;
  },
};

// mutations
const mutations = {
  setUser(state, payload) {
    state.user = payload;
  },
  setLoginInfo(state, payload) {
    state.loginInfo = payload;
  },
  logUserOut(state) {
    state.user = null;
    state.loginInfo = null;
  },
  setLoginRedirect(state, payload) {
    state.loginRedirect = payload;
  },
  setJustLoggedIn(state, payload) {
    state.justLoggedIn = payload;
  },
  setJustLoggedOut(state, payload) {
    state.justLoggedOut = payload;
  },
};

// actions
const actions = {
  login(context, payload) {
    context.commit('setJustLoggedIn', true);
    // Sign out in case we were signed in with unverified user.
    const auth = getAuth(Vue.prototype.$firebaseApp);
    signOut(auth).then(() => {
      signInWithEmailAndPassword(auth, payload.email, payload.password)
        .catch((error) => {
          context.commit('setNotification', {
            message: error.message,
            success: false,
            toast: false,
          });
        });
    });
  },
  logOut(context) {
    context.commit('setJustLoggedOut', true);
    const auth = getAuth(Vue.prototype.$firebaseApp);
    return signOut(auth).catch((error) => {
      context.commit('setNotification', {
        message: error.message,
        success: false,
      });
    });
  },
  async updateFirebaseUser(context, payload) {
    console.log('updateFirebaseUser', payload);
    if (payload.user) {
      if (!payload.user.emailVerified) {
        context.commit('setNotification', {
          message: 'User not verified.',
          success: false,
          toast: false,
        });
      } else {
        // User is signed in and verified.
        await context.dispatch('performLoginWithToken', payload.user);
      }
    } else {
      // Log out
      context.commit('logUserOut');
      if (context.getters.justLoggedOut) {
        router.push('/login');
        context.commit('setJustLoggedOut', false);
      }
    }
  },
  performLoginWithToken(context) {
    const auth = getAuth(Vue.prototype.$firebaseApp);
    return new Promise((resolve) => {
      api({ showError: true, toast: false }).post('/admin/firebase/user/login', {}).then((response) => {
        const newUser = response.data;
        if (_.includes(newUser.admin_permissions, 'admin_tool') || // TODO figure out the right way for this
            _.includes(newUser.admin_permissions, 'admin_tool.access')) {
          context.dispatch('rebuildState', { response, overrideEmailVerified: false });
        } else {
          context.commit('setNotification', {
            message: 'Admin access is required',
            success: false,
            toast: false,
          });
          signOut(auth);
        }
        resolve();
      }).catch(() => {
        // Unable to authenticate so logout
        signOut(auth);
        resolve();
      });
    });
  },
  rebuildState(context, payload) {
    const user = payload.response.data;
    context.commit('setUser', user);
    if (context.getters.loginRedirect) {
      router.push(context.getters.loginRedirect);
      context.commit('setLoginRedirect', null);
    } else if (context.getters.justLoggedIn) {
      router.push('/');
    }
    context.commit('setJustLoggedIn', false);
  },
  loginGoogle(context) {
    context.dispatch('clearNotification');
    context.commit('setJustLoggedIn', true);
    const provider = new GoogleAuthProvider();
    const auth = getAuth(Vue.prototype.$firebaseApp);
    return signInWithPopup(auth, provider).catch((error) => {
      console.log('signInWithPopup - error', error);
      context.commit('setNotification', {
        message: error.message,
        success: false,
        toast: false,
      });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
