<template>
  <div class="modal is-active">
    <div @click="dismissModal" class="modal-background"></div>
    <div class="modal-card" style="width:720px;">
      <header class="modal-card-head">
        <div class="modal-card-title">Digital Document Payment</div>
        <button @click="dismissModal" class="delete" aria-label="close"></button>
      </header>
      <section class="modal-card-body">
        <app-spinner v-if="loading"></app-spinner>
        <div v-else>
          <div class="columns">
            <div class="column is-9">
              <b-field label="Description">
                <b-input v-model="description" />
              </b-field>
            </div>
            <div class="column is-3">
              <b-field label="Amount (cents)">
                <b-input v-model="cents" type="number" min="0" max="5000000"/>
              </b-field>
            </div>
          </div>
          <hr>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button @click="dismissModal" class="button">Cancel</button>
        <button @click="saveModal" class="button is-primary">Save</button>
      </footer>
    </div>
  </div>
</template>

<script>
import api from '@/http-playmetrics';
import Spinner from '@/components/common/Spinner';

export default {
  components: {
    'app-spinner': Spinner,
  },

  props: {
    clubPandadoc: { type: Object, required: true },
    digitalDocumentPayment: { type: Object, required: false, default: null },
  },

  data() {
    return {
      loading: true,
      description: '',
      cents: 10000,
    };
  },

  computed: {
    invalid() {
      return true;
    },
  },

  created() {
    this.loading = true;
    if (this.digitalDocumentPayment) {
      this.description = this.digitalDocumentPayment.description;
      this.cents = this.digitalDocumentPayment.cents;
    }
    this.loading = false;
  },

  methods: {
    saveModal() {
      const postBody = {
        description: this.description,
        cents: Number(this.cents),
      };
      if (this.digitalDocumentPayment) {
        api().post(`admin/pandadoc_accounts/${this.clubPandadoc.id}/payments/${this.digitalDocumentPayment.id}`, postBody)
          .then(() => {
            this.dismissModal({ refreshData: true });
          });
      } else {
        // postBody.club_id = this.clubPandadoc.club_id;
        postBody.vendor_id = this.vendorId;
        api().post(`admin/pandadoc_accounts/${this.clubPandadoc.id}/payments`, postBody)
          .then(() => {
            this.dismissModal({ refreshData: true });
          });
      }
    },
    dismissModal(options = { refreshData: false }) {
      this.$emit('dismissDigitalDocumentPaymentModal', options);
    },
  },
};
</script>
