import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/components/Home';
import Login from '@/components/auth/Login';
import EmptyState from '@/components/EmptyState';
import Users from '@/components/users/Users';
import User from '@/components/users/User';
import Players from '@/components/players/Players';
import Player from '@/components/players/Player';
import Club from '@/components/clubs/Club';
import Clubs from '@/components/clubs/Clubs';
import ClubExports from '@/components/advanced/ClubExports';
import ClubUsageSummary from '@/components/clubs/ClubUsageSummary';
import SeasonStatus from '@/components/clubs/SeasonStatus';
import EditClub from '@/components/clubs/EditClub';
import EmailMarketing from '@/components/advanced/EmailMarketing';
import SignupClub from '@/components/clubs/SignupClub';
import Emails from '@/components/emails/Emails';
import Email from '@/components/emails/Email';
import AdminUsers from '@/components/admin-users/AdminUsers';
import FirebaseUsers from '@/components/firebase/FirebaseUsers';
import WepayNotificationPrefs from '@/components/advanced/WepayNotificationPrefs';
import ClubPandadoc from '@/components/club_pandadocs/ClubPandadoc';
import ClubPandadocs from '@/components/club_pandadocs/ClubPandadocs';
import Memcache from '@/components/advanced/Memcache';
import Pubsub from '@/components/advanced/Pubsub';
import PushNotifications from '@/components/push_notifications/PushNotifications';
import SystemNotification from '@/components/advanced/SystemNotification';
import TestPage from '@/components/advanced/TestPage';

import { AuthGuard, LoggedInGuard, HasFullAccessGuard } from './guards';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: Home,
      beforeEnter: AuthGuard,
      beforeRouteUpdate: AuthGuard,
      children: [
        { path: '', name: 'emptyState', component: EmptyState },
        { path: 'users', name: 'users', component: Users },
        { path: 'users/:user', name: 'user', component: User },
        { path: 'players', name: 'players', component: Players },
        { path: 'clubs', name: 'clubs', component: Clubs, beforeEnter: HasFullAccessGuard },
        { path: 'clubs/create', name: 'addClub', component: SignupClub, beforeEnter: HasFullAccessGuard },
        { path: 'clubs/:club/edit', name: 'editClub', component: EditClub, beforeEnter: HasFullAccessGuard },
        { path: 'clubs/:club', name: 'club', component: Club, beforeEnter: HasFullAccessGuard },
        { path: 'clubs/:club/players/:player', name: 'player', component: Player },
        { path: 'club_exports', name: 'ClubExports', component: ClubExports, beforeEnter: HasFullAccessGuard },
        { path: 'club_usage_summary', name: 'clubUsageSummary', component: ClubUsageSummary, beforeEnter: HasFullAccessGuard },
        { path: 'email_marketing', name: 'EmailMarketing', component: EmailMarketing, beforeEnter: HasFullAccessGuard },
        { path: 'emails', name: 'emails', component: Emails, beforeEnter: HasFullAccessGuard },
        { path: 'emails/:email', name: 'email', component: Email, beforeEnter: HasFullAccessGuard },
        { path: 'admin-users', name: 'adminUsers', component: AdminUsers, beforeEnter: HasFullAccessGuard },
        { path: 'firebase-users', name: 'firebaseUsers', component: FirebaseUsers, beforeEnter: HasFullAccessGuard },
        { path: 'wepay_notification_prefs', name: 'wepayNotificationPrefs', component: WepayNotificationPrefs, beforeEnter: HasFullAccessGuard },
        { path: 'pandadoc_accounts', name: 'clubPandadocs', component: ClubPandadocs, beforeEnter: HasFullAccessGuard },
        { path: 'pandadoc_accounts/:clubPandadoc', name: 'clubPandadoc', component: ClubPandadoc, beforeEnter: HasFullAccessGuard },
        { path: 'memcache', name: 'Memcache', component: Memcache, beforeEnter: HasFullAccessGuard },
        { path: 'pubsub', name: 'Pubsub', component: Pubsub, beforeEnter: HasFullAccessGuard },
        { path: 'push_notifications', name: 'pushNotifications', component: PushNotifications, beforeEnter: HasFullAccessGuard },
        { path: 'season_status', name: 'seasonStatus', component: SeasonStatus, beforeEnter: HasFullAccessGuard },
        { path: 'system_notification', name: 'SystemNotification', component: SystemNotification, beforeEnter: HasFullAccessGuard },
        { path: 'test_page', name: 'TestPage', component: TestPage, beforeEnter: HasFullAccessGuard },
      ],
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      beforeEnter: LoggedInGuard,
      beforeRouteUpdate: LoggedInGuard,
    },
  ],
});
