<template>
  <div class="modal is-active">
    <div @click="dismissModal()" class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <div class="modal-card-title">Add Family Member</div>
        <button @click="dismissModal()" class="delete" aria-label="close"></button>
      </header>
      <section class="modal-card-body">
        <b-field label="Email">
          <b-input v-model="email" type="email"></b-input>
        </b-field>
        <b-field label="First Name">
          <b-input v-model="firstName"></b-input>
        </b-field>
        <b-field label="Last Name">
          <b-input v-model="lastName"></b-input>
        </b-field>
        <b-field label="Mobile Number">
          <b-input v-model="mobileNumber"></b-input>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <button @click="addFamilyMember"
          class="button is-primary"
          :class="{'is-loading': loading}"
          :disabled="loading || invalid">
          Add Family Member
        </button>
        <button @click="dismissModal()" class="button">Cancel</button>
      </footer>
    </div>
  </div>
</template>

<script>
import api from '@/http-playmetrics';

export default {
  props: {
    player: { type: Object, required: true },
  },

  data() {
    return {
      loading: false,
      email: '',
      firstName: '',
      lastName: '',
      mobileNumber: '',
    };
  },

  computed: {
    invalid() {
      const re = /\S+@\S+\.\S+/;
      return !re.test(this.email);
    },
  },

  methods: {
    addFamilyMember() {
      this.loading = true;
      const parent = {
        email: this.email,
        first_name: this.firstName,
        last_name: this.lastName,
        mobile_number: this.mobileNumber,
        players: [{ id: this.player.id }],
      };
      api().post('/admin/parents', parent)
        .then(() => {
          this.$store.dispatch('setSuccessToast', 'Family Member Added');
          this.loading = false;
          this.dismissModal({ refreshData: true });
        })
        .catch((error) => {
          this.$store.dispatch('createNotification', { message: error.message, success: false, toast: true });
          this.loading = false;
        });
    },
    dismissModal(options = { refreshData: false }) {
      this.$emit('dismissModal', options);
    },
  },
};
</script>
