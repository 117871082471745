<template>
  <div class="modal is-active">
    <div @click="dismissModal" class="modal-background"></div>
    <div class="modal-card" style="width:720px; min-height:460px;">
      <header class="modal-card-head">
        <div class="modal-card-title" />
        <button @click="dismissModal" class="delete" aria-label="close"></button>
      </header>
      <section class="modal-card-body">
        <app-spinner v-if="loading"></app-spinner>
        <div v-else>
          <iframe :src="iframeSrc" width="100%" style="height:500px;" />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import api from '@/http-playmetrics';
import Spinner from '@/components/common/Spinner';

export default {
  components: {
    'app-spinner': Spinner,
  },

  props: {
    clubPandadoc: { type: Object, required: true },
    digitalDocumentTemplate: { type: Object, required: false, default: null },
  },

  data() {
    return {
      loading: true,
      iframeSrc: '',
    };
  },

  computed: {
    invalid() {
      return true;
    },
  },

  created() {
    this.loading = true;
    api().post(`/admin/pandadoc_accounts/${this.clubPandadoc.id}/templates/${this.digitalDocumentTemplate.id}/sample`, {}).then((resp) => {
      this.iframeSrc = `https://app.pandadoc.com/s/${resp.data.pandadoc_session.id}`;
      this.loading = false;
    });
  },

  methods: {
    dismissModal(options = { refreshData: false }) {
      this.$emit('dismissDigitalDocumentTemplateModal', options);
    },
  },
};
</script>
