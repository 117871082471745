import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import auth from './modules/auth';
import notification from './modules/notification';
import teamsFilters from './modules/teamsFilters';
import clubsFilters from './modules/clubsFilters';
import playersFilters from './modules/playersFilters';
// import eventsFilters from './modules/eventsFilters';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    auth,
    notification,
    teamsFilters,
    clubsFilters,
    playersFilters,
    // eventsFilters,
  },
  plugins: [createPersistedState()],
  strict: process.env.NODE_ENV !== 'production',
});

export { store as default };
