// state
const state = {
  playersFilters: {
    searchQuery: '',
    genders: [],
    selectedClub: null,
    selectedLevel: null,
  },
};

// getters
const getters = {
  playersFilters(state) {
    return state.playersFilters;
  },
};

// mutations
const mutations = {
  setPlayersFiltersSearchQuery(state, payload) {
    state.playersFilters.searchQuery = payload;
  },
  setPlayersFiltersGenders(state, payload) {
    state.playersFilters.genders = payload;
  },
  setPlayersFiltersSelectedClub(state, payload) {
    state.playersFilters.selectedClub = payload;
  },
  setPlayersFiltersSelectedLevel(state, payload) {
    state.playersFilters.selectedLevel = payload;
  },
};

// actions
const actions = {
  setPlayersFiltersSearchQuery(context, payload) {
    context.commit('setPlayersFiltersSearchQuery', payload);
  },
  setPlayersFiltersGenders(context, payload) {
    context.commit('setPlayersFiltersGenders', payload);
  },
  setPlayersFiltersSelectedClub(context, payload) {
    context.commit('setPlayersFiltersSelectedClub', payload);
  },
  setPlayersFiltersSelectedLevel(context, payload) {
    context.commit('setPlayersFiltersSelectedLevel', payload);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
