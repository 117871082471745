<template>
  <div>
    <div class="box">
      <div class="is-size-4">Pubsub</div>
      <app-spinner v-if="loading"></app-spinner>
      <div v-else>
        <div class="columns">
          <div class="column is-narrow">
            <button
              @click="queueCloudTask()"
              class="button is-primary">
              <span class="icon">
                <i class="fas fa-plus"></i>
              </span>
              <span>Enqueue Cloud Task</span>
            </button>
          </div>
        </div>
        <b-table
          :data="subs"
          narrowed>
          <template slot-scope="props">
            <b-table-column field="type" label="Type" sortable>
              {{ props.row.status }}&nbsp;
            </b-table-column>
            <b-table-column field="subName" label="Subscription Name" sortable>
              {{ props.row.name }}
            </b-table-column>
            <b-table-column field="callback_uri" label="Endpoint" sortable>
              {{ props.row.callback_uri }}
            </b-table-column>
            <b-table-column>
              <span v-if="props.row.status === 'Push'">
                <a
                  class="has-text-primary clickable"
                  @click="pausePubSubConfirm(props.row, $event)">
                  Pause
               </a>
              </span>
              <span v-if="props.row.status === 'Paused'">
                <a
                  class="has-text-primary clickable"
                  @click="resumePubSubConfirm(props.row, $event)">
                  Resume
               </a>
              </span>
            </b-table-column>
          </template>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>No results</p>
              </div>
            </section>
          </template>
        </b-table>
        <span>
          Note: To change an endpoint, set the memcache key &lt;sub_name>Endpoint
          to the url of the endpoint and include a ?token=&lt;secret> param
        </span>
      </div>
    </div>
    <app-cloud-task-request
      v-if="showCloudRequest"
      @dismissCloudRequestModal="dismissCloudRequestModal($event)">
    </app-cloud-task-request>
  </div>
</template>

<script>
import _ from 'lodash';
import api from '@/http-playmetrics';
import Spinner from '@/components/common/Spinner';
import CloudTaskRequest from './CloudTaskRequest';

export default {
  components: {
    'app-spinner': Spinner,
    'app-cloud-task-request': CloudTaskRequest,
  },

  data() {
    return {
      loading: true,
      showCloudRequest: false,
      subs: null,
      pushSubs: [],
    };
  },

  computed: {
  },

  methods: {
    queueCloudTask() {
      this.showCloudRequest = true;
    },
    dismissCloudRequestModal() {
      this.showCloudRequest = false;
    },
    loadsubs() {
      api().get('admin/pubsub/list')
        .then((response) => {
          this.subs = Object.values(response.data);
          this.pushSubs = _.filter(this.subs, p => p.status !== 'Pull');
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    pausePubSubConfirm(sub, $event) {
      $event.preventDefault();
      $event.stopPropagation();
      this.$buefy.dialog.confirm({
        title: 'Pause Pubsub Subscription Processing',
        message: `Are you sure you want to <b>pause</b><br/> ${sub.name}?`,
        confirmText: 'Pause',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.pausePubSub(sub),
      });
    },
    pausePubSub(sub) {
      api().post(`/admin/pubsub/pause/${sub.name}`).then(() => {
        this.$store.dispatch('setSuccessToast', 'Subscription Paused');
        this.loadsubs();
      });
    },
    resumePubSubConfirm(sub, $event) {
      $event.preventDefault();
      $event.stopPropagation();
      this.$buefy.dialog.confirm({
        title: 'Resume Pubsub Subscription Processing',
        message: `Are you sure you want to <b>resume</b><br/> ${sub.name}?`,
        confirmText: 'Resume',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.resumePubSub(sub),
      });
    },
    resumePubSub(sub) {
      api().post(`/admin/pubsub/resume/${sub.name}`).then(() => {
        this.$store.dispatch('setSuccessToast', 'Subscription Resumed');
        this.loadsubs();
      });
    },
  },

  created() {
    this.loadsubs();
  },
};
</script>
