<template>
  <div class="modal is-active">
    <div @click="dismissModal" class="modal-background"></div>
    <div class="modal-card" style="width:720px;">
      <header class="modal-card-head">
        <div class="modal-card-title">Cloud Task</div>
        <button @click="dismissModal" class="delete" aria-label="close"></button>
      </header>
      <section class="modal-card-body">
        <app-spinner v-if="loading"></app-spinner>
        <div v-else>
          <div class="columns">
            <div class="column">
              <b-field label="Queue">
                <div class="columns">
                  <div class="column">
                    <div class="columns">
                      <div class="column">
                        <b-radio v-model="queue" name="name" native-value="high-priority-30s">high-priority-30s</b-radio><br />
                        <b-radio v-model="queue" name="name" native-value="low-priority-30s">low-priority-30s</b-radio><br />
                        <b-radio v-model="queue" name="name" native-value="high-priority-10m">high-priority-10m</b-radio><br />
                        <b-radio v-model="queue" name="name" native-value="low-priority-10m">low-priority-10m</b-radio><br />
                      </div>
                    </div>
                  </div>
                </div>
              </b-field>
            </div>
            <div class="column">
              <div class="columns">
                <div class="column is-narrow">
                  <b-field label="Max Retries">
                    <b-numberinput
                      v-model="maxRetries"
                      :min="0"
                      :max="10" />
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <b-field>
                    <b-checkbox v-model="forgoDeadQueue">Forgo Dead Queue</b-checkbox>
                  </b-field>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="columns">
                <div class="column is-narrow">
                  <b-field label="Delay (seconds)">
                    <b-numberinput
                      v-model="delaySeconds"
                      :min="0"
                      :max="900" />
                  </b-field>
                </div>
              </div>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <b-field label="Task Name">
                <b-select v-model="task">
                  <option v-for="taskName in taskNames" :key="taskName" :value="taskName">
                    {{ taskName }}
                  </option>
                </b-select>
              </b-field>
            </div>
          </div>
          <b-field
            label="Datas (should be an array of maps, must be valid JSON)"
            :type="!jsonDatas ? 'is-danger' : ''"
            :message="!jsonDatas ? 'Invalid JSON' : ''"
          >
            <div class="columns">
              <div class="column">
                <b-input
                  v-model="datas"
                  type="textarea"
                  rows="4"
                  @blur="validateJSON()" />
              </div>
            </div>
          </b-field>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button @click="dismissModal" class="button">Cancel</button>
        <button :title="invalidMessage" :disabled="disableSubmit" @click="submitModal" class="button is-primary">Submit</button>
      </footer>
    </div>
  </div>
</template>

<script>
import api from '@/http-playmetrics';
import Spinner from '@/components/common/Spinner';

export default {
  components: {
    'app-spinner': Spinner,
  },

  props: {
  },

  data() {
    return {
      loading: true,
      queue: 'low-priority-30s',
      taskNames: [],
      task: '',
      datas: '[\n{}\n]',
      delaySeconds: 0,
      maxRetries: 3,
      forgoDeadQueue: false,
    };
  },

  watch: {
    currentConfig() {
      this.config = this.currentConfig;
    },
  },

  created() {
    api().get('/admin/cloud_tasks/list').then((resp) => {
      this.taskNames = resp.data;
      this.loading = false;
    });
  },

  computed: {
    invalidMessage() {
      if (this.task === '') { return 'Task required'; }
      if (this.delaySeconds < 0) { return 'Invalid Delay'; }
      if (this.maxRetries < 0 || this.maxRetries > 10) { return 'Invalid Max Retries'; }
      if (this.jsonDatas.length === 0 || !Array.isArray(this.jsonDatas)) { return 'Invalid JSON'; }
      // TODO require delaySeconds for multiple jsonDatas payloads
      return '';
    },
    disableSubmit() {
      return this.invalidMessage !== '';
    },
    jsonDatas() {
      const cleanDatas = this.datas.replace(/(?:\r\n|\r|\n)/g, '');
      try {
        return JSON.parse(cleanDatas);
      } catch (e) {
        console.error('Failed to parse datas:', e);
        return undefined;
      }
    },
  },

  methods: {
    validateJSON() {
      const cleanDatas = this.datas.replace(/(?:\r\n|\r|\n)/g, '');
      let jsonDatas;
      try {
        jsonDatas = JSON.parse(cleanDatas);
      } catch (e) {
        this.jsonError = e.message;
        return '';
      }
      this.jsonError = '';
      return jsonDatas;
    },
    submitModal() {
      if (this.disableSubmit) {
        this.$store.dispatch('createNotification', { message: 'Invalid cloud task.', success: false, toast: true });
        return;
      }
      const postBody = {
        queue: this.queue,
        task: this.task,
        datas: this.jsonDatas,
        delay_seconds: this.delaySeconds,
        max_retries: this.maxRetries,
        forgo_dead_queue: this.forgoDeadQueue,
      };
      api().post('admin/cloud_tasks', postBody)
        .then((response) => {
          if (response.data.errors) {
            this.$store.dispatch('createNotification', { message: response.data.errors, success: false, toast: true });
          } else {
            this.dismissModal();
          }
        })
        .catch((err) => {
          this.$store.dispatch('createNotification', { message: err.message, success: false, toast: true });
        });
    },
    dismissModal() {
      this.$emit('dismissCloudRequestModal');
    },
  },
};
</script>
