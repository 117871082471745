<template>
  <div>
    <div class="box">
      <div class="field">
        <div class="control">
          <input v-model="searchQuery" class="input" type="text" placeholder="Search...">
        </div>
      </div>
    </div>
    <div class="box">
      <app-spinner v-if="loading"></app-spinner>
      <div v-else>
        <div class="columns">
          <div class="column">
            <b-select
              v-model="clubToEnable"
              placeholder="Select a Club to enable..."
              expanded>
              <option
                v-for="club in enableableClubs"
                :value="club"
                :key="club.id">
                {{ club.name }} ({{ club.id }})
              </option>
            </b-select>
          </div>
          <div class="column is-narrow">
            <button
              @click="enableClub()"
              class="button is-primary">
              <span class="icon">
                <i class="fas fa-plus"></i>
              </span>
              <span>Enable Export For Club</span>
            </button>
          </div>
        </div>
        <b-table
          :data="filteredClubExports"
          :per-page="perPage"
          clickable
          narrowed
          hoverable
          mobile-cards
          paginated
          pagination-simple
          @select="selected">
          <template slot-scope="props">
            <b-table-column field="club_id" label="Club ID" sortable>
              {{ props.row.club_id }}
            </b-table-column>
            <b-table-column field="club_name" label="Club Name" sortable>
              {{ props.row.club_name }}
            </b-table-column>
          </template>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>No results</p>
              </div>
            </section>
          </template>
          <template slot="bottom-left">
            <b-select v-model="perPage">
              <option value="10">10 per page</option>
              <option value="15">15 per page</option>
              <option value="20">20 per page</option>
              <option value="50">50 per page</option>
              <option value="100">100 per page</option>
            </b-select>
          </template>
        </b-table>
      </div>
    </div>
    <app-edit-club-export
      v-if="selectedClubId > 0"
      :club-id="selectedClubId"
      :current-config="selectedClubConfig"
      @dismissClubModal="dismissClubModal($event)">
    </app-edit-club-export>
  </div>
</template>

<script>
import _ from 'lodash';
import api from '@/http-playmetrics';
import Spinner from '@/components/common/Spinner';
import EditClubExport from './EditClubExport';

export default {
  components: {
    'app-spinner': Spinner,
    'app-edit-club-export': EditClubExport,
  },

  data() {
    return {
      loading: true,
      clubs: null,
      clubExports: null,
      enableableClubs: null,
      searchQuery: '',
      perPage: 20,
      clubToEnable: null,
      selectedClubId: 0,
      selectedClubConfig: '',
    };
  },

  computed: {
    filteredClubExports() {
      return this.clubExports.filter(ce =>
        ce.club_name.toLowerCase().includes(this.searchQuery.toLowerCase()));
    },
  },
  methods: {
    selected(clubExport) {
      this.selectedClubConfig = clubExport.config;
      this.selectedClubId = clubExport.club_id;
    },
    fetchClubs() {
      this.loading = true;
      api().get('admin/clubs').then((resp1) => {
        this.clubs = _.map(resp1.data, (club) => {
          let name = club.name;
          if (club.is_test_club) {
            name += ' (test club)';
          }
          club.name = name;
          return club;
        });
        api().get('admin/club_exports').then((resp2) => {
          this.clubExports = _.map(resp2.data, (clubExport) => {
            const clubExportWithName = _.clone(clubExport);
            const club = _.find(this.clubs, c => c.id === clubExport.club_id);
            if (club) {
              clubExportWithName.club_name = club.name;
            }
            return clubExportWithName;
          });
          this.enableableClubs = _.sortBy(_.filter(_.filter(this.clubs, club => !club.is_deleted),
            c => !this.clubHasClubExport(c.id)), c => c.name);
          this.loading = false;
        });
      });
    },
    enableClub() {
      this.selectedClubConfig = ''; // TODO default
      this.selectedClubId = this.clubToEnable.id;
    },
    clubHasClubExport(clubId) {
      return _.some(this.clubExports, clubExport => (clubExport.club_id === clubId));
    },
    dismissClubModal(options) {
      this.selectedClubId = 0;
      if (options.refreshData) {
        this.fetchClubs();
      }
    },
  },

  created() {
    this.fetchClubs();
  },
};
</script>
