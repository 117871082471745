<template>
  <div>
    <div class="field">
      <div class="control">
        <input v-model="searchQuery" class="input" type="text" placeholder="Search...">
      </div>
    </div>
    <b-table
      :data="filteredAdmins"
      :per-page="perPage"
      narrowed
      hoverable
      mobile-cards
      paginated
      pagination-simple
      @select="selected"
      :row-class="(function() {return 'clickable';})">
      <template slot-scope="props">
        <b-table-column field="id" label="User ID" sortable>
          {{ props.row.id }}
        </b-table-column>
        <b-table-column field="last_name" label="Name" sortable>
          {{ props.row.first_name }} {{ props.row.last_name }}
        </b-table-column>
        <b-table-column field="email" label="Email" sortable>
          {{ props.row.email }}
        </b-table-column>
        <b-table-column field="registered_at" label="Registered At" sortable>
          <span v-if="props.row.registered_at">
            {{ props.row.registered_at | formatDate('M/D/YY h:mmA') }}
          </span>
          <span v-else>
            <button
              @click.capture.stop="resend(props.row)"
              class="button is-info is-small">
              <span>Resend</span>
            </button>
          </span>
        </b-table-column>
        <b-table-column label="Remove" centered>
          <a class="has-text-primary clickable" @click.stop="removeAdminConfirmation(props.row)">
            <i class="fas fa-trash"></i>
          </a>
        </b-table-column>
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>No results</p>
          </div>
        </section>
      </template>
      <template slot="bottom-left">
        <b-select v-model="perPage">
          <option value="10">10 per page</option>
          <option value="15">15 per page</option>
          <option value="20">20 per page</option>
          <option value="50">50 per page</option>
          <option value="100">100 per page</option>
        </b-select>
      </template>
    </b-table>
    <app-create-admin
      v-if="createAdminModal"
      :clubName="club.name"
      @dismissAdminModal="dismissAdminModal($event)">
    </app-create-admin>
  </div>
</template>

<script>
import api from '@/http-playmetrics';
import ClubCreateAdmin from './ClubCreateAdmin';

export default {
  components: {
    'app-create-admin': ClubCreateAdmin,
  },

  props: {
    club: { type: Object, required: true },
  },

  data() {
    return {
      perPage: 20,
      searchQuery: '',
      admins: [],
      createAdminModal: false,
    };
  },

  computed: {
    filteredAdmins() {
      return this.admins.filter(admin =>
        admin.email.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
        admin.first_name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
        admin.last_name.toLowerCase().includes(this.searchQuery.toLowerCase()),
      );
    },
  },

  created() {
    this.loadAdmins();
  },

  methods: {
    loadAdmins() {
      api().get(`/admin/clubs/${this.club.id}/admin_users`).then((response) => {
        this.admins = response.data;
      });
    },
    selected(user) {
      this.$router.push(`/users/${user.id}`);
    },
    removeAdminConfirmation(user) {
      this.$buefy.dialog.confirm({
        title: 'Remove Admin',
        message: `Are you sure you want to <b>remove</b><br/> ${user.email}?`,
        confirmText: 'Remove',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.removeAdmin(user),
      });
    },
    removeAdmin(user) {
      api().delete(`/admin/clubs/${this.club.id}/admin_users/${user.id}`)
        .then(() => {
          this.$store.dispatch('setSuccessToast', 'Admin Removed');
          this.loadAdmins();
        });
    },
    resend(user) {
      api().post(`/admin/clubs/${this.club.id}/admin_users/${user.id}/resend`, {})
        .then(() => {
          this.$store.dispatch('setSuccessToast', 'Email Resent');
        });
    },
    addAdmin() {
      this.createAdminModal = true;
    },
    dismissAdminModal(options) {
      if (options.refreshData) {
        this.loadAdmins();
      }
      this.createAdminModal = false;
    },
  },
};
</script>
