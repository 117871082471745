// state
const state = {
  clubsFilters: {
    searchQuery: '',
  },
};

// getters
const getters = {
  clubsFilters(state) {
    return state.clubsFilters;
  },
};

// mutations
const mutations = {
  setClubsFiltersSearchQuery(state, payload) {
    state.clubsFilters.searchQuery = payload;
  },
};

// actions
const actions = {
  setClubsFiltersSearchQuery(context, payload) {
    context.commit('setClubsFiltersSearchQuery', payload);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
