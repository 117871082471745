// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { initializeApp } from 'firebase/app';

import Vue from 'vue';
import Buefy from 'buefy';
import LoadScript from 'vue-plugin-load-script';
import VueClipboards from 'vue-clipboards';
import { library as faLibrary } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';
import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import { faTrash, faPlus, faAngleRight, faAngleLeft, faDoNotEnter } from '@fortawesome/pro-solid-svg-icons';
import App from './App';
import router from './router';
import store from './store/store';
import { cmToFt, kgToLbs, formatDate, currency } from './filters';
import CONFIG from './env-config';
import '@fortawesome/fontawesome-pro/css/fontawesome.css';
import '@fortawesome/fontawesome-pro/css/regular.css';
import '@fortawesome/fontawesome-pro/css/solid.css';


faLibrary.add(faEdit, faTrash, faPlus, faAngleRight, faAngleLeft, faDoNotEnter);
Vue.component('fa-icon', FontAwesomeIcon);
Vue.component('fa-layers', FontAwesomeLayers);

Vue.config.productionTip = false;

Vue.use(Buefy, { defaultIconPack: 'fas' });
Vue.use(LoadScript);
Vue.use(VueClipboards);
Vue.filter('formatDate', formatDate);
Vue.filter('cmToFt', cmToFt);
Vue.filter('kgToLbs', kgToLbs);
Vue.filter('currency', currency);

let app;
Vue.prototype.$firebaseApp = initializeApp(CONFIG.firebaseConfig);
const auth = getAuth(Vue.prototype.$firebaseApp);
onAuthStateChanged(auth, (user) => {
  store.dispatch('updateFirebaseUser', { user }).then(() => {
    // Only initialize the app once after we have logged in
    if (!app) {
      app = new Vue({
        el: '#app',
        router,
        store,
        render: h => h(App),
      });
    }
  });
});
// Kicker for build 2024-04-18
