<template>
  <div class="box">
    <div class="has-text-centered welcome content">
      <i class="fas fa-database fa-6x has-text-grey-lighter"></i>
      <h1 class="title has-text-weight-light">Welcome to<br/>PlayMetrics Admin</h1>
      <p class="has-text-grey-light">To get started, click on a link in side navigation bar.</p>
    </div>
  </div>
</template>

<script>
// import api from '@/http-playmetrics';

export default {
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    user() {
      return this.$store.getters.user;
    },
  },
};
</script>

<style scoped>
  .welcome {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
</style>
