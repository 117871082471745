<template>
 <div>
   <div v-if="loading" class="box">
     <app-spinner ></app-spinner>
   </div>
   <template v-else>
     <div class="box">
       <nav class="level">
         <div class="level-left">
           <div class="level-item">
             <nav class="breadcrumb">
               <ul>
                 <li>
                   <router-link to="/" tag="a">
                     Home
                   </router-link>
                 </li>
                 <li>
                   <router-link to="/clubs" tag="a">
                     Clubs
                   </router-link>
                 </li>
                 <li class="is-active">
                   <router-link to="" tag="a">
                     Edit Club
                   </router-link>
                 </li>
               </ul>
             </nav>
           </div>
         </div>
       </nav>
     </div>
     <div class="box">
       <div class="box">
         <h2 class="title is-5">Club Info:</h2>
        <b-field label="Club Name">
          <b-input v-model="clubName"></b-input>
        </b-field>
        <b-field label="Mailing Address">
          <b-input v-model="club.config.mailing_address"></b-input>
        </b-field>
        <div class="columns">
          <div class="column is-narrow">
            <b-field label="Country">{{ this.country }}</b-field>
          </div>
          <div class="column is-narrow">
            <b-field label="State">
              <b-select v-model="state">
                <option
                  v-for="state in states"
                  :key="state.abbr"
                  :value="state.abbr">
                  {{ state.name }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column">
            <b-field label="City">
              <b-input v-model="city" maxlength="50"/>
            </b-field>
          </div>
        </div>
        <b-field label="Timezone">
          <b-select
            placeholder="Select timezone"
            v-model="timezone">
            <option
             v-for="zone in timezones"
             :key="zone"
             :value="zone">
             {{ zone }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Abbreviation">
          <b-input v-model="abbreviation" maxlength="8"></b-input>
        </b-field>
        <b-field label="Email Domain">
          <b-input v-model="emailDomain"></b-input>
        </b-field>
         <b-field label="Zendesk Club Name">
           <b-input v-model="zendeskClubName"></b-input>
         </b-field>
        <b-field label="Website">
          <b-input v-model="website"></b-input>
        </b-field>
        <b-field label="Contact Name">
          <b-input v-model="contactName"></b-input>
        </b-field>
        <b-field label="Contact Email">
          <b-input v-model="contactEmail" type="email"></b-input>
        </b-field>
       </div>
       <div class="box">
         <b-field label="Sport">
           <div class="columns">
             <div class="column">
               <b-radio v-model="sport" disabled name="name" native-value="baseball">Baseball</b-radio>
             </div>
             <div class="column">
               <b-radio v-model="sport" disabled name="name" native-value="basketball">Basketball</b-radio>
             </div>
             <div class="column">
               <b-radio v-model="sport" disabled name="name" native-value="fieldhockey">Field Hockey</b-radio>
             </div>
             <div class="column">
               <b-radio v-model="sport" disabled name="name" native-value="hockey">Hockey</b-radio>
             </div>
             <div class="column">
               <b-radio v-model="sport" disabled name="name" native-value="lacrosse">Lacrosse</b-radio>
             </div>
           </div>
           <div class="columns">
             <div class="column">
               <b-radio v-model="sport" disabled name="name" native-value="rugby">Rugby</b-radio>
             </div>
             <div class="column">
               <b-radio v-model="sport" disabled name="name" native-value="ski">Ski/Snowboard</b-radio>
             </div>
             <div class="column">
               <b-radio v-model="sport" disabled name="name" native-value="soccer">Soccer</b-radio>
             </div>
             <div class="column">
               <b-radio v-model="sport" disabled name="name" native-value="softball">Softball</b-radio>
             </div>
             <div class="column">
               <b-radio v-model="sport" disabled name="name" native-value="volleyball">Volleyball</b-radio>
             </div>
           </div>
         </b-field>
       </div>
       <div class="box">
        <h2 class="title is-5">Permissions:</h2>
        <b-field label="Coaches Practices (can be left blank)">
          <div class="level-left">
            <div class="level-item">
              <div class="block">
                <b-checkbox v-model="club.config.allows_coaches_create_practices">
                  Allow coaches to create practices.
                </b-checkbox>
              </div>
            </div>
          </div>
        </b-field>
        <b-field label="Coaches Games (can be left blank)">
          <div class="level-left">
            <div class="level-item">
              <div class="block">
                <b-checkbox v-model="club.config.allows_coaches_view_all_games">
                  Allow coaches to view all games.
                </b-checkbox>
              </div>
            </div>
          </div>
        </b-field>
        <b-field label="Parent Contact Info (can be left blank)">
          <div class="level-left">
            <div class="level-item">
              <div class="block">
                <b-checkbox v-model="club.config.hide_contact_details_from_parent">
                  Hide parent contact info from other parents.
                </b-checkbox>
              </div>
            </div>
          </div>
        </b-field>
        <b-field label="Director Drill Access">
          <b-select
            placeholder="Select acccess"
            v-model="club.config.director_drill_access">
            <option
              v-for="opt in ['read', 'private', 'public']"
              :key="opt"
              :value="opt">
              {{ opt }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Director Season Plan Access">
          <b-select
            placeholder="Select access"
            v-model="club.config.director_season_plan_access">
            <option
              v-for="opt in ['read', 'create']"
              :key="opt"
              :value="opt">
              {{ opt }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Team Manager">
          <div class="level-left">
            <div class="level-item">
              <div class="block">
                <b-checkbox v-model="club.config.team_manager_can_assign_guests">
                  Allow Team Manager to add Guest Coaches and Players.
                </b-checkbox>
              </div>
            </div>
          </div>
        </b-field>
       </div>
       <div class="box">
         <div class="tag__container">
           <h2 class="title is-5">Import Drills From:</h2>
           <div
             v-for="club in sharedDrillsClub"
             :key="club">
             <div
               class="columns is-mobile">
               <div class="column">
                 {{ clubsByID[club].name}}
               </div>
               <div class="column">
                 <b-checkbox v-model="sharedSessionsClub[club]">
                   also import Sessions
                 </b-checkbox>
               </div>
               <div class="column is-narrow">
                 <button
                   class="button is-small is-danger-on hover is-outlined is-borderless"
                   @click="removeSharedDrillsClubConfirmation(club)">
                   <i class="fas fa-trash"></i>
                 </button>
               </div>
             </div>
           </div>
           <div class="columns">
             <b-select
               class="column is-narrow"
               placeholder="Select a Club"
               v-model="selectedDrillsClubToShare"
               @input="disableAddDrillsClubButton = false">
               <option
                 v-for="clubToShare in clubsForDrillSharing"
                 :key="clubToShare.id"
                 :value="clubToShare.id">
                 {{ clubToShare.name }}
               </option>
             </b-select>
             <div class="column">
               <button @click.stop="assignSharedDrillsClub(selectedDrillsClubToShare)"
                       class="button is-primary"
                       :class="{'is-loading': loading}"
                       :disabled="disableAddDrillsClubButton">
                 Add Club
               </button>
             </div>
           </div>
         </div>
       </div>
       <div class="box">
          <div class="tag__container">
            <h2 class="title is-5">Dictionary (leave blank for default names):</h2>
            <section class="modal-card-body">
              <div class="columns is-mobile">
                <div class="column is-6">
                  <b-field label="clinic">
                    <b-input v-model="clinic" placeholder="Enter name for clinic..." maxlength="18"> </b-input>
                  </b-field>
                </div>
                <div class="column is-6">
                  <b-field label="clinics">
                    <b-input v-model="clinics" placeholder="Enter name for clinics..." maxlength="18"> </b-input>
                  </b-field>
                </div>
              </div>
              <div class="columns is-mobile">
                <div class="column is-6">
                  <b-field label="drill">
                    <b-input v-model="drill" placeholder="Enter name for drill..."> </b-input>
                  </b-field>
                </div>
                <div class="column is-6">
                  <b-field label="drills">
                    <b-input v-model="drills" placeholder="Enter name for drills..."> </b-input>
                  </b-field>
                </div>
              </div>
              <div class="columns is-mobile">
                <div class="column is-6">
                  <b-field label="practice">
                    <b-input v-model="practice" placeholder="Enter  name for practice..."> </b-input>
                  </b-field>
                </div>
                <div class="column is-6">
                  <b-field label="practices">
                    <b-input v-model="practices" placeholder="Enter name for practices..."> </b-input>
                  </b-field>
                </div>
              </div>
              <div class="columns is-mobile">
                <div class="column is-6">
                  <b-field label="region">
                    <b-input v-model="region" placeholder="Enter name for region..."> </b-input>
                  </b-field>
                </div>
                <div class="column is-6">
                  <b-field label="regions">
                    <b-input v-model="regions" placeholder="Enter name for regions..."> </b-input>
                  </b-field>
                </div>
              </div>
            </section>
          </div>
        </div>
       <div class="box">
        <h2 class="title is-5">Other:</h2>
        <b-field label="Game Prefix">
          <b-input v-model="club.config.game_prefix"></b-input>
        </b-field>
        <b-field label="SMS">
          <b-checkbox v-model="club.config.enable_sms">
            Enable SMS
          </b-checkbox>
        </b-field>
        <b-field label="Billing">
          <b-checkbox v-model="club.config.enable_billing">
            Enable Billing
          </b-checkbox>
        </b-field>
        <b-field label="Player Contact Offline Payments">
          <b-checkbox v-model="club.config.player_contact_offline_payments">
            Player Contacts can register for programs via Cash or Check
          </b-checkbox>
        </b-field>
        <b-field label="Non-Resident Payments">
          <b-checkbox v-model="club.config.accept_payments_from_non_residents">
            Prompt non-resident users for their country during checkout.
          </b-checkbox>
        </b-field>
        <b-field label="Drill Tags">
          <b-checkbox v-model="club.config.enable_drill_tags">
            Enable Drill Tags
          </b-checkbox>
        </b-field>
        <b-field label="Testing">
          <b-checkbox v-model="club.config.enable_testing">
            Enable Testing
          </b-checkbox>
        </b-field>
        <b-field label="League Matchup Import/Export">
          <b-checkbox v-model="club.config.enable_league_matchup_export">
            Enable League Matchup Import/Export
          </b-checkbox>
        </b-field>
         <b-field label="Admin Package Controls">
           <b-checkbox v-model="club.config.enable_admin_edit_subscription_packages">
             Admins can change packages on subscriptions
           </b-checkbox>
         </b-field>
         <b-field label="Staff Calendar">
           <b-checkbox v-model="club.config.enable_staff_calendar">
             Enable Staff Calendar
           </b-checkbox>
         </b-field>
        <b-field label="Custom Player Fields">
          <b-input
            v-model="customPlayerFields"
            type="textarea"
            rows="4"/>
        </b-field>
        <b-field label="Custom Team Fields">
          <b-input
            v-model="customTeamFields"
            type="textarea"
            rows="4"/>
        </b-field>
         <b-field label="App Review">
           <b-checkbox v-model="showAppReview">
             Enable App Review
           </b-checkbox>
         </b-field>
         <b-field label="App Review Roles">
           <div>
             <b-checkbox
               v-model="appReviewRoles"
               native-value="Admin"
             >
               Admin
             </b-checkbox>
             <b-checkbox
               v-model="appReviewRoles"
               native-value="Director"
             >
               Director
             </b-checkbox>
             <b-checkbox
               v-model="appReviewRoles"
               native-value="Coach"
             >
               Coach
             </b-checkbox>
             <b-checkbox
               v-model="appReviewRoles"
               native-value="Manager"
             >
               Team Manager
             </b-checkbox>
             <b-checkbox
               v-model="appReviewRoles"
               native-value="Parent"
             >
               Player Contact
             </b-checkbox>
           </div>
         </b-field>
         <b-field label="Players Search First">
          <b-checkbox v-model="club.config.players_search_first">
            Admins/Directors see Search first on Players (recommended for larger clubs)
          </b-checkbox>
        </b-field>
         <div class="is-font-mont has-text-weight-bold is-size-16px">Recruiting Profiles</div>
         <b-field>
           <b-checkbox
             v-model="club.config.enable_recruiting_profiles_feature"
             @input="clearRecruitingProfilesMinAge($event)">
             Enable recruiting profiles feature
           </b-checkbox>
         </b-field>
         <b-field grouped>
           <b-checkbox
             style="margin-right: 0"
             :disabled="!club.config.enable_recruiting_profiles_feature"
             v-model="recruitingProfilesCanSelectMinAge"
             @input="clearRecruitingProfilesMinAge($event)"/>
           <div class="recruiting-profile-select">
             <p>Club can select</p>
             <b-select
               class="media-left media-right"
               :disabled="!recruitingProfilesCanSelectMinAge"
               v-model="recruitingProfilesCustomMinAge">
               <option :value="15">15</option>
               <option :value="14">14</option>
             </b-select>
             <p>as minimum age</p>
           </div>
         </b-field>
         <b-field label="Attribution Tag Window (Hours)">
           <b-input type="number" placeholder="Default -- 24 Hours" min="0" v-model="attributionWindowHours" />
         </b-field>
         <b-field label="New Drill Designer">
           <b-checkbox v-model="club.config.enable_new_drill_designer">
             Enable New Drill Designer
           </b-checkbox>
         </b-field>
      </div>
      <div class="box">
        <h2 class="title is-5">Internal Tracking</h2>
        <b-field label="Onboarding Type">
          <b-select
            v-model="club.config.onboarding_type"
            placeholder="Select Onboarding Type"
            expanded>
            <option
              v-for="type in ['Self Service', 'Full Service']"
              :key="type"
              :value="type">
              {{ type }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Status">
          <b-select
            v-model="club.config.onboarding_status"
            placeholder="Select Status"
            expanded>
            <option
              v-for="club_status in ['Onboarding', 'Active', 'Onboarding Risk', 'At Risk']"
              :key="club_status"
              :value="club_status">
              {{ club_status }}
            </option>
          </b-select>
        </b-field>
         <b-field label="Competitive Player Cap">
          <b-input v-model="club.config.competitive_player_cap" type="number"></b-input>
        </b-field>
         <b-field label="Recreational Player Cap">
          <b-input v-model="club.config.rec_player_cap" type="number"></b-input>
        </b-field>
      </div>
      <button @click="submit"
        class="button is-primary"
        :class="{'is-loading': loading}"
        :disabled="loading || invalid">
        Save Changes
      </button>
     </div>
   </template>
 </div>
</template>

<script>
import _ from 'lodash';
import api from '@/http-playmetrics';
import Spinner from '@/components/common/Spinner';

const dictionaryTerms = ['clinic', 'clinics', 'drill', 'drills', 'practice', 'practices', 'region', 'regions'];

export default {
  components: {
    'app-spinner': Spinner,
  },

  data() {
    return {
      showHidden: false,
      club: null,
      clubs: null,
      clubName: '',
      timezone: 'America/New_York',
      website: '',
      contactName: '',
      zendeskClubName: '',
      contactEmail: '',
      dictionary: {},
      clinic: null,
      clinics: null,
      drill: null,
      drills: null,
      practice: null,
      practices: null,
      recruitingProfilesCanSelectMinAge: false,
      recruitingProfilesCustomMinAge: 0,
      region: null,
      regions: null,
      sharedDrillsClub: [],
      disableAddDrillsClubButton: true,
      selectedDrillsClubToShare: null,
      sharedSessionsClub: {},
      abbreviation: '',
      emailDomain: '',
      sport: '',
      clubsByID: {},
      showAppReview: false,
      appReviewRoles: [],
      country: '',
      state: '',
      city: '',
      status: '',
      type: '',
    };
  },

  computed: {
    loading() {
      return ((this.club == null) || (this.clubs == null));
    },
    invalid() {
      const re = /\S+@\S+\.\S+/;
      return (this.clubName === '' || this.contactName === '' ||
        !re.test(this.contactEmail));
    },
    timezones() {
      return api().timezonesForCountry(this.country);
    },
    states() {
      return api().statesForCountry(this.country);
    },
    clubsForDrillSharing() {
      return _.filter(this.clubs, c =>
        (this.club.id !== c.id) &&
        (!c.name.startsWith('DELETED')) &&
        (this.club.is_test_club || !c.is_test_club || c.id === 2));
    },
    customPlayerFields: {
      get() {
        return JSON.stringify(this.club.config.custom_player_fields);
      },
      set(val) {
        const clean = val.replace(/(?:\r\n|\r|\n)/g, '');
        this.club.config.custom_player_fields = JSON.parse(clean);
      },
    },
    customTeamFields: {
      get() {
        return JSON.stringify(this.club.config.custom_team_fields);
      },
      set(val) {
        const clean = val.replace(/(?:\r\n|\r|\n)/g, '');
        this.club.config.custom_team_fields = JSON.parse(clean);
      },
    },
    attributionWindowHours: {
      get() {
        return this.club.config.attribution_window_hours || '';
      },
      set(value) {
        const valueAsNumber = Math.max(parseInt(value, 10) || 0, 0);
        this.$set(this.club.config, 'attribution_window_hours', valueAsNumber);
      },
    },
  },

  methods: {
    fetchClubData() {
      this.club = null;
      api().get(`admin/clubs/${this.$route.params.club}`)
        .then((response) => {
          this.club = response.data;
          this.initVariables();
        });
    },
    fetchClubList() {
      this.clubs = null;
      api().get('admin/clubs')
        .then((response) => {
          const clubs = response.data;
          this.clubsByID = _.keyBy(clubs, 'id');
          this.clubs = _.sortBy(clubs, 'name');
        });
    },
    updateRecruitingProfilesConfig() {
      switch (this.recruitingProfilesCustomMinAge) {
        case 14:
          this.club.config.recruiting_profiles_min_age_15 = true;
          this.club.config.recruiting_profiles_min_age_14 = true;
          break;
        case 15:
          if (this.club.config.recruiting_profile_settings.min_age < 15) {
            this.club.config.recruiting_profile_settings.min_age = 15;
          }
          this.club.config.recruiting_profiles_min_age_15 = true;
          this.club.config.recruiting_profiles_min_age_14 = false;
          break;
        default:
          if (this.club.config.recruiting_profile_settings.min_age < 16) {
            this.club.config.recruiting_profile_settings.min_age = 16;
          }
          this.club.config.recruiting_profiles_min_age_15 = false;
          this.club.config.recruiting_profiles_min_age_14 = false;
      }
    },
    clearRecruitingProfilesMinAge(input) {
      if (!input) {
        this.recruitingProfilesCanSelectMinAge = false;
        this.recruitingProfilesCustomMinAge = 0;
      }
    },
    removeSharedDrillsClubConfirmation(club) {
      this.$buefy.dialog.confirm({
        title: 'Remove Club from Import Drills',
        message: 'Are you sure you want to <b>remove</b> this Club for import drills (and sessions)?',
        confirmText: 'Remove',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.removeSharedDrillsClub(club),
      });
    },
    removeSharedDrillsClub(club) {
      this.sharedDrillsClub.splice(this.sharedDrillsClub.indexOf(club), 1);
    },
    assignSharedDrillsClub(club) {
      if (this.sharedDrillsClub[this.sharedDrillsClub.indexOf(club)] === club ||
        club === this.club.id) {
        this.$store.dispatch('createNotification', {
          message: 'Club is already in the list / You cannot choose your own club',
          success: false,
          toast: true });
      } else {
        this.sharedDrillsClub.push(club);
      }
    },
    loadSharedDrillsClub() {
      if (this.club.config.import_drills_from_clubs !== undefined) {
        this.sharedDrillsClub = this.club.config.import_drills_from_clubs;
      }
    },
    loadSharedSessionsClub() {
      if (this.club.config.import_sessions_from_clubs !== undefined) {
        _.each(_.intersection(this.club.config.import_sessions_from_clubs, this.sharedDrillsClub),
          (clubID) => { this.sharedSessionsClub[clubID] = true; });
      }
    },
    loadDictionary() {
      if (this.dictionary === undefined) {
        this.dictionary = {};
      }
      _.forEach(dictionaryTerms, (t) => {
        _.set(this, t, _.get(this.dictionary, t, null));
      });
    },
    buildDictionary() {
      this.dictionary = {};

      _.forEach(dictionaryTerms, (t) => {
        const val = _.get(this, t, null);
        if (val !== null) {
          _.set(this.dictionary, t, val);
        }
      });
    },
    closeModal() {
    },
    submit() {
      this.updateRecruitingProfilesConfig();
      this.club.config.competitive_player_cap = parseInt(this.club.config.competitive_player_cap, 10);
      this.club.config.rec_player_cap = parseInt(this.club.config.rec_player_cap, 10);
      const url = `/admin/clubs/${this.club.id}`;
      this.buildDictionary();
      const config = _.assign(this.club.config, {
        dictionary: this.dictionary,
        import_drills_from_clubs: this.sharedDrillsClub,
        import_sessions_from_clubs:
          _.filter(this.sharedDrillsClub, c => this.sharedSessionsClub[c]),
        show_app_review: this.showAppReview,
        app_review_roles: this.appReviewRoles,
        state: this.state,
        city: this.city,
      });
      const postData = {
        name: this.clubName,
        timezone: this.timezone,
        website: this.website,
        abbreviation: this.abbreviation,
        zendesk_club_name: this.zendeskClubName,
        email_domain: this.emailDomain,
        contact_name: this.contactName,
        contact_email: this.contactEmail,
        config,
      };
      api().post(url, postData)
        .then((response) => {
          this.$store.dispatch('createNotification', { message: 'Club Updated', success: true, toast: true });
          this.$router.push(`/clubs/${response.data.id}`);
        });
    },
    initVariables() {
      this.clubName = this.club.name;
      this.timezone = this.club.timezone;
      this.website = this.club.website;
      this.abbreviation = this.club.abbreviation;
      this.emailDomain = this.club.email_domain;
      this.contactName = this.club.contact_name;
      this.contactEmail = this.club.contact_email;
      this.dictionary = this.club.config.dictionary;
      this.loadDictionary();
      this.loadSharedDrillsClub();
      this.loadSharedSessionsClub();
      this.sport = _.map(this.club.sports, sport => sport.sport)[0];
      this.showAppReview = this.club.config.show_app_review || false;
      this.appReviewRoles = this.club.config.app_review_roles || [];
      this.country = this.club.config.country || 'US';
      this.state = this.club.config.state || '';
      this.city = this.club.config.city || '';
      if (this.club.config.recruiting_profiles_min_age_15) {
        this.recruitingProfilesCanSelectMinAge = true;
        this.recruitingProfilesCustomMinAge = 15;
      }
      if (this.club.config.recruiting_profiles_min_age_14) {
        this.recruitingProfilesCanSelectMinAge = true;
        this.recruitingProfilesCustomMinAge = 14;
      }
    },
  },

  created() {
    this.fetchClubData();
    this.fetchClubList();
  },
};
</script>

<style lang="sass" scoped>
.recruiting-profile-select
  display: flex
  align-items: center
</style>
