<template>
  <div>
    <h2>{{player.first_name}} {{player.last_name}}</h2>
    <b>Club:</b>
    <router-link :to="`/clubs/${player.club_id}`" tag="a">
      Club {{player.club_id}}
    </router-link><br/>
    <b>Birth Date:</b> {{player.birth_date | formatDate}}<br/>
    <b>Gender:</b> {{player.gender}}<br/>
    <b>Dominant Foot:</b> {{player.foot}}<br/>
    <b>Last Waiver At:</b> {{player.last_waiver_at | formatDate}}<br/>
    <b>Last Written Waiver At:</b> {{player.extra.last_written_waiver_at | formatDate}}<br/>
    <hr>

    <b>Teams:</b><br/>
    <div v-for="(teamPlayer, idx) in player.team_players" class="columns" :key="idx">
      <div class="column">
        <b>{{ teamPlayer.team_id }}</b> - {{ teamPlayer.team.name }}
      </div>
    </div>

    <b>Users:</b><br/>
    <ul>
      <li v-for="(user, idx) in player.users" class="columns" :key="idx">
        <div class="column">
          <router-link :to="`/users/${user.id}`" tag="a">
            <b>
              {{ user.email }}
              {{ (user.is_owner ? '(Owner)' : '') }}
            </b>
          </router-link>
        </div>
        <div v-if="currentUserHasFullAccess" class="column">
          <button @click="removeParentConfirmation(user)" class="button">Remove Parent</button>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import api from '@/http-playmetrics';

export default {
  props: {
    player: { type: Object, required: true },
  },

  computed: {
    currentUserHasFullAccess() {
      return this.$store.getters.user.is_admin;
    },
  },

  methods: {
    removeParentConfirmation(user) {
      this.$buefy.dialog.confirm({
        title: 'Remove Parent',
        message: `Are you sure you want to <b>remove</b><br/> ${user.email} as a parent?`,
        confirmText: 'Remove',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.removeParent(user),
      });
    },
    removeParent(user) {
      api().delete(`/admin/clubs/${this.player.club_id}/players/${this.player.id}/users/${user.id}`).then(() => {
        this.$emit('refresh');
      });
    },
  },
};
</script>
