// state
const state = {
  teamsFilters: {
    searchQuery: '',
    genders: [],
    selectedClub: null,
    selectedLevel: null,
  },
};

// getters
const getters = {
  teamsFilters(state) {
    return state.teamsFilters;
  },
};

// mutations
const mutations = {
  setTeamsFiltersSearchQuery(state, payload) {
    state.teamsFilters.searchQuery = payload;
  },
  setTeamsFiltersGenders(state, payload) {
    state.teamsFilters.genders = payload;
  },
  setTeamsFiltersSelectedClub(state, payload) {
    state.teamsFilters.selectedClub = payload;
  },
  setTeamsFiltersSelectedLevel(state, payload) {
    state.teamsFilters.selectedLevel = payload;
  },
};

// actions
const actions = {
  setTeamsFiltersSearchQuery(context, payload) {
    context.commit('setTeamsFiltersSearchQuery', payload);
  },
  setTeamsFiltersGenders(context, payload) {
    context.commit('setTeamsFiltersGenders', payload);
  },
  setTeamsFiltersSelectedClub(context, payload) {
    context.commit('setTeamsFiltersSelectedClub', payload);
  },
  setTeamsFiltersSelectedLevel(context, payload) {
    context.commit('setTeamsFiltersSelectedLevel', payload);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
