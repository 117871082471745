<template>
  <div>
    <div v-if="loading" class="box">
      <app-spinner ></app-spinner>
    </div>
    <div v-if="!loading" class="box">
      <nav class="level">
        <div class="level-left">
          <div class="level-item">
            <nav class="breadcrumb">
              <ul>
                <li>
                  <router-link to="/" tag="a">
                    Home
                  </router-link>
                </li>
                <li>
                  <router-link to="/users" tag="a">
                    Users
                  </router-link>
                </li>
                <li class="is-active">
                  <router-link :to="`/users/${user.id}`" tag="a">
                    {{user.email}}
                  </router-link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </nav>
      <nav class="level" v-if="user.deleted_at == null">
        <div class="level-left">
          <p v-if="currentUserHasFullAccess" class="level-item">
            <a @click="deleteUserConfirmation($event)" class="button is-primary">
              <span class="icon">
                <i class="fas fa-trash"></i>
              </span>
              <span>Delete</span>
            </a>
          </p>
          <p class="level-item">
            <a @click="register" class="button is-primary">
              <span class="icon">
                <i class="fas fa-envelope"></i>
              </span>
              <span>{{ registerLabel }}</span>
            </a>
          </p>
          <p v-if="canLoginAs" class="level-item">
            <a :href="ghostUrl" target="_blank" class="button is-primary">
              <span class="icon">
                <i class="fas fa-sign-in-alt"></i>
              </span>
              <span>Login as User</span>
            </a>
          </p>
          <p class="level-item">
            <a @click="resetPasswordLink" class="button is-primary">
              <span>Reset Password Link</span>
            </a>
          </p>
          <p class="level-item" v-if="hasPhone">
            <a @click="smsPasswordResetLink" class="button is-primary">
              <span>SMS Password Link</span>
            </a>
          </p>
          <p class="level-item">
            <a @click="loadUser" class="button is-primary">
              <span class="icon">
                <i class="far fa-redo"></i>
              </span>
              <span>Refresh User</span>
            </a>
          </p>
          <p class="level-item">
            <a @click="refreshRoles" class="button is-primary">
              <span class="icon">
                <i class="far fa-redo"></i>
              </span>
              <span>Refresh Roles</span>
            </a>
          </p>
        </div>
      </nav>
      <div v-if="resetLink">
        <b-input v-model="resetLink" :disabled="true"/>
        <button v-clipboard="resetLink"><i class="far fa-copy"></i> Copy Link</button>
      </div>
    </div>

    <div v-if="!loading" class="box">
      <div class="tabs is-fullwidth">
        <ul>
          <li @click="tabClicked('userInfo')"
            :class="{ 'is-active': activeTab === 'userInfo' }">
            <a>User Info</a>
          </li>
          <li @click="tabClicked('players')"
            :class="{ 'is-active': activeTab === 'players' }">
            <a>Players</a>
          </li>
          <li @click="tabClicked('roles')"
            :class="{ 'is-active': activeTab === 'roles' }">
            <a>Roles</a>
          </li>
          <li @click="tabClicked('emails')"
            :class="{ 'is-active': activeTab === 'emails' }">
            <a>Emails</a>
          </li>
          <li @click="tabClicked('reminders')"
            :class="{ 'is-active': activeTab === 'reminders' }">
            <a>Reminders</a>
          </li>
        </ul>
      </div>
      <div v-if="activeTab === 'userInfo'" class="content">
        <div class="columns">
          <div class="column">
            <b>Email</b>: {{user.email}}
              <span v-if="user.email_private">
                <b>(Private) </b>
              </span>
              <span v-if="user.invalid_email_at">
                <b>Invalid Email At</b>: {{user.invalid_email_at | formatDate('M/D/YY h:mmA')}}
              </span>
            <br/>
            <b>Secondary Email</b>: {{user.secondary_email}}
              <span v-if="user.secondary_email_private">
                <b>(Private)</b>
              </span>
            <br/>
            <b>First</b>: {{user.first_name}}<br/>
            <b>Last</b>: {{user.last_name}}<br/>
            <b>Mobile Number</b>: {{user.mobile_number}}
              <span v-if="user.mobile_private">
                <b>(Private)</b>
              </span>
            <br/>
            <b>Mobile Number Verified</b>: {{user.mobile_number_verified}}
            <br/>
            <b>Mobile 2FA</b>: {{user.mobile_2fa}}
            <span v-if="currentUserHasFullAccess">
              <b-button size="is-small" type="is-text" @click="editUser2FAModal = true">
                <i class="far fa-edit has-text-primary" style="font-size:1.2rem"></i>
              </b-button>
            </span><br/>
            <b>Firebase UID:</b> {{ user.firebase_uid }}
            <span><a :href="realtimeURL" target="_blank">
              View Realtime Roles
            </a></span><br/>
            <b>Last Register Email At</b>: {{user.last_register_email_at | formatDate('M/D/YY h:mmA')}}<br/>
            <b>Registered At</b>:
            <span v-if="user.registered_at">
              {{user.registered_at | formatDate('M/D/YY h:mmA')}}
            </span>
            <br/>
            <span v-if="!user.registered_at">
              <b>Signup URL</b>:
              <a target="_blank" :href="user.image_url">Click Here</a><br/>
            </span>
            <span v-if="user.deleted_at">
              <b>Deleted At</b>: {{user.deleted_at | formatDate('M/D/YY h:mmA')}}<br/>
            </span>
            <b>IsAdmin</b>: {{user.is_admin}}
            <span v-if="user.is_admin">
              <b>permissions</b>: {{user.admin_permissions}}
              <a @click.stop="editAdminUserModal=true">
                <i class="far fa-edit has-text-primary" style="font-size:1rem"></i></a>
            </span><br/>
            <b>Extra</b>: {{user.extra}}<br/>
            <b>SMS Stops</b>: {{user.sms_stops ? user.sms_stops : 'No Stops'}}<br/>
            <b>Governing Body User ID</b>:
            <a target="_blank" :href="gbUserURL">
              {{user.sync_governing_body_id}}
            </a>
            <br/>
            <div v-if="firebaseUser" class="has-text-weight-bold" style="margin-top:1rem">
              <div class="has-text-danger">
                WARNING: Unattached Firebase user found for email {{firebaseUser.email}}
              </div>
              <a @click="gotoFirebaseUser">Click to Modify</a>
            </div>
            <br><br>
            {{ user }}
          </div>

        </div>
        <app-add-or-edit-admin-user
          v-if="editAdminUserModal"
          @dismissAdminUserModal="dismissAdminUserModal($event)"
          :user-id=this.user.id>
        </app-add-or-edit-admin-user>
        <EditUser2FANumber
          v-if="editUser2FAModal"
          @dismissModal="dismissEdit2FAModal($event)"
          :user="user"
        ></EditUser2FANumber>
      </div>
      <div v-if="activeTab === 'players'" class="content">
        <b-table
          :data="user.players"
          narrowed
          hoverable
          mobile-cards>
          <template slot-scope="props">
            <b-table-column field="first_name" label="Name" sortable>
              <router-link :to="`/clubs/${props.row.club_id}/players/${props.row.id}`" tag="a">
                {{props.row.first_name}} {{props.row.last_name}}
              </router-link>
            </b-table-column>
            <b-table-column field="gender" label="Gender" sortable>
              {{ props.row.gender }}
            </b-table-column>
            <b-table-column field="birth_date" label="Birth Date" sortable>
              {{ props.row.birth_date }}
            </b-table-column>
            <b-table-column field="team_name" label="Team" sortable>
              {{ props.row.team_name }}
            </b-table-column>
            <b-table-column label="Level">
              {{ playerLevel(props.row) }}
            </b-table-column>
            <b-table-column label="Club">
              <router-link :to="`/clubs/${props.row.club_id}`" tag="a">
                Club #{{ props.row.club_id }}
              </router-link><br/>
            </b-table-column>
          </template>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>This user has no players.</p>
              </div>
            </section>
          </template>
        </b-table>
      </div>

      <div v-if="activeTab === 'roles'" class="content">
        <b-table
          :data="user.roles"
          narrowed
          hoverable
          mobile-cards>
          <template slot-scope="props">
            <b-table-column field="id" label="ID" sortable>
              {{ props.row.id }}
            </b-table-column>
            <b-table-column field="type" label="Type" sortable>
              {{ props.row.type }}
            </b-table-column>
            <b-table-column field="club_name" label="Club" sortable>
              <router-link :to="`/clubs/${props.row.club_id}`" tag="a">
                {{ props.row.club_name }}
              </router-link>
            </b-table-column>
            <b-table-column v-if="currentUserHasFullAccess" label="" centered>
              <a
                class="has-text-primary clickable"
                @click="removeRoleConfirmation(props.row, $event)">
                <i class="fas fa-trash"></i>
              </a>
            </b-table-column>
          </template>
        </b-table>
      </div>

      <div v-if="activeTab === 'emails'" class="content">
        <div class="">
          <app-user-emails :emails="user.sent_emails || []"></app-user-emails>
        </div>
      </div>

      <div v-if="activeTab === 'reminders'" class="content">
        <div class="">
          <app-user-reminders :reminders="user.reminder_triggers || []"></app-user-reminders>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CONFIG from '@/env-config';
import _ from 'lodash';
import api from '@/http-playmetrics';

import Spinner from '@/components/common/Spinner';
import AddOrEditAdminUser from '@/components/admin-users/AddOrEditAdminUser';
import EditUser2FANumber from './EditUser2FANumber';
import UserEmails from './UserEmails';
import UserReminders from './UserReminders';

export default {
  components: {
    'app-spinner': Spinner,
    'app-user-emails': UserEmails,
    'app-user-reminders': UserReminders,
    'app-add-or-edit-admin-user': AddOrEditAdminUser,
    EditUser2FANumber,
  },

  data() {
    return {
      loading: true,
      user: null,
      firebaseUser: null,
      activeTab: 'userInfo',
      resetLink: null,
      editAdminUserModal: false,
      editUser2FAModal: false,
    };
  },

  computed: {
    ghostUrl() {
      return `${CONFIG.frontendBaseURL}login-as/${this.user.id}`;
    },
    currentUserHasFullAccess() {
      return this.$store.getters.user.is_admin;
    },
    hasPhone() {
      return this.user.mobile_number !== '';
    },
    canLoginAs() {
      if (this.currentUserHasFullAccess) {
        return true;
      }
      const roleTypes = _.uniq(_.map(this.user.roles, 'type'));
      return _.intersection(roleTypes, ['Coach', 'Manager', 'Parent']).length === roleTypes.length;
    },
    registerLabel() {
      if (this.user.firebase_uid !== '' && !this.user.registered_at) return 'Confirm Email';
      return this.user.registered_at == null ? 'Register' : 'Re-register';
    },
    realtimeURL() {
      let dbURL = CONFIG.fbRoles?.databaseURL; // Ex: https://playmetrics-staging-user-roles.firebaseio.com
      if (!dbURL) return '';
      dbURL = dbURL.replace('https://', '').replace('.firebaseio.com', '');
      let prefix = `https://console.firebase.google.com/u/0/project/playmetrics-staging/database/${dbURL}/data/`;
      if (dbURL.includes('playmetrics-prod')) {
        prefix = `https://console.firebase.google.com/u/0/project/playmetrics-prod/database/${dbURL}/data/`;
      }
      return `${prefix}~2Fusers~2F${this.user.firebase_uid}`;
    },
    gbUserURL() {
      return `${CONFIG.gbCstURL}users/${this.user.sync_governing_body_id}`;
    },
  },

  created() {
    this.loadUser();
  },

  methods: {
    loadUser() {
      this.loading = true;
      api().get(`admin/users/${this.$route.params.user}`).then((response) => {
        const user = response.data;
        if (user && !user.firebase_uid) this.loadFirebase(user.email);
        this.user = user;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    loadFirebase(email) {
      api().get(`/admin/firebase_user/email/${email}`).then((response) => {
        if (!response.data.error) this.firebaseUser = response.data;
      });
    },
    playerLevel(player) {
      return player.level; // not defined on the player, but we don't care anymore
    },
    deleteUserConfirmation($event) {
      $event.preventDefault();
      $event.stopPropagation();
      this.$buefy.dialog.confirm({
        title: 'Delete User',
        message: `Are you sure you want to <b>delete</b><br/> ${this.user.email}?`,
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteUser(),
      });
    },
    deleteUser() {
      api().delete(`/admin/users/${this.user.id}`).then(() => {
        this.loadUser();
        this.$store.dispatch('setSuccessToast', 'User deleted.');
      });
    },
    register() {
      api().post(`/admin/users/${this.user.id}/reregister`).then(() => {
        this.loadUser();
        this.$store.dispatch('setSuccessToast', `Registration email sent to ${this.user.email}.`);
      });
    },
    resetPasswordLink() {
      api().get(`/admin/users/${this.user.id}/password_reset_link`).then((response) => {
        this.resetLink = response.data.link;
      });
    },
    smsPasswordResetLink() {
      api().post(`/admin/users/${this.user.id}/send_sms_password_reset_link`).then(() => {
        this.$store.dispatch('setSuccessToast', `SMS with reset link sent to ${this.user.mobile_number}.`);
      });
    },
    tabClicked(tab) {
      this.activeTab = tab;
    },
    selected(player) {
      this.$router.push(`/clubs/${player.club_id}/players/${player.id}`);
    },
    removeRoleConfirmation(role, $event) {
      $event.preventDefault();
      $event.stopPropagation();
      this.$buefy.dialog.confirm({
        title: 'Remove Role',
        message: `Are you sure you want to <b>remove</b><br/> ${role.type} access to club ${role.club_id}?`,
        confirmText: 'Remove',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.removeRole(role),
      });
    },
    dismissAdminUserModal(options) {
      if (options.refreshData) {
        this.loadUser();
      }
      this.editAdminUserModal = false;
    },
    dismissEdit2FAModal(options) {
      if (options.refreshData) {
        this.loadUser();
      }
      this.editUser2FAModal = false;
    },
    removeRole(role) {
      api().delete(`/admin/users/${this.user.id}/roles/${role.id}`).then(() => {
        this.loadUser();
        this.$store.dispatch('setSuccessToast', 'Role removed.');
      });
    },
    gotoFirebaseUser() {
      this.$router.push(`/firebase-users?email=${encodeURIComponent(this.user.email)}`);
    },
    refreshRoles() {
      api().post(`/admin/users/${this.user.id}/refresh_roles`).then(() => {
        this.$store.dispatch('setSuccessToast', 'Roles Refreshed');
      });
    },
  },
};
</script>
