<template>
  <div>
    <div class="field">
      <div class="control">
        <input v-model="searchQuery" class="input" type="text" placeholder="Search...">
      </div>
    </div>
    <b-table
      :data="filteredEmails"
      narrowed
      hoverable
      mobile-cards
      detailed
      detail-key="id"
      :per-page="perPage"
      paginated
      pagination-simple>
      <template slot-scope="props">
        <b-table-column field="Template" label="Template" sortable>
          <router-link :to="`/emails/${props.row.template}`">
            {{ props.row.template }}
          </router-link>
        </b-table-column>
        <b-table-column field="Subject" label="Subject" sortable>
          <b-tooltip v-if="props.row.subject.length > 35" :label="`${props.row.subject}`" dashed>
            {{ truncateSubject(props.row.subject) }}
          </b-tooltip>
          <span v-else>{{props.row.subject}}</span>
        </b-table-column>
        <b-table-column field="MailingID" label="MailingID" sortable>
          {{ props.row.mailing_id }}
        </b-table-column>
        <b-table-column field="SentAt" label="Sent" sortable>
          <span v-if="props.row.send_at">
            <b-tooltip :label="`${formatDate(props.row.send_at)}`" dashed>
              {{ props.row.send_at | formatDate('l') }}
            </b-tooltip>
          </span>
          <span v-else>-</span>
        </b-table-column>
        <b-table-column field="OpenedAt" label="Opened" sortable>
          <span v-if="props.row.opened_at">
            <b-tooltip :label="`${formatDate(props.row.opened_at)}`" dashed>
              {{ props.row.opened_at | formatDate('l') }}
            </b-tooltip>
          </span>
          <span v-else>-</span>
        </b-table-column>
        <b-table-column field="ClickedAt" label="Clicked" sortable>
          <span v-if="props.row.clicked_at">
            <b-tooltip :label="`${formatDate(props.row.clicked_at)}`" dashed>
              {{ props.row.clicked_at | formatDate('l') }}
            </b-tooltip>
          </span>
          <span v-else>-</span>
        </b-table-column>
      </template>
      <template slot="detail" slot-scope="props">
        <b>Email ID</b>: {{ props.row.ID }}<br/>
        <b>User ID</b>: {{ props.row.UserID }}<br/>
        <b>Player ID</b>: {{ props.row.player_id }}<br/>
        <b>Email To</b>: {{ props.row.email_to }}<br/>
        <b>Template</b>: {{ props.row.template }}<br/>
        <b>Subject</b>: {{ props.row.subject }}<br/>
        <b>Context Type</b>: {{ props.row.ContextType }}<br/>
        <b>Context ID</b>: {{ props.row.ContextID }}<br/>
        <b>Sent At</b>:
          <span v-if="props.row.send_at">
            {{ props.row.send_at | formatDate('llll') }}
          </span><br/>
        <b>Delivered At</b>:
          <span v-if="props.row.delivered_at">
            {{ props.row.delivered_at | formatDate('llll') }}
          </span><br/>
        <b>Opened At</b>:
          <span v-if="props.row.opened_at">
            {{ props.row.opened_at | formatDate('llll') }}
          </span><br/>
        <b>Clicked At</b>:
          <span v-if="props.row.clicked_at">
            {{ props.row.clicked_at | formatDate('llll') }}
          </span><br/>
        <b>Complained At</b>:
          <span v-if="props.row.complained_at">
            {{ props.row.complained_at | formatDate('llll') }}
          </span><br/>
        <b>Permanent Fail At</b>:
          <span v-if="props.row.permanent_fail_at">
            {{ props.row.permanent_fail_at | formatDate('llll') }}
          </span><br/>
        <b>Temporary Fail At</b>:
          <span v-if="props.row.temporary_fail_at">
            {{ props.row.temporary_fail_at | formatDate('llll') }}
          </span><br/>
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>No results</p>
          </div>
        </section>
      </template>
      <template slot="bottom-left">
        <b-select v-model="perPage">
          <option value="10">10 per page</option>
          <option value="15">15 per page</option>
          <option value="20">20 per page</option>
          <option value="50">50 per page</option>
          <option value="100">100 per page</option>
        </b-select>
      </template>
    </b-table>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    emails: { type: Array, required: true },
  },

  data() {
    return {
      perPage: 20,
      searchQuery: '',
    };
  },

  computed: {
    filteredEmails() {
      return this.emails.filter(email =>
        email.template.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
        email.subject.toLowerCase().includes(this.searchQuery.toLowerCase()),
      );
    },
  },

  methods: {
    formatDate(date) {
      return moment(date).format('llll');
    },
    truncateSubject(subject) {
      if (subject.length > 35) {
        return `${subject.substring(0, 36)}...`;
      }
      return subject;
    },
  },
};
</script>
