<template>
  <div>
    <div class="box">
      <div class="field">
        <div class="control">
          <input v-model="searchQuery" class="input" type="text" placeholder="Search...">
        </div>
      </div>
    </div>
    <div class="box">
      <app-spinner v-if="loading"></app-spinner>
      <div v-else>
        <div class="columns">
          <div class="column">
            <b-select
              v-model="clubToEnable"
              placeholder="Select a Club to enable..."
              expanded>
              <option
                v-for="club in nonPandadocClubs"
                :value="club"
                :key="club.id">
                {{ club.name }} ({{ club.id }})
              </option>
            </b-select>
          </div>
          <div class="column is-narrow">
            <button
              @click="enableClub()"
              class="button is-primary">
              <span class="icon">
                <i class="fas fa-plus"></i>
              </span>
              <span>Enable PandaDoc For Club</span>
            </button>
          </div>
        </div>
        <b-table
          :data="filteredClubPandadocs"
          :per-page="perPage"
          narrowed
          hoverable
          mobile-cards
          paginated
          pagination-simple
          @select="selected">
          <template slot-scope="props">
            <b-table-column field="club_id" label="Club ID" sortable>
              {{ props.row.club_id }}
            </b-table-column>
            <b-table-column field="club_name" label="Club Name" sortable>
              {{ props.row.club_name }}
            </b-table-column>
            <b-table-column field="enabled" label="Enabled" sortable>
              {{ props.row.enabled ? 'Yes' : 'No' }}
            </b-table-column>
            <b-table-column field="uses" label="#Uses" numeric sortable>
              {{ props.row.use_count }}
            </b-table-column>
          </template>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>No results</p>
              </div>
            </section>
          </template>
          <template slot="bottom-left">
            <b-select v-model="perPage">
              <option value="10">10 per page</option>
              <option value="15">15 per page</option>
              <option value="20">20 per page</option>
              <option value="50">50 per page</option>
              <option value="100">100 per page</option>
            </b-select>
          </template>
        </b-table>
      </div>
    </div>
    <app-edit-club-pandadoc
      v-if="selectedClub != null"
      :club="selectedClub"
      @dismissClubPandadocModal="dismissClubPandadocModal($event)">
    </app-edit-club-pandadoc>
  </div>
</template>

<script>
import _ from 'lodash';
import api from '@/http-playmetrics';
import Spinner from '@/components/common/Spinner';
import EditClubPandadoc from './EditClubPandadoc';

export default {
  components: {
    'app-spinner': Spinner,
    'app-edit-club-pandadoc': EditClubPandadoc,
  },

  data() {
    return {
      loading: true,
      clubs: null,
      clubPandadocs: null,
      nonPandadocClubs: [],
      perPage: 20,
      searchQuery: '',
      clubToEnable: null,
      selectedClub: null,
    };
  },

  computed: {
    filteredClubPandadocs() {
      return this.clubPandadocs.filter(club =>
        club.club_name.toLowerCase().includes(this.searchQuery.toLowerCase()));
    },
  },

  methods: {
    selected(clubPandadoc) {
      this.$router.push(`/pandadoc_accounts/${clubPandadoc.id}`);
    },
    fetchClubPandadocs() {
      this.loading = true;
      api().get('admin/pandadoc_accounts').then((resp) => {
        this.clubPandadocs = _.map(resp.data, (clubPandadoc) => {
          let name = clubPandadoc.club.name;
          if (clubPandadoc.club.is_test_club) {
            name += ' (test club)';
          }
          clubPandadoc.club_name = name;
          clubPandadoc.use_count = 0;
          _.each(clubPandadoc.document_stats, (stat) => { clubPandadoc.use_count += stat.num_documents; });
          return clubPandadoc;
        });
        this.nonPandadocClubs = _.sortBy(_.filter(this.clubs,
          club => !_.find(this.clubPandadocs, c => c.club_id === club.id)), ['name']);
        this.loading = false;
      });
    },
    enableClub() {
      this.selectedClub = this.clubToEnable;
    },
    dismissClubPandadocModal(options) {
      this.selectedClub = null;
      if (!this.loading) {
        if (options.refreshData) {
          this.fetchClubPandadocs();
        }
      }
    },
  },

  created() {
    api().get('admin/clubs').then((resp) => {
      this.clubs = resp.data;
      this.fetchClubPandadocs();
    });
  },
};
</script>

<style>
  .disabled-club {
    text-decoration: line-through;
    background-color: lightgrey;
  }
</style>
