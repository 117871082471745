<template>
  <div class="modal is-active">
    <div @click="dismissModal()" class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <div class="modal-card-title">Create Admin</div>
        <button @click="dismissModal()" class="delete" aria-label="close"></button>
      </header>
      <section class="modal-card-body">
        <b-field label="Email">
          <b-input v-model="email" type="email"></b-input>
        </b-field>
        <b-field label="First Name">
          <b-input v-model="firstName"></b-input>
        </b-field>
        <b-field label="Last Name">
          <b-input v-model="lastName"></b-input>
        </b-field>
        <b-field label="Mobile Number">
          <b-input v-model="mobileNumber"></b-input>
        </b-field>
        <b-field label="Club">
          <b-input v-model="clubName" disabled></b-input>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <button @click="createAdmin"
          class="button is-primary"
          :class="{'is-loading': loading}"
          :disabled="loading || invalid">
          Create Admin
        </button>
        <button @click="dismissModal()" class="button">Cancel</button>
      </footer>
    </div>
  </div>
</template>

<script>
import api from '@/http-playmetrics';

export default {
  props: {
    clubName: { type: String, required: true },
  },

  data() {
    return {
      loading: false,
      email: '',
      firstName: '',
      lastName: '',
      mobileNumber: '',
    };
  },

  computed: {
    invalid() {
      const re = /\S+@\S+\.\S+/;
      return !re.test(this.email);
    },
  },

  methods: {
    createAdmin() {
      this.loading = true;
      let url = `/admin/clubs/${this.$route.params.club}/admin_users`;
      api().post(url, {
        email: this.email,
        first_name: this.firstName,
        last_name: this.lastName,
        mobile_number: this.mobileNumber,
      })
        .then((response) => {
          this.$store.dispatch('setSuccessToast', 'Admin Created');
          url += `/${response.data.id}/permissions`;
          api({ showError: false }).post(url, ['club_administration']).then(() => {
            this.loading = false;
            this.dismissModal({ refreshData: true });
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    dismissModal(options = { refreshData: false }) {
      this.$emit('dismissAdminModal', options);
    },
  },
};
</script>
