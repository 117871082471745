<template>
  <div>
    <div v-if="loading" class="box">
      <app-spinner ></app-spinner>
    </div>
    <div v-if="!loading" class="box">
      <nav class="level">
        <div class="level-left">
          <div class="level-item">
            <nav class="breadcrumb">
              <ul>
                <li>
                  <router-link to="/" tag="a">
                    Home
                  </router-link>
                </li>
                <li>
                  <router-link to="/pandadoc_accounts" tag="a">
                    PandaDoc Accounts
                  </router-link>
                </li>
                <li class="is-active">
                  <router-link :to="`/pandadoc_accounts/${clubPandadoc.club_id}`" tag="a">
                    {{clubPandadoc.club.name}}
                  </router-link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div class="level-right">
          <p class="level-item">
            <button
              @click="showEditModal = true"
              class="button is-primary">
              <span>Edit</span>
            </button>
          </p>
          <p class="level-item">
            <button
              @click="showTemplateModal = true"
              class="button is-primary">
              <span>Create Template</span>
            </button>
          </p>
          <p class="level-item">
            <button
              @click="showPaymentModal = true"
              class="button is-primary">
              <span>Create Payment</span>
            </button>
          </p>
        </div>
      </nav>
    </div>
    <div v-if="!loading" class="box">
      <div class="columns">
        <div class="column">
          <h2>{{ clubPandadoc.club.id }} - {{ clubPandadoc.club.name }}</h2>
        </div>
      </div>
      <hr>
      <div class="tabs is-fullwidth">
        <ul>
          <li @click="tabClicked('templates')"
            :class="{ 'is-active': activeTab === 'templates' }">
            <a>Templates</a>
          </li>
          <li @click="tabClicked('payments')"
            :class="{ 'is-active': activeTab === 'payments' }">
            <a>Payments</a>
          </li>
        </ul>
      </div>
      <div v-if="activeTab === 'templates'" class="columns">
        <b-table
          :data="clubPandadoc.templates"
          :per-page="perPage"
          hoverable
          mobile-cards
          detailed
          detail-key="id"
          paginated
          pagination-simple>
          <template slot-scope="props">
            <b-table-column field="id" label="ID" sortable>
              {{ props.row.id }}
            </b-table-column>
            <b-table-column field="vendor_id" label="Vendor ID" sortable>
              {{ props.row.vendor_id }}
            </b-table-column>
            <b-table-column field="name" label="Template Name" sortable>
              {{ props.row.name }}
            </b-table-column>
            <b-table-column field="status" label="Status" sortable>
              {{ props.row.status }}
            </b-table-column>
            <b-table-column field="use_count" label="#Uses" numeric sortable>
              {{ props.row.use_count }}
            </b-table-column>
            <b-table-column label="" centered>
              <a
                class="has-text-primary clickable"
                @click="editTemplate(props.row)">
                <i class="fas fa-pencil"></i>
              </a>
              &nbsp;&nbsp;&nbsp;
              <a
                class="has-text-primary clickable"
                @click="viewSample(props.row)">
                <i class="fas fa-envelope"></i>
              </a>
            </b-table-column>
          </template>
          <template slot="detail" slot-scope="props">
            <div>{{ props.row.form_json }}</div>
          </template>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>No results</p>
              </div>
            </section>
          </template>
          <template slot="bottom-left">
            <b-select v-model="perPage">
              <option value="10">10 per page</option>
              <option value="15">15 per page</option>
              <option value="20">20 per page</option>
              <option value="50">50 per page</option>
              <option value="100">100 per page</option>
            </b-select>
          </template>
        </b-table>
      </div>
      <div v-if="activeTab === 'payments'" class="columns">
        <b-table
          :data="clubPandadoc.payments"
          :per-page="perPage"
          hoverable
          mobile-cards
          paginated
          pagination-simple>
          <template slot-scope="props">
            <b-table-column field="id" label="ID" sortable>
              {{ props.row.id }}
            </b-table-column>
            <b-table-column field="created_at" label="Created" sortable>
              {{ props.row.created_at }}
            </b-table-column>
            <b-table-column field="description" label="Description" sortable>
              {{ props.row.description }}
            </b-table-column>
            <b-table-column field="cents" label="Amount (cents)" numeric sortable>
              {{ props.row.cents }}
            </b-table-column>
            <b-table-column label="" centered>
              <a
                class="has-text-primary clickable"
                @click="editPayment(props.row)">
                <i class="fas fa-pencil"></i>
              </a>
              &nbsp;&nbsp;&nbsp;
              <a
                class="has-text-primary clickable"
                @click="deletePayment(props.row)">
                <i class="fas fa-trash"></i>
              </a>
            </b-table-column>
          </template>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>No results</p>
              </div>
            </section>
          </template>
          <template slot="bottom-left">
            <b-select v-model="perPage">
              <option value="10">10 per page</option>
              <option value="15">15 per page</option>
              <option value="20">20 per page</option>
              <option value="50">50 per page</option>
              <option value="100">100 per page</option>
            </b-select>
          </template>
        </b-table>
      </div>
    </div>
    <app-edit-club-pandadoc
      v-if="showEditModal"
      :club-pandadoc="clubPandadoc"
      @dismissClubPandadocModal="dismissClubPandadocModal($event)">
    </app-edit-club-pandadoc>
    <app-edit-digital-document-template
      v-if="showTemplateModal"
      :club-pandadoc="clubPandadoc"
      :digital-document-template="templateToEdit"
      @dismissDigitalDocumentTemplateModal="dismissDigitalDocumentModal($event)">
    </app-edit-digital-document-template>
    <app-edit-digital-document-payment
      v-if="showPaymentModal"
      :club-pandadoc="clubPandadoc"
      :digital-document-payment="paymentToEdit"
      @dismissDigitalDocumentPaymentModal="dismissDigitalDocumentModal($event)">
    </app-edit-digital-document-payment>
    <app-view-sample-document
      v-if="showDocumentModal"
      :club-pandadoc="clubPandadoc"
      :digital-document-template="templateToViewSample"
      @dismissDigitalDocumentTemplateModal="dismissDigitalDocumentModal($event)">
    </app-view-sample-document>
  </div>
</template>

<script>
import _ from 'lodash';
import api from '@/http-playmetrics';
import Spinner from '@/components/common/Spinner';
import EditClubPandadoc from './EditClubPandadoc';
import EditDigitalDocumentTemplate from './EditDigitalDocumentTemplate';
import EditDigitalDocumentPayment from './EditDigitalDocumentPayment';
import ViewSampleDocument from './ViewSampleDocument';

export default {
  components: {
    'app-spinner': Spinner,
    'app-edit-club-pandadoc': EditClubPandadoc,
    'app-edit-digital-document-template': EditDigitalDocumentTemplate,
    'app-edit-digital-document-payment': EditDigitalDocumentPayment,
    'app-view-sample-document': ViewSampleDocument,
  },

  data() {
    return {
      loading: true,
      clubPandadoc: null,
      activeTab: 'templates',
      showEditModal: false,
      showTemplateModal: false,
      templateToEdit: null,
      showPaymentModal: false,
      paymentToEdit: null,
      showDocumentModal: false,
      templateToViewSample: null,
      perPage: 20,
    };
  },

  methods: {
    tabClicked(tab) {
      this.activeTab = tab;
    },
    fetchClub() {
      this.loading = true;
      api().get(`/admin/pandadoc_accounts/${this.$route.params.clubPandadoc}`)
        .then((resp) => {
          resp.payments ||= [];
          resp.templates ||= [];
          _.each(resp.data.templates, (t) => {
            t.use_count = 0;
            _.each(t.document_stats, (stat) => { t.use_count += stat.num_documents; });
          });
          this.clubPandadoc = resp.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    editTemplate(template) {
      this.templateToEdit = template;
      this.showTemplateModal = true;
    },
    viewSample(template) {
      this.templateToViewSample = template;
      this.showDocumentModal = true;
    },
    editPayment(payment) {
      this.paymentToEdit = payment;
      this.showPaymentModal = true;
    },
    deletePayment(payment) {
      this.paymentToEdit = payment;
      // Delete("/pandadoc_accounts/{club_pandadoc_id}/payments/{payment_id}"
      if (payment) {
        api().delete(`admin/pandadoc_accounts/${this.clubPandadoc.id}/payments/${payment.id}`)
          .then(() => {
            this.fetchClub();
          });
      }
    },
    dismissClubPandadocModal(options) {
      this.showEditModal = false;
      if (options.refreshData) {
        this.fetchClub();
      }
    },
    dismissDigitalDocumentModal(options) {
      this.showTemplateModal = false;
      this.templateToEdit = null;
      this.showDocumentModal = false;
      this.templateToViewSample = null;
      this.showPaymentModal = false;
      this.paymentToEdit = null;
      if (options.refreshData) {
        this.fetchClub();
      }
    },
  },

  created() {
    this.fetchClub();
  },
};
</script>
