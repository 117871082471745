<template>
  <div class="modal" :class="modalClass">
    <div class="modal-background"></div>
    <div class="modal-card">
      <template v-if="showExpiredModal">
        <header class="modal-card-head">
          <div class="modal-card-title">Authentication Expired</div>
        </header>
        <section class="modal-card-body">
          <p>
            Your authentication has expired.
          </p>
        </section>
        <footer class="modal-card-foot">
          <b-button
            class="is-primary"
            @click="handleLogOut"
          >Log Out</b-button>
        </footer>
      </template>
      <template v-else>
        <header class="modal-card-head">
          <div class="modal-card-title">Authentication Required</div>
        </header>
        <section class="modal-card-body">
          <p>
            Your authentication is about to expire.  Please re-authenticate.
          </p>
          <p v-if="errorMessage" style="color:#f00;">
            {{ errorMessage }}
          </p>
        </section>
        <footer class="modal-card-foot">
          <b-button
            class="is-primary"
            :loading="requestInFlight"
            :disabled="requestInFlight"
            @click="handleSubmit"
          >Log In with Google</b-button>
        </footer>
      </template>
    </div>
  </div>
</template>

<script>
import {
  getAuth,
  getIdTokenResult,
  signInWithPopup,
  GoogleAuthProvider,
} from 'firebase/auth';

const SECOND_IN_MILLISECONDS = 1000;
const MINUTE_IN_MILLISECONDS = 60 * SECOND_IN_MILLISECONDS;
const HOUR_IN_MILLISECONDS = 60 * MINUTE_IN_MILLISECONDS;

const WARNING_SUPER_ADMIN_AUTH_DURATION_MILLISECONDS = 12 * 24 * HOUR_IN_MILLISECONDS; // 12 Days
const EXPIRED_SUPER_ADMIN_AUTH_DURATION_MILLISECONDS = 15 * 24 * HOUR_IN_MILLISECONDS; // 15 Days

export default {
  data() {
    return {
      // timeoutId: new Error("DO NOT UNCOMMENT THIS LINE, IT IS INTENTIONALLY NOT REACTIVE"),
      showExpiredModal: false,
      showReauthenticationModal: false,
      errorMessage: '',
      requestInFlight: false,
    };
  },
  computed: {
    userEmail() {
      return this.$store.getters.user?.email;
    },
    modalClass() {
      if (this.showExpiredModal || this.showReauthenticationModal) {
        return 'is-active';
      }
      return '';
    },
  },
  watch: {
    userEmail: {
      handler(value) {
        this.stopTimer();
        if (value) {
          this.checkAuthTime();
        }
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    this.stopTimer();
  },
  methods: {
    checkAuthTime() {
      const currentUser = getAuth(this.$firebaseApp).currentUser;
      getIdTokenResult(currentUser).then((result) => {
        const authTime = new Date(result.authTime).getTime();
        const nowTime = new Date().getTime();
        const authDuration = nowTime - authTime;
        if (authDuration > EXPIRED_SUPER_ADMIN_AUTH_DURATION_MILLISECONDS) {
          this.showExpiredModal = true;
          this.showReauthenticationModal = false;
        } else if (authDuration > WARNING_SUPER_ADMIN_AUTH_DURATION_MILLISECONDS) {
          this.showExpiredModal = false;
          this.showReauthenticationModal = true;
        } else {
          this.startTimer(); // Check again later...
        }
      }).catch(() => {
        this.startTimer(); // We encountered an error... Ignore and try again later.
      });
    },
    startTimer() {
      this.stopTimer(); // Stop any existing timeouts...
      this.timeoutId = setTimeout(() => {
        this.checkAuthTime();
      }, 3 * HOUR_IN_MILLISECONDS);
    },
    stopTimer() {
      clearTimeout(this.timeoutId);
    },
    handleSubmit() {
      if (this.requestInFlight) {
        return;
      }
      this.requestInFlight = true;
      const auth = getAuth(this.$firebaseApp);
      signInWithPopup(auth, new GoogleAuthProvider()).then(() => {
        this.showExpiredModal = false;
        this.showReauthenticationModal = false;
        this.startTimer();
      }).catch((error) => {
        this.errorMessage = 'Unable to authenticate. Please try again.';
        console.error(error);
      }).finally(() => {
        this.requestInFlight = false;
      });
    },
    handleLogOut() {
      this.showExpiredModal = false;
      this.showReauthenticationModal = false;
      this.$store.dispatch('logOut');
    },
  },
};
</script>
