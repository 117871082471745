<template>
  <section class="section" style="padding-top:1.5rem">
    <div class="container">
      <div class="columns">
        <div class="column is-narrow">
          <app-side-bar></app-side-bar>
        </div>
        <div class="column">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SideBar from './SideBar';

export default {
  components: {
    'app-side-bar': SideBar,
  },
};
</script>
