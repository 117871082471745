<template>
  <div>
    <div class="navbar-extension"></div>
    <nav class="navbar container">
      <div class="navbar-brand">
        <router-link to="/" class="navbar-item" exact>
          <img width="150" src="/static/images/logos/playmetrics-logo.svg">
        </router-link>

        <div v-if="user"
          @click="toggleBurger = !toggleBurger"
          class="navbar-burger burger"
          :class="{ 'is-active' : toggleBurger }">
          <span></span>
          <span></span>
          <span></span>
        </div>

      </div>

      <div class="navbar-menu" :class="{ 'is-active' : toggleBurger }">
        <div v-if="user" class="navbar-end">
          <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link has-text-white">{{user.email}}</a>
            <div class="navbar-dropdown is-right">
              <div class="navbar-item">
                <a @click="logOut"
                  class="button is-primary is-fullwidth" >
                  <span>Sign Out</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      toggleBurger: false,
    };
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },
  },

  methods: {
    logOut() {
      this.$store.dispatch('logOut');
    },
  },

  watch: {
    $route() {
      this.toggleBurger = false;
    },
  },
};
</script>

<style scoped>
  @media screen and (max-width: 1023px) {
    .navbar-menu {
      background-color: inherit;
    }
  }
  #nav-buttons {
    padding-right: 0rem;
  }
  .navbar-burger span {
    background-color: white;
  }
  .navbar-extension {
    height: 3.25rem;
    background-color: #0a0a0a;
    width: 100vw;
    position: absolute;
  }
  .navbar-burger:hover {
    background-color: #0a0a0a;
  }
  .navbar-login-mobile {
    margin-left: auto;
  }
</style>

