<template>
  <div>
    <div v-if="loading" class="box">
      <app-spinner ></app-spinner>
    </div>
    <div v-if="!loading" class="box">
      <nav class="level">
        <div class="level-left">
          <div class="level-item">
            <nav class="breadcrumb">
              <ul>
                <li>
                  <router-link to="/" tag="a">
                    Home
                  </router-link>
                </li>
                <li>
                  <router-link to="/players" tag="a">
                    Players
                  </router-link>
                </li>
                <li class="is-active">
                  <router-link :to="`/clubs/${player.club_id}/players/${player.id}`" tag="a">
                    {{player.first_name}} {{player.last_name}}
                  </router-link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div class="level-right">
          <p class="level-item" v-if="player.user">
            <router-link :to="`/users/${player.user.id}`" tag="a" class="button is-primary">
              <span class="icon">
                <i class="fas fa-user"></i>
              </span>
              <span>View User</span>
            </router-link>
          </p>
          <p class="level-item">
            <button
              v-if="this.$store.getters.user.is_admin"
              @click="addFamilyMember"
              class="button is-primary">
              <span class="icon">
                <i class="fas fa-plus"></i>
              </span>
              <span>Add Family Member</span>
            </button>
          </p>
        </div>
      </nav>
    </div>
    <div v-if="!loading" class="box">
      <div class="column">
        <app-player-info
          :player="player"
          @refresh="dismissPlayerModal({refreshData:true})">
        </app-player-info>
      </div>
    </div>

    <app-add-family-member
      v-if="addFamilyMemberModal"
      :player="player"
      @dismissModal="dismissPlayerModal($event)">
    </app-add-family-member>
  </div>
</template>

<script>
import api from '@/http-playmetrics';
import Spinner from '@/components/common/Spinner';
import PlayerInfo from './PlayerInfo';
import AddFamilyMember from './AddFamilyMember';

export default {
  components: {
    'app-spinner': Spinner,
    'app-player-info': PlayerInfo,
    'app-add-family-member': AddFamilyMember,
  },

  data() {
    return {
      loading: true,
      player: null,
      addFamilyMemberModal: false,
    };
  },

  methods: {
    fetchPlayer() {
      api().get(`/admin/clubs/${this.$route.params.club}/players/${this.$route.params.player}`)
        .then((response) => {
          this.player = response.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    dismissPlayerModal(options) {
      if (options.refreshData) {
        this.fetchPlayer();
      }
      this.addFamilyMemberModal = false;
    },
    addFamilyMember() {
      this.addFamilyMemberModal = true;
    },
  },

  created() {
    this.fetchPlayer();
  },
};
</script>
