<template>
  <div class="box">
    <div class="columns">
      <div class="column">
        <b-field label="Club Name">
          <b-input
            v-model="club.name"
            :has-counter="false"
            minlength="4"
            maxlength="30"/>
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-field label="First Name">
          <b-input v-model="club.first_name" minlength="1"/>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Last Name">
          <b-input v-model="club.last_name" minlength="2"/>
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-field label="Email">
          <b-input v-model="club.email" type="email"/>
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column is-narrow">
        <b-field label="Country">
          <b-select v-model="club.country">
            <option
              v-for="country in countries"
              :key="country"
              :value="country">
              {{ country }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="column is-narrow">
        <b-field label="State">
          <b-select v-model="club.state">
            <option
              v-for="state in states"
              :key="state.abbr"
              :value="state.abbr">
              {{ state.name }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="column">
        <b-field label="City">
          <b-input v-model="club.city" maxlength="50"/>
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-field label="Timezone">
          <b-select
            placeholder="Select timezone"
            v-model="club.timezone">
            <option
             v-for="zone in timezones"
             :key="zone"
             :value="zone">
             {{ zone }}
            </option>
          </b-select>
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-field label="Sport">
          <div class="columns">
            <div class="column">
              <b-radio v-model="club.sport" name="name" native-value="baseball">Baseball</b-radio>
            </div>
            <div class="column">
              <b-radio v-model="club.sport" name="name" native-value="basketball">Basketball</b-radio>
            </div>
            <div class="column">
              <b-radio v-model="club.sport" name="name" native-value="fieldhockey">Field Hockey</b-radio>
            </div>
            <div class="column">
              <b-radio v-model="club.sport" name="name" native-value="hockey">Hockey</b-radio>
            </div>
            <div class="column">
              <b-radio v-model="club.sport" name="name" native-value="lacrosse">Lacrosse</b-radio>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-radio v-model="club.sport" name="name" native-value="rugby">Rugby</b-radio>
            </div>
            <div class="column">
              <b-radio v-model="club.sport" name="name" native-value="ski/snowboard">Ski/Snowboard</b-radio>
            </div>
            <div class="column">
              <b-radio v-model="club.sport" name="name" native-value="soccer">Soccer</b-radio>
            </div>
            <div class="column">
              <b-radio v-model="club.sport" name="name" native-value="softball">Softball</b-radio>
            </div>
            <div class="column">
              <b-radio v-model="club.sport" name="name" native-value="volleyball">Volleyball</b-radio>
            </div>
          </div>
        </b-field>
      </div>
    </div>
    <div class="columns" style="margin-top:1.5rem;">
      <div class="column">
        <b-field>
          <b-checkbox v-model="club.send_register_email">
            Send Welcome Email
          </b-checkbox>
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-field label="Onboarding Type">
          <b-select
            placeholder="Select..."
            v-model="club.onboarding_type">
            <option
              v-for="type in onboardingTypeList"
              :value="type"
              :key="type">
              {{ type }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Onboarding Status">
          <b-select
            placeholder="Select..."
            v-model="club.onboarding_status">
            <option
              v-for="status in onboardingStatusList"
              :value="status"
              :key="status">
              {{ status }}
            </option>
          </b-select>
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-field label="Competitive Player Cap">
          <b-input v-model="club.competitive_player_cap" type="number"/>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Recreational Player Cap">
          <b-input v-model="club.rec_player_cap" type="number"/>
        </b-field>
      </div>
    </div>
    <button @click="submit"
      class="button is-primary"
      :disabled="invalid">
      Create Club
    </button>
  </div>
</template>
<script>
import api from '@/http-playmetrics';

export default {
  data() {
    return {
      club: {
        name: '',
        first_name: '',
        last_name: '',
        email: '',
        country: 'US',
        city: '',
        state: '',
        timezone: 'America/New_York',
        sport: 'soccer',
        send_register_email: true,
        onboarding_type: '',
        onboarding_status: '',
        competitive_player_cap: null,
        rec_player_cap: null,
      },
      onboardingTypeList: ['Full Service', 'Self Service'],
      onboardingStatusList: ['Onboarding', 'Active', 'Onboarding Risk', 'At Risk'],
      countries: ['US', 'CA'],
    };
  },
  computed: {
    invalid() {
      return this.club.name === '' || this.club.first_name === '' || this.club.last_name === '' ||
        this.club.email === '' || this.club.country === '' || this.club.timezone === '';
    },
    states() {
      return api().statesForCountry(this.club.country);
    },
    timezones() {
      return api().timezonesForCountry(this.club.country);
    },
  },
  methods: {
    submit() {
      this.club.competitive_player_cap = parseInt(this.club.competitive_player_cap, 10);
      if (Number.isNaN(this.club.competitive_player_cap)) { this.club.competitive_player_cap = null; }
      this.club.rec_player_cap = parseInt(this.club.rec_player_cap, 10);
      if (Number.isNaN(this.club.rec_player_cap)) { this.club.rec_player_cap = null; }
      api().post('/admin/clubs/signup', this.club).then((response) => {
        const club = response.data;
        const message = `Club ${club.name} Created`;
        this.$store.dispatch('createNotification', { message, success: true, toast: true });
        this.$router.push(`/clubs/${club.id}`);
      });
    },
  },
};
</script>
