<template>
  <div>
    <div class="box">
      <div class="field">
        <div class="control">
          <input v-model="searchQuery" class="input" type="text" placeholder="Search...">
        </div>
      </div>
       <div class="field">
        <b-switch
          v-model="showTestClubs">
          Test Clubs
        </b-switch>
        <b-switch
          v-model="showDeletedClubs">
          Deleted Clubs
        </b-switch>
        <b-switch
          v-model="showDisabledClubs">
          Disabled Clubs
        </b-switch>
      </div>
    </div>
    <div class="box">
      <app-spinner v-if="loading"></app-spinner>
      <div v-else>
        <p class="has-text-right" style="margin-bottom:1rem;">
          <router-link to="clubs/create" tag="button" class="button is-primary">
            <span class="icon">
              <i class="fas fa-plus"></i>
            </span>
            <span>Create Club</span>
          </router-link>
        </p>
        <b-table
          :data="filteredClubs"
          :per-page="perPage"
          narrowed
          hoverable
          mobile-cards
          detailed
          detail-key="id"
          paginated
          pagination-simple
          @select="selected"
          :row-class="(function() {return 'clickable';})">
          <template slot-scope="props">
            <b-table-column field="id" label="Club ID" sortable>
              {{ props.row.id }}
            </b-table-column>
            <b-table-column field="name" label="Club" sortable>
              {{ props.row.name }}
            </b-table-column>
            <b-table-column class="has-text-right" field="config.competitive_player_cap" label="# of Comp" numeric sortable>
              {{ props.row.config.competitive_player_cap != null ? props.row.config.competitive_player_cap : '-' }}
            </b-table-column>
            <b-table-column class="has-text-right" field="config.rec_player_cap" label="# of Rec" numeric sortable>
              {{ props.row.config.rec_player_cap != null ? props.row.config.rec_player_cap : '-'}}
            </b-table-column>
            <b-table-column field="config.onboarding_type" label="Onboarding Type" sortable>
              {{ props.row.config.onboarding_type }}
            </b-table-column>
            <b-table-column field="config.onboarding_status" label="Status" sortable>
              {{ props.row.config.onboarding_status }}
            </b-table-column>
          </template>
          <template slot="detail" slot-scope="props">
            <div class="columns">
              <div class="column is-8">
                <b>Club ID</b>: {{ props.row.id }}<br/>
                <b>Name</b>: {{ props.row.name }}<br/>
                <b>Contact</b>: {{ props.row.contact_name }}<br/>
                <b>Contact Email</b>: {{ props.row.contact_email }}<br/>
                <b>Website</b>: {{ props.row.website }}<br/>
                <b>Signup Key</b>:
                <input class="input is-small"
                  type="text"
                  :value="props.row.login_key"
                  readonly
                  style="width:20rem">
                <br/>
              </div>
              <div class="column is-4">
                <img :src="props.row.image_url" style="height:150px">
              </div>
            </div>
          </template>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>No results</p>
              </div>
            </section>
          </template>
          <template slot="bottom-left">
            <b-select v-model="perPage">
              <option value="10">10 per page</option>
              <option value="15">15 per page</option>
              <option value="20">20 per page</option>
              <option value="50">50 per page</option>
              <option value="100">100 per page</option>
            </b-select>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/http-playmetrics';
import Spinner from '@/components/common/Spinner';

export default {
  components: {
    'app-spinner': Spinner,
  },

  data() {
    return {
      loading: true,
      clubs: null,
      perPage: 20,
      showTestClubs: false,
      showDisabledClubs: false,
      showDeletedClubs: false,
    };
  },

  computed: {
    searchQuery: {
      get() {
        return this.$store.getters.clubsFilters.searchQuery;
      },
      set(searchQuery) {
        return this.$store.dispatch('setClubsFiltersSearchQuery', searchQuery);
      },
    },
    filteredClubs() {
      // UI Flags
      let filteredClubs = this.clubs.filter(i => (
        i.is_test_club === this.showTestClubs &&
        i.is_disabled === this.showDisabledClubs &&
        i.is_deleted === this.showDeletedClubs
      ));
      if (this.searchQuery) {
        const lowerCaseQuery = this.searchQuery.toLowerCase();
        filteredClubs = filteredClubs.filter(i => (
          (lowerCaseQuery.match(/^\d+$/) && i.id === parseInt(lowerCaseQuery, 10)) ||
            i.name.toLowerCase().includes(lowerCaseQuery) ||
            i.abbreviation.toLowerCase().includes(lowerCaseQuery) ||
            i.email_domain.toLowerCase().includes(lowerCaseQuery) ||
            i.contact_name.toLowerCase().includes(lowerCaseQuery)
        ));
      }
      return filteredClubs;
    },
  },

  methods: {
    selected(club) {
      this.$router.push(`/clubs/${club.id}`);
    },
    fetchClubs() {
      api().get('admin/clubs')
        .then((response) => {
          this.clubs = response.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    user() {
      return this.$store.getters.user;
    },
  },

  created() {
    this.fetchClubs();
  },
};
</script>
