<template>
  <div>
    <div class="box">
      <div class="columns">
        <div class="column">
          <button @click="emailMarketingTestVendor('constant_contact')" class="button">Test Constant Contact</button>
        </div>
        <div class="column">
          <button @click="emailMarketingTestVendor('hubspot')" class="button">Test HubSpot</button>
        </div>
        <div class="column">
          <button @click="emailMarketingTestVendor('mailchimp')" class="button">Test Mailchimp</button>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          {{ this.clubEmailMarketingVendor }}
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <iframe v-show="showVendorLogin"
            :src="vendorUrl"
            style="width:80%; height:80vh; border:1px solid black;">
          </iframe>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from '@/http-playmetrics';

export default {
  data() {
    return {
      showVendorLogin: false,
      vendorUrl: '',
      loginWindow: null,
      clubEmailMarketingVendor: null,
    };
  },
  mounted() {
    window.addEventListener('message', this.receiveMessage);
  },
  beforeDestroy() {
    window.removeEventListener('message', this.receiveMessage);
  },
  methods: {
    emailMarketingTestVendor(vendor) {
      if (this.showVendorLogin) {
        this.resetVendorLogins();
      } else {
        this.clubEmailMarketingVendor = null;
        api().get(`admin/clubs/2/email_marketing/url_for/${vendor}`).then((resp) => {
          if (resp.data === '') {
            console.log('returned empty response');
          } else if (vendor === 'constant_contact') {
            this.loginWindow = window.open(resp.data);
          } else if (vendor === 'hubspot') {
            this.loginWindow = window.open(resp.data);
          } else if (vendor === 'mailchimp') {
            this.loginWindow = window.open(resp.data);
          } else {
            this.vendorUrl = resp.data;
            this.showVendorLogin = true;
          }
        });
      }
    },
    receiveMessage(message) {
      if (message.origin !== 'http://127.0.0.1:8081') {
        console.log('message ignored because of unexpected origin', message.origin);
      }
      if (message.data && message.data.vendor) {
        api().post('admin/clubs/2/email_marketing', message.data).then((resp) => {
          this.resetVendorLogins();
          this.clubEmailMarketingVendor = resp.data;
        });
      }
    },
    resetVendorLogins() {
      this.showVendorLogin = false;
      this.vendorUrl = '';
      if (this.loginWindow != null) {
        this.loginWindow.close();
        this.loginWindow = null;
      }
    },
  },
};
</script>
